{
  "key": "value",
  "ABOUT": "About",
  "THESSA": "Thessa",
  "THESSA_IS_THE_DOCTORAL_STUDIES": "Thessa is the doctoral studies planning and reporting tool for University of Helsinki.",
  "ALL_KINDS_OF_FEEDBACK_AND_ERRO": "All kinds of feedback and error reports are much appreciated.",
  "THESSA_ADMIN_HELSINKI_FI": "thessa-admin@helsinki.fi",
  "RIGHTS_OBLIGATIONS_AND_RESPON": "Rights, Obligations and Responsibilities in Doctoral Training at the University of Helsinki",
  "PROBLEMS_LOGGING_IN": "Problems logging in?",
  "IF_YOU_FORGOT_YOUR_PASSWORD_Y": "If you forgot your password, you can",
  "RESET_IT": "reset it",
  "SEND_PASSWORD_RESET_LINK_TO_MY": "Send password reset link to my email",
  "EMAIL_FOR_THESSA_ACCOUNT": "Email for Thessa account",
  "SEND": "Send",
  "BACK_TO_LOGIN": "Back to login",
  "BACK_TO_MY_PHD_PROJECT": "Back to My PhD project",
  "MESSAGE_SENT_CHECK_YOUR_EMAIL": "Message sent. Check your email inbox and open the link in the password reset email.",
  "TOO_MANY_PASSWORD_RESET_REQUES": "Too many password reset requests.",
  "WAIT_FIVE_MINUTES_BEFORE_TRYIN": "Please wait five minutes before trying again. If the problem continues, <0>contact us</0> for more help.",
  "HELPDESK": "contact IT-Helpdesk.",
  "YOU_MAY_RESET_YOUR_PASSWORD": "try changing your password.",
  "SOMETHING_WENT_WRONG": "Something went wrong.",
  "TRY_AGAIN_LATER": "Please try again later. If the problem continues, <0>contact us</0> for more help.",
  "NO_NETWORK_CONNECTION": "No network connection",
  "RIGHTS_OBLIGATIONS_AND_RESPON1": "Rights, Obligations and Responsibilities in Doctoral Training at the University of Helsinki",
  "ABOUT_THESSA": "About Thessa",
  "THE_DOCTORAL_STUDIES_PLANNING": "The doctoral studies planning and reporting tool.",
  "EMAIL": "Email",
  "PASSWORD": "Password",
  "LOG_IN": "Log in",
  "LOGIN_WAS_NOT_SUCCESSFUL": "Login was not successful.",
  "CHECK_USERNAME_AND_PASSWORD_I": "Check username and password. If that doesn't help, find more help from",
  "UNIVERSITY_OF_HELSINKI_LOGIN": "Do you have University of Helsinki username?",
  "USE_UNIVERSITY_OF_HELSINKI_LOGIN": "Use University of Helsinki login",
  "UNIVERSITY_OF_HELSINKI_USERNAM": "University of Helsinki username",
  "CHECK_USERNAME_AND_PASSWORD_I1": "Check username and password. If that doesn't help,",
  "TOO_MANY_FAILED_LOGIN_ATTEMPTS": "Too many failed login attempts.",
  "WAIT_FIVE_MINUTES_BEFORE_TRYIN1": "Wait five minutes before trying again. Check username and password. If that doesn't help, find more help from",
  "YOU_NEED_AN_INVITATION_OR_AN_A": "You need an invitation or an active doctoral study right to access Thessa.",
  "YOUR_EMAIL_ADDRESS_IS_ALREADY": "Your email address is already in use by someone in Thessa.",
  "CONTACT_US": "Contact us",
  "CONTACT_US_END_OF_SENTENCE": "contact us.",
  "FOR_HELP_WITH_THIS_ERROR": "for help with this error.",
  "LOGIN_FAILED_WITH_ERROR_CODE": "Login failed with error code",
  "NON_UNIVERSITY_LOGIN_EXTERNAL": "Are you an external user?",
  "USE_NON_UNIVERSITY_LOGIN": "Use non-university login",
  "NON_UNIVERSITY_LOGIN": "Non-university login for external users",
  "USE_YOUR_UNIVERSITY_OF_HELSINK": "Use your University of Helsinki username and password to log in.",
  "FORGOT_PASSWORD": "Forgot password?",
  "RESET_PASSWORD": "Reset password",
  "NEW_PASSWORD": "New password",
  "SAVE_PASSWORD": "Save password",
  "CANCEL": "Cancel",
  "PASSWORD_SET_REDIRECTING_YOU": "Password set. Redirecting you",
  "INSIDE": "inside",
  "THERE_WERE_PROBLEMS_WITH_SAVIN": "There were problems with saving the password:",
  "SIGN_UP_TO_THESSA": "Sign up to Thessa",
  "IF_YOU_HAVE_A_UNIVERSITY_OF_HE": "If you have a University of Helsinki account, use its username and password in the login form above. All students and employees have an account.",
  "MON": "Mon",
  "TUE": "Tue",
  "WED": "Wed",
  "THU": "Thu",
  "FRI": "Fri",
  "SAT": "Sat",
  "SUN": "Sun",
  "LOADING_WITH_ELLIPSIS": "Loading…",
  "YOU_MAY_USE": "You may use",
  "MARKDOWN": "Markdown",
  "ITALICS": "*italics*",
  "BOLD": "**bold**",
  "LINKS_HTTPS": "[links](https://...)",
  "HEADINGS": "# headings",
  "LISTS": "- lists",
  "AND_MORE": "and more.",
  "THESSA_FOR": "Thessa for",
  "UNIVERSITY_OF_HELSINKI": "University of Helsinki",
  "PRIVACY_POLICY": "Privacy policy",
  "ASK_FOR_HELP_AND_GIVE_FEEDBACK": "Ask for help and give feedback",
  "TOGGLE_NAVIGATION": "Toggle navigation",
  "LOG_OUT": "Log out",
  "LOGIN": "Login",
  "REMINDERS1": "Reminders",
  "PROFILE1": "Profile",
  "HELP1": "Help",
  "MY_PHD_PROJECT": "My PhD project",
  "MY_DOCTORAL_CANDIDATES": "My doctoral researchers",
  "DOCTORAL_CANDIDATES1": "Doctoral researchers",
  "USERS1": "Users",
  "QUEUE1": "Queue",
  "STATISTICS1": "Statistics",
  "LEFT": "left",
  "BOTTOM": "bottom",
  "ARE_YOU_SURE": "Are you sure?",
  "CONFIRM_DELETE": "Yes, remove",
  "THANKS": "Thanks!",
  "YOU_CAN_GIVE_MORE_FEEDBACK_HER": "You can give more feedback here:",
  "SEND_FEEDBACK": "Send feedback",
  "PAGE_HEADER": "Page Header",
  "HEADING": "Heading 1",
  "HEADING1": "Heading 2",
  "HEADING2": "Heading 3",
  "HEADING3": "Heading 4",
  "HEADING4": "Heading 5",
  "HEADING5": "Heading 6",
  "LEAD_TEXT": "Lead text",
  "BODY_TEXT": "Body text",
  "SMALL_TEXT": "Small text",
  "LABEL": "Label 1",
  "LABEL1": "Label 2",
  "LABEL2": "Label 3",
  "CHECK": "Check 1",
  "CHECK1": "Check 2",
  "SUBMIT": "Submit",
  "SAVE": "Save",
  "TOGGLE": "Toggle",
  "CALENDAR": "calendar",
  "OK": "ok",
  "REMOVE": "remove",
  "SUBMITTED": "Submitted",
  "CONCLUSION_MISSING": "Conclusion missing",
  "GIVE_FEEDBACK": "Give feedback",
  "DEFAULT": "Default",
  "RIGHT": "Right",
  "LEFT1": "Left",
  "CALL_TO_ACTION": "Call to action",
  "SECONDARY": "Secondary",
  "ACTION": "Action",
  "ACTIONS": "Actions",
  "STUDENT_NUMBER": "Student number",
  "NAME": "Name",
  "ACCOUNT": "Account",
  "NO_ACCOUNT": "No account",
  "NO_SUPERVISORS_IN_OODI": "No supervisors in Oodi",
  "STUDY_RIGHT_START_DATE": "Study right start date",
  "RECENT": "RECENT",
  "COORDINATOR_QUEUE": "Coordinator Queue",
  "LOADING_INACTIVE_USERS": "Loading inactive users...",
  "THERE_WAS_AN_ERROR_IN_LOADING": "There was an error in loading inactive users.",
  "PLEASE": "Please",
  "TRY_AGAIN": "try again",
  "LET_US_KNOW": "let us know",
  "IF_THE_PROBLEM_DOES_NOT_GO_AWA": "if the problem does not go away.",
  "INACTIVE_USER": "Inactive user",
  "TYPE": "Type",
  "N_A": "N/A",
  "NO_COURSES_IN_PLAN_YET": "No courses in plan yet.",
  "ADD_ONE_BY_CLICKING_THE": "Add one by clicking the",
  "ADD": "Add",
  "BUTTON_ABOVE": "button above!",
  "TITLE": "Title",
  "CREDITS": "Credits",
  "STATUS": "Status",
  "COMPLETED": "Completed",
  "NOT_COMPLETED": "Not completed",
  "COMPLETED_AT": "Completed at",
  "TO_BE_COMPLETED_AT": "To be completed at",
  "NOT_AVAILABLE": "Not available",
  "CHANGED_SINCE_LAST_OPENED": "Changed since last opened",
  "REPORT": "Report",
  "SUPERVISION_PLAN": "Supervision plan",
  "SUPERVISION_PLAN_other": "Supervision plans",
  "MY_DOCTORAL_CANDIDATES1": "My Doctoral Researchers",
  "LOADING_DOCTORAL_CANDIDATES": "Loading doctoral researchers...",
  "FAILED_TO_LOAD_DOCTORAL_CANDID": "Failed to load doctoral researchers. You can try reloading the page. If the error persists, please contact Thessa support.",
  "MISSING_DOCTORAL_CANDIDATES": "Missing doctoral researchers?",
  "TO_SEE_YOUR_DOCTORAL_CANDIDATE": "To see your doctoral researchers in Thessa, make sure they sign up to Thessa and invite you to become a supervisor or a thesis committee member for them.",
  "YOU_WILL_GET_THE_INVITATION_BY": "You will get the invitation by email. Accept it to get access to your doctoral researchers.",
  "HIDE": "Hide",
  "SHOW": "Show",
  "CLOSED_ACCOUNTS": "closed accounts",
  "RELATIONSHIP": "Relationship",
  "PROGRESS": "Progress",
  "YOU_HAVE_NO_DOCTORAL_CANDIDATE": "You have no doctoral researchers in Thessa.",
  "NO_DOCTORAL_CANDIDATES": "No doctoral researchers.",
  "YOUR_USER_ACCOUNT_NEEDS_TO_BE": "Your user account needs to be activated before you can create your first report. Before your account can be activated, you need to",
  "INVITE_ALL_YOUR_SUPERVISORS_AN": "invite all your supervisors and thesis committee members",
  "IN_THESSA": "in Thessa.",
  "YOU_WILL_RECEIVE_AN_EMAIL_WHEN": "You will receive an email when your account has been activated.",
  "INVITE_SUPERVISORS": "Invite supervisors",
  "THERE_ARE_NO_SUPERVISION_PLANS": "There are no supervision plans so far.",
  "DOCTORAL_CANDIDATE_AND_SUPERVI": "Doctoral researcher and supervisors agree to follow a supervision plan that will define the co-operation of the doctoral researcher and supervisors. The plan is created using a supervisor's account and then signed by the doctoral researcher and supervisors.",
  "SUPERVISORS": "Supervisors",
  "LOADING_NEXT_REPORT_DUE_DATE": "Loading next report due date...",
  "NEXT_REPORT_DUE_ON": "Next report due on",
  "NEXT_REPORT_RECOMMENDED_TO_BE_SENT_AT": "A report should be submitted about a week before the thesis committee meeting.",
  "PERIOD": "Period",
  "PUBLICATIONS": "Publications",
  "COURSES": "Courses",
  "GRADUATION": "Graduation",
  "CONCLUSION": "Conclusion",
  "DRAFT": "Draft",
  "OPEN": "Open",
  "MISSING": "Missing",
  "HAVE_A_THESIS_COMMITTEE_MEETIN": "Have a thesis committee meeting and write the conclusions by opening the report.",
  "REPORTS": "Reports",
  "LOADING_REPORTS": "Loading reports...",
  "NO_REPORTS_YET": "No reports yet",
  "VIEW_CUMULATIVE_REPORT": "View cumulative report",
  "THESSA_IS_IN_MAINTENANCE": "Thessa is in maintenance",
  "PLEASE_WAIT_A_FEW_MINUTES_AND": "Please wait a few minutes and <0>reload the page</0>",
  "RELOAD_THE_PAGE": "Reload the page",
  "PAGE_NOT_FOUND": "Page not found",
  "SORRY_THE_PAGE_YOU_OPENED_CAN": "Sorry, the page you opened cannot be found. Try the",
  "FRONT_PAGE_INSTEAD": "front page instead",
  "ADD_GRANT": "Add grant",
  "ADD_SALARY": "Add salary",
  "SALARIES": "Salaries",
  "GRANTS": "Grants",
  "NO_SALARIES_IN_PLAN_YET": "No salaries in plan yet.",
  "NO_SALARIES": "No salaries.",
  "NO_GRANTS_IN_PLAN_YET": "No grants in plan yet.",
  "NO_GRANTS": "No grants.",
  "SOURCE": "Source",
  "AMOUNT": "Amount",
  "FOR_MONTHS": "For months",
  "USED_FOR": "Used for",
  "NOT_GRANTED": "Not granted",
  "GRANTED": "Granted",
  "FOR_DATES": "For dates",
  "HELP_MORE_GUIDES_LINK_PREFIX": "Go to",
  "HELP_MORE_GUIDES_LINK_URL": "https://studies.helsinki.fi/instructions/article/thessa",
  "HELP_MORE_GUIDES_LINK_LINK_TEXT": "Instructions for students",
  "HELP_MORE_GUIDES_LINK_SUFFIX": "to get more help regarding Thessa.",
  "THE_THREE_MAIN_PARTS_OF_THESSA": "The three main parts of Thessa",
  "THESSA_CONSISTS_OF_THREE_MAIN": "Thessa consists of three main parts:",
  "PHD_PROJECT1": "PhD project",
  "SUPERVISION_PLAN_IS_A_SET_OF_D": "Supervision plan is a set of decisions made together with the doctoral researcher and supervisors to make sure working on PhD is as smooth as possible. The plan is created with supervisor's account and the doctoral researcher and other supervisors sign the plan afterwards.",
  "PHD_PROJECT_IS_A_PLACE_FOR_DOC": "PhD project is a place for doctoral researcher to plan their project's parts and keep track of their progress. The parts include for example publications, courses and funding. All of these and more can be stored in Thessa for communicating with supervisors.",
  "ANOTHER_GREAT_BENEFIT_OF_KEEPI": "Another great benefit of keeping track of progress is that it lightens the load of writing reports when most of the content can automatically be copied from the PhD project to the report.",
  "DOCTORAL_CANDIDATES_AND_SUPERV": "Doctoral researchers and supervisors",
  "DOCTORAL_CANDIDATES_ARE_THE_ON": "Doctoral researchers are the ones who invite their supervisors in Thessa. Thessa sends the invitation as an email message containing a link to register as the doctoral researcher's supervisor. The invitation is valid for two weeks. The pairing between a supervisor and a doctoral researcher is thus managed by the doctoral researcher only and supervisors cannot choose their doctoral researchers in Thessa. That way Thessa protects unauthorized access to doctoral researcher's information: doctoral researchers are themselves the gatekeepers.",
  "THE_DOCTORAL_CANDIDATE_CAN_STA": "The doctoral researcher can start working with Thessa right away after signing in. However, the supervisors and thesis committee members (see below) must be invited before the reporting cycle can be completed.",
  "TO_LATER_CHANGE_DOCTORAL_CANDI": "To later change doctoral researcher's supervisors, the doctoral researcher needs to visit their Profile page.",
  "THESIS_COMMITTEES_TC": "Thesis committees (TC)",
  "THESIS_COMMITTEES_ARE_REQUIRED": "Thesis committees are required in all doctoral programmes from 2020 onwards. Many doctoral programmes already have them in place.",
  "THESSA_SUPPORTS_INVITING_MEMBE": "Doctoral researchers can invite their thesis committee members to Thessa from the profile page in the main menu. The TC members can review and comment the doctoral researcher's report in Thessa.",
  "THE_CONCLUSIONS_AND_RECOMMEND": "The \"Conclusions and recommendations\" is written by the doctoral researcher during the TC Meeting when all members of the TC are present.",
  "THESSA_ASKS_DOCTORAL_CANDIDATE": "Thessa asks doctoral researchers to complete a report of their PhD project's progress at least once per year during doctoral studies. The report is a snapshot of current progress and that's why most of it are automatically copied from the My PhD project section. There are some additional fields that the doctoral researcher fills, too.",
  "AFTER_THE_DOCTORAL_CANDIDATE_H": "After the doctoral researcher has submitted the report, the supervisors and TC members will be notified of the new report. It is then the job of the supervisors and TC members to review the report. A discussion tool is enabled in Thessa's Report view to facilitate the review. The doctoral researcher gets notified of review comments and needs to update and save the report again after completing the review comments.",
  "THERE_WILL_BE_REMINDER_EMAILS": "There will be reminder emails about reporting deadlines sent. Report's deadline is always the later date of the following dates:",
  "ONE_YEAR_FROM_THE_LAST_REPORT": "One year from the last report.",
  "ONE_YEAR_FROM_THE_BEGINNING_OF": "One year from the beginning of doctoral studies.",
  "CLOSING_THE_REPORT": "Closing the report",
  "AT_THE_END_OF_REPORTING_CYCLE": "At the end of reporting cycle when all requested changes have been made in the Report, the doctoral researcher closes the report by writing the \"Conclusions and recommendations\". This section is typically written by the doctoral researcher during a TC Meeting when all members of the TC are present.",
  "IN_SOME_DOCTORAL_PROGRAMS_THE": "In some doctoral programs the thesis committees are not yet in practice and the Report is reviewed by the supervisor only. In such cases the doctoral researchers may use the field for their own notes. Nevertheless, the doctoral researchers should use the \"Conclusions and recommendations\" field to close the report and allow for next reporting period start.",
  "FEEDBACK_AND_ERROR_REPORTS": "Feedback and error reports",
  "CONTACT_US1": "contact us",
  "TRY_TO_INCLUDE_AS_MUCH_DETAI": "Try to include as much details about your issue with your email. Thessa is in active development and any help in the form of feedback of error reports is much appreciated.",
  "IF_YOU_NEED_MORE_HELP_PLEASE": "If you need more help, please don't hesitate to",
  "INVITE": "Invite",
  "S_EMAIL_ADDRESS": "{{roleName}}'s email address",
  "INVITE_TO_YOUR_PHD_PROJECT_IN": "Invite to your PhD project in Thessa",
  "INVITATION_SUBMITTED": "Invitation submitted",
  "SUBMITTING_INVITATION_FAILED": "Submitting invitation failed",
  "YOU_ARE_INVITED": "You are invited",
  "WHAT_IS_THESSA": "What is Thessa?",
  "ALL_DOCTORAL_CANDIDATES_AND_TH": "All doctoral researchers and their supervisors and thesis committee members can use it.",
  "WELCOME_TO_THESSA": "Welcome to Thessa!",
  "YOU_ARE_NOT_CONNECTED_TO_ANYON": "You are not connected to anyone.",
  "ASK_YOUR_DOCTORAL_CANDIDATES_T": "Ask your doctoral researchers to send you an invitation from their Thessa.",
  "OTHERWISE_IT_S_GOING_TO": "Otherwise it's going to be lonely in here.",
  "NO_NOTES": "No notes.",
  "EDIT": "Edit",
  "SAVING": "Saving...",
  "UPDATE_ANNUAL_REPORTS": "Update: Annual reports",
  "ANNUAL_REPORTS_SECTION_HAS_BEE": "Annual reports section has been updated to show report's status and requested actions more clearly.",
  "UPDATE_CUMULATIVE_REPORT": "Update: Cumulative report",
  "CUMULATIVE_REPORT_VIEW_SHOWS_A": "Cumulative report view shows all of your reports at the same time making it easier to grasp the full picture. Press \"View cumulative report\" button in the annual reports section to open it.",
  "UPDATE_REVIEWING_REPORTS": "Update: Reviewing reports",
  "CUMULATIVE_REPORT": "Cumulative report",
  "YOU_CAN_NOW_SEE_THE_CUMULATIVE": "You can now see the cumulative report when reviewing.",
  "COMMENTING_REPORT": "Commenting report",
  "DISCUSS_REPORTS": "Discuss reports.",
  "NEW_IMPROVEMENTS": "New improvements!",
  "INVITE_SUPERVISORS_AND_THESIS": "Invite Supervisors and Thesis committee members using email addresses.",
  "DISCUSS_IN_SIMPLIFIED_ANNUAL_R": "Discuss in simplified Annual Report Review.",
  "REPORTS_ARE_CLOSED_BY_WRITING": "Reports are closed by writing the \"Conclusions and Recommendations\".",
  "READ_MORE_FROM_HELP_PAGE": "Read more from help page.",
  "SET_OF_DECISIONS_BETWEEN_DOCTO": "Set of decisions between doctoral researcher and supervisor to make sure PhD project makes smooth progress.",
  "KEEPING_TRACK_OF_FUTURE_PLANS": "Keeping track of future plans for advancing studies, publications and other things related to doctoral researcher's PhD.",
  "A_SNAPSHOT_OF_PHD_PROJECT_IT": "A snapshot of PhD project. It is created once per year to help communication between doctoral researcher and supervisor.",
  "START_USING_THESSA": "Start using Thessa!",
  "WE_RECOMMEND_THAT_YOU_START_US": "We recommend that you start using Thessa by creating a supervision plan. Contact your supervisor to create it.",
  "THEN_YOU_SHOULD_UPDATE_YOUR_PH": "Then you should update your PhD project plans in Thessa to make them clearly visible to you and your supervisor. You may start this right now as well!",
  "YOU_CAN_FIND_MORE_DETAILS_ABOU": "You can find more details about Thessa from the Help section in the main menu.",
  "IF_YOU_FIND_BUGS_OR_ERRORS_IN": "If you find bugs or errors in Thessa, or have an idea about how to make it better,",
  "DROP_AN_EMAIL_TO_US": "drop an email to us",
  "YOUR_DOCTORAL_CANDIDATES_WILL": "Your doctoral researchers will soon link their Thessa accounts with yours. After that, we recommend that you start using Thessa by creating a supervision plan for your doctoral researchers. Create it together with your account and then everyone will sign it with their Thessa account.",
  "REMOVE1": "Remove",
  "CONFERENCES_AND_MOBILITY": "Conferences and mobility",
  "LOCATION": "Location",
  "DATES": "Dates",
  "LOADING_CONFERENCES_AND_MOBILI": "Loading conferences and mobility...",
  "NO_CONFERENCES_OR_MOBILITY_PLA": "No conferences or mobility planned.",
  "ADD_CONFERENCE_OR_MOBILITY": "Add conference or mobility",
  "FAILED_TO_CREATE_CONFERENCE_OR_MOBILITY": "Failed to create conference or mobility.",
  "DELETE": "Delete",
  "REALLY_CANCEL_INVITATION": "Really cancel invitation?",
  "KEEP_INVITATION": "Keep invitation",
  "CANCEL_INVITATION": "Cancel invitation",
  "INVITATION_EXPIRED": "Invitation expired",
  "INVITED_NOT_YET_RESPONDED": "Invited, not yet responded",
  "FIRST_NAME": "First name",
  "LAST_NAME": "Last name",
  "EMAIL_ADDRESS": "Email address",
  "SAVED": "Saved",
  "REALLY_REMOVE": "Really remove?",
  "THE_LAST_MEMBER_CANNOT_BE_REMO": "The last member cannot be removed.",
  "RESEND_INVITATION": "Resend invitation",
  "INVITATION_TO_BECOME_A": "Invitation to become a",
  "FOR": "for",
  "EXPIRES_ON": "Expires on",
  "THIS_INVITATION_HAS_EXPIRED_Y": "This invitation has expired. You can request a new one from doctoral researcher",
  "ACCEPT": "Accept",
  "DECLINE": "Decline",
  "DUE_TO_DATA_MIGRATION_THESSA": "Due to data migration, Thessa doesn't know the start date of this status. Please select the correct date below and save publication.",
  "NO_PUBLICATIONS_IN_PLAN_YET": "No publications in plan yet.",
  "TOPIC_OR_TITLE": "Topic or title",
  "EDIT_REMINDER": "Edit reminder",
  "LOADING_YOUR_REMINDERS": "Loading your reminders",
  "NEW_REMINDER": "New reminder",
  "UPCOMING_REMINDERS": "Upcoming reminders",
  "PAST_REMINDERS": "Past reminders",
  "NO_REMINDERS": "No reminders",
  "DUE": "Due",
  "SEND_REMINDERS_TO": "Send reminders to",
  "REMINDER_TIMING": "Reminder timing",
  "DAYS_BEFORE_DUE_DATE": "days before due date",
  "CREATE_REPORT": "Create report",
  "YOUR_ACCOUNT_IS_INACTIVE_THER": "Your account is inactive, therefore you cannot create a report yet.",
  "YOUR_DOCTORAL_PROGRAMME_S_STAF": "Your doctoral programme's staff will activate the account for you once they have checked your profile.",
  "CANNOT_CREATE_REPORT_WHEN_A_PR": "Cannot create report when a previous report is a draft. Submit or delete it before creating new report.",
  "OPEN_REPORT": "Open report",
  "CANNOT_CREATE_REPORT_WHEN_A_PR1": "Cannot create report when a previous report has no conclusion.",
  "SUMMARIZE_THE_REPORTING_PERIOD": "Summarize the reporting period",
  "AS_AN_OVERVIEW_OF_THE_REPORTIN": "As an overview of the reporting period, list three positive and three negative things about it.",
  "BELOW_ARE_ALL_COURSES_YOU_COMP": "Below are all courses you completed between",
  "YOU_CAN_EDIT_THE_LIST_ON": "You can edit the list on the",
  "PHD_PROJECT_PAGE": "PhD project page",
  "NO_COURSES_IN_REPORT_RIGHT_NOW": "No courses in report right now.",
  "BELOW_ARE_ALL_ACTIVE_SALARIES": "Below are all active salaries and grants that you have in your plan between",
  "YOU_CAN_EDIT_THE_LISTS_ON_TH": "You can edit the lists on the",
  "NO_SALARIES_IN_REPORT_RIGHT_NO": "No salaries in report right now.",
  "NO_GRANTS_IN_REPORT_RIGHT_NOW": "No grants in report right now.",
  "BELOW_ARE_ALL_CONFERENCES_AND": "Below are all conferences and mobility you completed between",
  "NO_CONFERENCES_OR_MOBILITY_IN": "No conferences or mobility in report right now.",
  "BELOW_ARE_ALL_PUBLICATIONS_THA": "Below are all publications that were published or in progress between",
  "NO_PUBLICATIONS_IN_REPORT_RIGH": "No publications in report right now.",
  "BELOW_ARE_ALL_ACTIVE_TEACHINGS": "Below are all active teachings that you have in your plan between",
  "NO_COURSES_OR_WORKSHOPS_IN_REP": "No courses or workshops in report right now.",
  "NO_THESIS_INSTRUCTIONS_IN_REPO": "No thesis instructions in report right now.",
  "NOT_SUBMITTED": "Not submitted",
  "CONCLUSION_DONE": "Conclusion done",
  "WRITE_CONCLUSION": "Write conclusion",
  "HAVE_A_THESIS_COMMITTEE_MEETIN1": "Have a thesis committee meeting and write about the conclusions to your report.",
  "THE_DOCTORAL_CANDIDATE_NEEDS_T": "The doctoral researcher needs to have a thesis committee meeting and write about the conclusions to the report.",
  "DONE": "done",
  "REPORT_SUBMITTED_FOR_REVIEW_SU": "Report submitted for review successfully.",
  "REPORT_SUBMITTED": "Report submitted",
  "REPORT_UPDATED": "Report updated",
  "EDIT_REPORT": "Edit report",
  "YOU_CAN_MAKE_CHANGES_TO_THE_RE": "You can make changes to the report.",
  "NO_REPORTS_TO_REVIEW_FOUND": "No reports to review found.",
  "EDIT_REVIEW": "Edit review",
  "REVIEW_REPORT": "Review report",
  "CHANGES_SAVED": "Changes saved",
  "LAST_SAVED": "Last saved",
  "CANCEL_EDITING": "Cancel editing",
  "SAVE_REPORT": "Save report",
  "SUBMIT_REPORT_FOR_REVIEW": "Submit report for review",
  "CREATED_IN": "Created in",
  "CONFERENCES_AND_MOBILITY1": "Conferences and mobility",
  "FUNDING": "Funding",
  "TEACHING": "Teaching",
  "COURSES_AND_WORKSHOPS": "Courses and workshops",
  "THESIS_INSTRUCTION": "Thesis instruction",
  "YOU_ARE_EDITING_THE_REPORT_S_C": "You are editing the report's conclusion & recommendations",
  "SHOW_EDITOR": "Show editor",
  "PLEASE_INDICATE_WHO_ATTENDED_T": "Please indicate who attended the thesis committee meeting.",
  "THESIS_COMMITTEE_MEETING_FORM": "Thesis Committee Meeting Form",
  "CONCLUSION_AND_RECOMMENDATIONS": "Conclusion and recommendations",
  "NO_REPORT_FOUND_CANNOT_EDIT_CONCLUSION": "Report was not found. Cannot edit conclusion.",
  "NO_TEXT_TO_PREVIEW": "No text to preview",
  "CANNOT_SUBMIT_CONCLUSION_EMPTY": "Conclusion cannot be empty.",
  "THE_DOCTORAL_CANDIDATE_IS_EXPE": "The doctoral researcher is expected to complete this form at the conclusion of the thesis committee meeting when all participants are present.",
  "PREVIEW": "Preview",
  "SUBMITTING_PLEASE_WAIT": "Submitting, please wait...",
  "CONCLUSION_CONFIRM_CANCEL": "Are you sure you want to stop writing the conclusion? What you have written so far will be lost.",
  "CONCLUSION_CANNOT_BE_EDITED": "Conclusion has already been written for this report and it cannot be changed afterwards. You can <0>go back to the report</0>.",
  "CUMULATIVE_REPORT_FOR": "Cumulative Report: ",
  "COMPLETED_IN_THE_MOST_RECENT": "completed in the most recent report",
  "IN_PROGRESS_IN_THE_MOST_RECE": "in progress in the most recent report",
  "PUBLICATION_IS_IN_THESIS": "Publication is in thesis.",
  "COMPLETE": "complete",
  "NOTHING_REPORTED_YET": "Nothing reported yet.",
  "DELETE_REPORT": "Delete report",
  "YOU_CAN_DELETE_YOUR_REPORT_BEF": "You can delete your report before it has been submitted.",
  "COMPLETED1": "completed",
  "ESTIMATE_OF_HOW_COMPLETE_YOUR": "Estimate of how complete your PhD project is in percentage.",
  "FULL_TIME": "full time",
  "ESTIMATE_OF_FULL_PART_TIME_WOR": "Estimate of full/part time work on PhD project during the reporting period.",
  "YOUR_DOCTORAL_PROGRAMME_ASKS_Y": "Your doctoral programme asks you to write this report to get a sense of how your PhD project is going.",
  "PLEASE_FILL_IN_ALL_OF_THE_FIEL": "Please fill in all of the fields.",
  "THE_REPORT_WILL_BE_VISIBLE_TO": "The report will be visible to your supervisors, thesis committee members and coordinators once submitted.",
  "THIS_REPORT_COVERS_DATES_BETWE": "This report covers dates between",
  "AND": "and",
  "WHEN_YOU_ARE_DONE_PRESS_THE_S": "When you are done, press the Submit-button. The button becomes visible when you have filled in every field. After submitting, the report is open for discussion.",
  "REMEMBER_TO_SAVE_THE_REPORT_PE": "Remember to save the report periodically. You may save the report at any time and continue writing it later.",
  "MESSAGE_REMOVED": "Message removed.",
  "DISCUSSION": "Discussion",
  "LOADING": "Loading...",
  "NO_DOCUMENT_FOUND_WITH_DATE": "No document found with date",
  "APPROVED_BY_THE_BOARD_OF_DOCTO": "Approved by the Board of Doctoral Training at the University of Helsinki, <0>{{date}}</0>.",
  "THESSA_JOIN_A_THESIS_COMMITT": "Thessa - Join a thesis committee",
  "WITH_YOUR_THESSA_CREDENTIALS_O": "with your Thessa credentials or use your University of Helsinki username.",
  "OTHERWISE": "Otherwise",
  "REGISTER_AN_EXTERNAL_ACCOUNT": "register an external account",
  "YOU_WILL_USE_THIS_FOR_LOGGING": "You will use this for logging in.",
  "AT_LEAST_CHARACTERS": "At least 8 characters.",
  "YOUR_ORGANIZATION": "Your organization",
  "FOR_EXAMPLE_FINNISH_METEOROLO": "For example: Finnish Meteorological Institute or Acme Inc.",
  "THERE_WERE_PROBLEMS_WITH_SIGN": "There were problems with sign up:",
  "WELCOME_TO_THESSA_AS_A_NON_UN": "Welcome to Thessa! As a non-University-of-Helsinki user, you can create your account here.",
  "ERROR": "Error",
  "SORRY_AN_ERROR_HAS_OCCURRED_I": "Sorry, an error has occurred in sign up. Please try again by refreshing the page, or",
  "DOCTORAL_STUDIES": "Doctoral studies",
  "FILL_IN_DETAILS_OF_YOUR_STUDIE": "Fill in details of your studies.",
  "BEGINNING_OF_DOCTORAL_STUDIES": "Beginning of doctoral studies",
  "DOCTORAL_PROGRAMME": "Doctoral programme",
  "BACK": "Back",
  "CONTINUE": "Continue",
  "OR": "or",
  "LOG_OUT1": "log out",
  "WELCOME_TO_THESSA1": "Welcome to Thessa",
  "WE_NEED_SOME_INFORMATION_ABOUT": "We need some information about you before you can continue.",
  "MY_ROLE_IN_THESSA": "My role in Thessa",
  "DOCTORAL_CANDIDATE": "Doctoral researcher",
  "SUPERVISOR": "Supervisor",
  "SUPERVISOR_other": "Supervisors",
  "THESIS_COMMITTEE_MEMBER": "Thesis committee member",
  "VERIFY_YOUR_DETAILS": "Verify your details",
  "WE_FOUND_THE_FOLLOWING_DETAILS": "We found the following details of you automatically.",
  "PLEASE_CHECK_THAT_THEY_ARE_COR": "Please check that they are correct and fix if needed.",
  "LIST_DOCTORAL_CANDIDATES_BY_RE": "List doctoral researchers by report status",
  "LIST_REVIEWERS_OF_NOT_COMPLETE": "List reviewers of not completed reports",
  "DOWNLOAD_THESSA_ADMIN_REPORT": "Download Thessa admin report",
  "REPORT_CONCLUSIONS_OVERVIEW": "Report conclusions overview",
  "FILTER": "Filter",
  "SHOW_ALL_REPORTS": "Show all reports",
  "OVER_MONTH_OLD_REPORTS": "Over 2 month old reports",
  "COPY_REVIEWER_EMAIL_ADDRESSES": "Copy reviewer email addresses",
  "EMAIL_ADDRESSES_COPIED": "Email addresses copied",
  "CREATED_AT": "Created at",
  "REVIEWERS": "Reviewers",
  "FILTER_BY_DOCTORAL_PROGRAMME": "Filter by doctoral programme",
  "NO_REPORT_CONCLUSIONS_AVAILABL": "No report conclusions available for doctoral programme in",
  "PRELIMINARY_TITLE_OF_DISSERTAT": "Preliminary title of dissertation",
  "STUDENT_NUMBER1": "STUDENT NUMBER",
  "NAME1": "NAME",
  "ACCOUNT_CLOSED": "Account closed:",
  "STUDIES": "STUDIES",
  "FACULTY": "FACULTY",
  "SUPERVISORS1": "SUPERVISORS",
  "THESIS_COMMITTEE2": "THESIS COMMITTEE",
  "REPORT_CREATED": "Report created",
  "PROGRESS1": "progress",
  "LINK_TO_THE_DOCTORAL_CANDIDATE": "Link to the doctoral researcher's Thessa page",
  "STUDENT_ID_NUMBER": "Student ID number",
  "ACCOUNT_STATUS_OPEN_CLOSED": "Account status (open/closed)",
  "IF_ACCOUNT_IS_CLOSED_REASON_F": "If account is closed, reason for closing",
  "IS_ACCOUNT_ACTIVE_ACTIVE_INAC": "Is account active (active/inactive)",
  "EMAIL2": "email",
  "FACULTY1": "faculty",
  "FACULTY_OF_DEGREE": "Faculty of degree",
  "STARTING_DATE_OF_DOCTORAL_STUD": "Starting date of doctoral studies",
  "DOES_THE_DOCTORAL_CANDIDATE_HA": "Does the doctoral researcher have a supervision plan",
  "IS_THE_SUPERVISION_PLAN_IN_EFF": "Is the supervision plan in effect",
  "SUPERVISORS2": "supervisors",
  "SUPERVISORS_AND_THEIR_EMAIL_AD": "Supervisors and their email addresses",
  "IS_THE_ANNUAL_REPORT_SUBMITTED": "Is the annual report submitted",
  "THE_ANNUAL_REPORTING_IS_CLOSED": "The annual reporting is closed and concluded",
  "NEXT_REPORT_S_DEADLINE": "Next report's deadline",
  "THESIS_COMMITTEE_HAS_BEEN_ASSI": "Thesis committee has been assigned",
  "MEMBERS_OF_THE_THESIS_COMMITTE": "Members of the thesis committee and their email addresses",
  "OVERALL_PERCENTAGE_OF_COMPLETI": "Overall percentage of completion of thesis work",
  "ADVANCEMENT_OF_THESIS_WORK_COM": "Advancement of thesis work completion during the reporting period",
  "FULL_TIME_PART_TIME": "Full time/part time",
  "SALARIES2": "salaries",
  "GRANTS2": "grants",
  "GRANTS_OBTAINED_AND_APPLIED_FO": "Grants obtained and applied for",
  "EXPORT": "Export",
  "CREATE_SUPERVISION_PLAN": "Create supervision plan",
  "DELETE_SUPERVISION_PLAN": "Delete supervision plan",
  "ACCEPT_PLAN": "Accept plan",
  "VALID_BETWEEN": "Valid between",
  "VALID_FROM": "Valid indefinitely from",
  "VALID_INDEFINITELY": "Supervision plan is valid indefinitely",
  "DECISIONS": "Decisions",
  "SIGNATURES": "Signatures",
  "SIGNED": "Signed",
  "NOT_YET_SIGNED": "Not yet signed",
  "WHAT_HAVE_YOU_DECIDED_ABOUT_TH": "Please fill out at least one optional field with your decision on how you will work towards the PhD together.",
  "ACTIVE": "Active",
  "PENDING": "Pending",
  "EXPIRED": "Expired",
  "GENERAL_TERMS_ON": "General terms on",
  "RIGHTS_OBLIGATIONS_AND_RESPON2": "Rights, Obligations and Responsibilities in doctoral training at the University of Helsinki",
  "THAT_APPLY_TO_THE_SUPERVISION": "that apply to the Supervision Plan.",
  "NO_COURSES_OR_WORKSHOPS_IN_PLA": "No courses or workshops in plan yet.",
  "NO_THESIS_INSTRUCTIONS_IN_PLAN": "No thesis instructions in plan yet.",
  "ADD_TEACHING": "Add teaching",
  "TYPE_OF_TEACHING": "Type of teaching",
  "DATES1": "Dates",
  "COURSE_OR_WORKSHOP_NAME": "Course or workshop name",
  "MY_ROLE": "My role",
  "WORK_HOURS": "Work hours",
  "STUDENT_S_NAME": "Student's name",
  "THESIS_LEVEL": "Thesis level",
  "THESIS_TOPIC_OR_TITLE": "Thesis topic or title",
  "SIGNED_UP_ON": "Signed up on",
  "USERNAME": "Username:",
  "ACTIVATE_ACCOUNT_NOW": "Activate account now",
  "USER_ACCOUNT_IS_NOT_YET_ACTIVA": "User account is not yet activated",
  "CHECK_THE_ACCOUNT_DETAILS_IF": "Check the account details — if the user is actually in your organization, activate the account. Otherwise",
  "CONTACT_THESSA_S_ADMINS": "contact Thessa's admins",
  "CLOSE_ACCOUNT": "Close account",
  "REASON_FOR_CLOSING_THE_ACCOUNT": "Reason for closing the account",
  "FACULTY2": "Faculty:",
  "MAJOR": "Major:",
  "DOCTORAL_PROGRAMME1": "Doctoral programme:",
  "BEGINNING_OF_DOCTORAL_STUDIES1": "Beginning of doctoral studies:",
  "BACK_TO": "Back to",
  "ORGANIZATION": "Organization:",
  "STUDENT_NUMBER2": "Student number:",
  "VERIFIED_FROM_LDAP": "Verified from LDAP)",
  "NOT_VERIFIED_REPORTED_BY_DOCT": "Not verified, reported by doctoral researcher)",
  "THESIS_COMMITTEE_MEMBER1": "Thesis Committee Member",
  "IN_THESIS_COMMITTEE_FOR": "In thesis committee for {{count}} doctoral researcher",
  "IN_THESIS_COMMITTEE_FOR_other": "In thesis committee for {{count}} doctoral researchers",
  "THE_INVITATION_HAS_EXPIRED": "<0>The invitation has expired.</0> Please ask for a new invite from the doctoral researcher.",
  "THERE_WAS_AN_ERROR_IN_LOADING_THE_INVITATION": "<0>There was an error in loading the invitation.</0> Please <1>try again</1>. Please <2>let us know</2> if the problem does not go away.",
  "INVITATION_DECLINED": "<0>Invitation declined.</0> Thank you for responding!",
  "YOUR_ACCOUNT_IS_CLOSED": "Your account is closed",
  "SUPERVISION_PLAN_NOT_FOUND": "Supervision plan not found",
  "IN_THESIS": "In thesis",
  "PUBLISHED": "Published",
  "NOT_STARTED": "Not started",
  "THE_SUPERVISION_PLAN_AFFECTS_THE_DOCTORAL": "The supervision plan affects the doctoral researcher and all supervisors of the doctoral researcher.",
  "IT_IS_RECOMMENDED_THAT_SUPERVISION_PLANS": "It is recommended that supervision plans are created when everyone it affects is present. If someone can't make it, be sure to create a plan that they too can sign.",
  "CREATE_AND_SIGN_PLAN_AS": "Save final version and sign",
  "SAVE_DRAFT": "Save draft",
  "SAVE_DRAFT_FEEDBACK": "Draft saved",
  "LOADING_DOCTORAL_PROGRAMMES": "Loading doctoral programmes...",
  "FORM_IS_INCOMPLETE": "Form is incomplete",
  "DESCRIPTION": "Description",
  "ROLE_IN_CONFERENCE": "Role in conference",
  "FAILED_TO_SAVE": "<0>Failed to save.</0> Try again or <1>contact us</1> if the problem persists.",
  "DOCTORAL_SCHOOL": "Doctoral school",
  "ROLE": "Role",
  "PREVIOUS_PAGE": "Previous <0>page</0>",
  "NEXT_PAGE": "Next <0>page</0>",
  "ROWS": "rows",
  "PAGE_OF": "<0>Page {{currentPage}}</0> <1>of {{totalPages}}</1>",
  "PAGE": "Page",
  "MAX_SIZE_PER_ATTACHMENT": "Max size per attachment:",
  "UPLOAD_ATTACHMENTS": "Upload attachments",
  "UPLOADING": "Uploading...",
  "WHAT_PLANS_DO_YOU_HAVE": "What plans do you have? Do your completed studies support this goal?",
  "WRITE_HERE_ABOUT_YOUR_RESEARCH_PROGRESS": "Write here about your research progress during the reporting period. Stick to the main points and be brief in order to help the Thesis Committee easily evaluate your progress. Note, that you can upload your updated research plan, or other relevant material, as an attachment to this report.",
  "FOR_EXAMPLE_WRITE_ABOUT_HOSTING_A_VISITOR": "For example, write about hosting a visitor or organising national field trips.",
  "FOR_EXAMPLE_HAVE_YOU_WRITTEN_A_BLOG": "For example, have you written a blog or a popular science article, organized an event aimed at an audience outside your own field or tried to have an impact in any other ways?",
  "NO_REPORTS": "No reports",
  "ANY": "Any",
  "FILTER_BY": "Filter by {{target}}",
  "SORT_BY": "Sort by",
  "THERE_WAS_AN_ERROR_IN_LOADING_DOCTORAL_CANDIDATES": "<0>There was an error in loading doctoral researchers.</0> Please <1>try again</1>. Please <2>let us know</2> if the problem does not go away.",
  "THERE_ARE_NO_DOCTORAL_CANDIDATES_IN_THE_SELECTED": "No doctoral researchers found.",
  "DOCTORAL_PROGRAMMES": "Doctoral programmes",
  "ERROR_LOADING_DOCTORAL_PROGRAMMES": "<0>Error loading doctoral programmes.</0> Please <1>try again</1>. If it does not help, please <2>let us know</2>.",
  "SELECT_NONE": "Select none",
  "SELECT_ALL": "Select all",
  "WHEN_WILL_EACH_STAGE_OF_THE_PUBLICATION_BEGIN": "When will each stage of the publication begin? If the stage in the future, estimate when it would begin. Each status has to start after the previous one",
  "THE_STATUSES_ARE_IN_INCORRECT_ORDER": "The statuses are in incorrect order: each status has to start after the previous one.",
  "TO_BE_INCLUDED_IN_THE_PHD_THESIS": "To be included in the PhD thesis",
  "SAVE_PUBLICATION": "Save publication",
  "CREATE_ANOTHER": "Create another",
  "CREATING_ANOTHER_PUBLICATION": "Creating another publication",
  "I_HAVE_COMPLETED_THIS_COURSE": "I have completed this course",
  "SAVE_COURSE": "Save course",
  "CREATING_ANOTHER_COURSE": "Creating another course",
  "CREATING_ANOTHER_GRANT": "Creating another grant",
  "SOURCE_OF_GRANT": "Source of grant",
  "FROM_WHICH_ORGANIZATION_IS_THE_GRANT_FROM": "From which organization is the grant from?",
  "HOW_MUCH_IS_THIS_GRANT": "How much is this grant?",
  "SAVE_GRANT": "Save grant",
  "ENDS_AT": "Ends at",
  "BEGINS_AT": "Begins at",
  "WHAT_IS_THE_INTENDED_USAGE_OF_THIS_GRANT": "What is the intended usage of this grant?",
  "DURATION_OF_GRANT": "Duration of grant",
  "WHEN_DOES_THIS_GRANT_BEGIN_AND_END": "When does this grant begin and end? If there is no clear date, make an estimate.",
  "CREATING_ANOTHER_TEACHING": "Creating another teaching",
  "BEGINNING_DATE": "Beginning date",
  "END_DATE": "End date",
  "NAME_OF_THE_COURSE_OR_WORKSHOP": "Name of the course or workshop",
  "MY_ROLE_IN_TEACHING": "My role in teaching",
  "FOR_EXAMPLE_LECTURER_OR_ASSISTANT": "For example <0>lecturer</0> or <1>assistant</1>.",
  "TOPIC_OR_TITLE_OF_THE_THESIS": "Topic or title of the thesis",
  "LEVEL_OF_THESIS": "Level of thesis",
  "COURSE_OR_WORKSHOP": "Course or workshop",
  "HOW_MANY_HOURS_OF_WORK_DID_YOU_SPEND_IN_TOTAL": "How many hours of work did you spend in total?",
  "NAME_OF_THE_STUDENT_WHO_YOUR_WERE_INSTRUCTING": "Name of the student who your were instructing",
  "MY_ROLE_IN_INSTRUCTING_THE_STUDENT": "My role in instructing the student",
  "FOR_EXAMPLE_MAIN_SUPERVISOR_OR_CO_SUPERVISOR": "For example <0>main supervisor</0> or <1>co-supervisor</1>.",
  "FOR_EXAMPLE_MASTERS_THESIS_OR_BACHELORS_THESIS": "For example <0>master's thesis</0> or <1>bachelor's thesis</1>.",
  "CREATING_ANOTHER_SALARY": "Creating another salary",
  "SOURCE_OF_SALARY": "Source of salary",
  "FROM_WHICH_ORGANIZATION_AND_OR_PROJECT_DO_YOU_GET": "From which organization and/or project do you get your salary from?",
  "DURATION_OF_SALARY": "Duration of salary",
  "WHEN_DOES_THIS_SALARY_BEGIN_AND_END_IF_THERE_IS_NO": "When does this salary begin and end? If there is no clear date, make an estimate.",
  "SAVE_SALARY": "Save salary",
  "DUE_DATE": "Due date",
  "SEND_REMINDER_EMAILS_AT": "Send reminder emails at",
  "SAVE_REMINDER": "Save reminder",
  "LOG_IN_OR_SIGN_UP_TO_ACCEPT_INVITATION": "Log in or sign up to accept invitation.",
  "LOG_IN_WITH_UNIVERSITY_OF_HELSINKI_ACCOUNT": "Log in with University of Helsinki account",
  "I_DON_T_HAVE_A_UNIVERSITY_OF_HELSINKI_ACCOUNT": "I don't have a University of Helsinki account",
  "LOG_IN_WITH_AN_EXISTING_THESSA_ACCOUNT": "Log in with an existing Thessa account.",
  "LOG_IN_OR_SIGN_UP_FOR_A_THESSA_ACCOUNT_TO": "Log in or sign up for a Thessa account to accept invitation.",
  "SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT": "Sign up for a new Thessa account",
  "ERROR_LOGIN_OR_SIGN_UP": "ERROR - login or sign up!",
  "INVITATION_HAS_EXPIRED_AT": "Invitation has expired at {{date}}. <0>Go to Thessa's front page</0>",
  "YOU_HAVE_BEEN_INVITED_TO_BECOME": "You have been invited to become <0>{{roles}} for {{inviter}}</0> in Thessa.",
  "ACCEPT_INVITATION": "Accept invitation",
  "THERE_WAS_AN_ERROR_IN_DECLINING_THE_INVITATION": "<0>There was an error in declining the invitation.</0> Please try again. Please <1>let us know</1> if the problem does not go away.",
  "SIGN_UP_FOR_A_NEW_THESSA_ACCOUNT_TO": "Sign up for a new Thessa account to accept invitation.",
  "SIGN_UP": "Sign up",
  "ERROR_IN_SIGN_UP": "Error in sign up.",
  "LOG_IN_FAILED_TRY_AGAIN": "Log in failed. Try again.",
  "ROLES": "Roles",
  "DOES_NOT_HAVE_ANY_ROLES_ASSIGNED": "<0>{{name}} does not have any roles assigned.</0> Everyone must have at least one role.",
  "THERE_WAS_AN_ERROR_IN_UPDATING_ROLES": "<0>There was an error in updating roles.</0> Please try again. Please <1>let us know</1> if the problem does not go away.",
  "ONLY_ONE_PERSON_CAN_BE_THE_COORDINATING": "Only one person can be the coordinating academic.",
  "ONLY_ONE_PERSON_CAN_BE_THE_MAIN_SUPERVISOR": "Only one person can be the main supervisor.",
  "ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_COORDINATING": "One person should be selected as the coordinating academic.",
  "ONE_PERSON_SHOULD_BE_SELECTED_AS_THE_MAIN": "One person should be selected as the main supervisor.",
  "MYSELF": "Myself",
  "FILTERS": "Filters",
  "CHOOSE_A_DOCTORAL_PROGRAMME": "Choose a doctoral programme",
  "LOADING_INACTIVE_USER": "Loading inactive user...",
  "NO_STUDENT_NUMBER": "No student number",
  "SIGNED_UP": "Signed up",
  "NO_SUPERVISORS": "No supervisors",
  "SUPERVISOR_INVITATIONS": "Supervisor invitations",
  "ACTIVATE": "Activate",
  "SEND_EMAIL": "Send email",
  "NO_EMAIL_ADDRESS": "No email address",
  "THERE_WAS_AN_ERROR_IN_ACTIVATING_THE_USER_ACCOUNT": "<0>There was an error in activating the user account.</0> Please <1>try again</1>. Please <2>let us know</2> if the problem does not go away.",
  "SELECT_DOCTORAL_PROGRAMME": "Select doctoral programme",
  "NO_DOCTORAL_PROGRAMME_SELECTED": "No doctoral programme selected",
  "SELECT_DOCTORAL_PROGRAMMES": "Select doctoral programmes",
  "ALL_DOCTORAL_PROGRAMMES": "All doctoral programmes",
  "NOT_VERIFIED": "Not verified",
  "VERIFY_NOW": "Verify now",
  "DOCTORAL_CANDIDATE_DOES_NOT_HAVE_ANY_SUPERVISORS": "Doctoral researcher does not have any supervisors.",
  "COUNT_THESIS_COMMITTEE_MEMBER": "{{count}} thesis committee member",
  "COUNT_THESIS_COMMITTEE_MEMBER_other": "{{count}} thesis committee members",
  "COUNT_SUPERVISOR_RELATIONSHIP": "{{count}} supervisor relationship",
  "COUNT_SUPERVISOR_RELATIONSHIP_other": "{{count}} supervisor relationships",
  "COUNT_PUBLICATION_IN_PROGRESS": "{{count}} publication in progress",
  "COUNT_PUBLICATION_IN_PROGRESS_other": "{{count}} publications in progress",
  "COUNT_PUBLICATION_PUBLISHED": "{{count}} publication published",
  "COUNT_PUBLICATION_PUBLISHED_other": "{{count}} publications published",
  "MAIN_SUPERVISOR": "Main supervisor",
  "MAIN_SUPERVISOR_other": "Main supervisors",
  "COORDINATING_ACADEMIC": "Coordinating academic",
  "COORDINATING_ACADEMIC_other": "Coordinating academics",
  "COUNT_DOCTORAL_CANDIDATE": "{{count}} doctoral researcher found",
  "COUNT_DOCTORAL_CANDIDATE_other": "{{count}} doctoral researchers found",
  "ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_ACCOUNT": "Only show doctoral researchers without account",
  "ONLY_SHOW_DOCTORAL_CANDIDATES_WITHOUT_SUPERVISOR": "Only show doctoral researchers without supervisor",
  "COUNT_DOCTORAL_CANDIDATE_S_REPORT": "{{count}} doctoral researcher's report is late ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_REPORT_other": "{{count}} doctoral researchers' report is late ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_LATEST": "{{count}} doctoral researcher's latest report has not yet been completed ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_S_LATEST_other": "{{count}} doctoral researchers' latest report has not yet been completed ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME": "{{count}} doctoral researcher is on time ({{percentage}}%)",
  "COUNT_DOCTORAL_CANDIDATE_IS_ON_TIME_other": "{{count}} doctoral researchers are on time ({{percentage}}%)",
  "COUNT_ITEM_IN_QUEUE": "{{count}} item in queue",
  "COUNT_ITEM_IN_QUEUE_other": "{{count}} items in queue",
  "OLDEST_ITEM_FIRST": "oldest item first",
  "ISPROCEEDING": "isProceeding",
  "IS_THE_ANNUAL_REPORT_PROCEEDING_AS_PLANNED": "Is the annual report proceeding as planned",
  "CONCLUSIONCOMMENT": "conclusionComment",
  "COMMENT_ABOUT_THE_PROGRESS_OF_THE": "Comment about the progress of the dissertation work written by main supervisor",
  "PROGRESS_FEEDBACK_BY": "Progress feedback by",
  "IS_THE_DISSERTATION_WORK_PROCEEDING_AS_PLANNED": "Is the dissertation work proceeding as planned?",
  "PROGRESS_FEEDBACK_IS_MISSING": "Progress feedback is missing",
  "PROGRESS_FEEDBACK_SUBMITTED": "Progress feedback submitted",
  "GIVE_PROGRESS_FEEDBACK": "Give progress feedback",
  "THERE_WAS_A_PROBLEM_SUBMITTING_PROGRESS_FEEDBACK": "There was a problem submitting progress feedback",
  "PROGRESS_FEEDBACK_SUBMITTED_REDIRECTING_YOU_BACK": "Progress feedback submitted. Redirecting you back to <0>overview page</0>",
  "PROGRESS_FEEDBACK": "Progress feedback",
  "COMMENT1": "Comment",
  "OPTIONAL_COMMENT_ABOUT_THE_PROGRESS_OF_THE": "Optional comment about the progress of the dissertation work",
  "MOBILE": "Mobile",
  "DESKTOP": "Desktop",
  "VIEW_ACCOUNT": "View account",
  "EDIT_ROLES": "Edit roles",
  "ENDING_ON_DATE": "Ending on {{date}}",
  "INVITE_SUPERVISOR": "Invite supervisor",
  "ADD_SUPERVISOR": "Add supervisor",
  "FAILED_TO_SEND_INVITE_PLEASE_TRY_AGAIN": "<0>Failed to send invite.</0> Please try again or <1>contact Thessa support</1> for help.",
  "SAVE_ROLES": "Save roles",
  "ERROR_IN_SAVING_ROLES_TRY_AGAIN_OR": "Error in saving roles. Try again or <0>contact support</0>.",
  "INVITATION": "Invitation",
  "REMOVE_INVITATION": "Remove invitation",
  "YOU_HAVE_NO_ACTIVE_DOCTORAL_CANDIDATES_IN_THESSA": "You have no active doctoral researchers in Thessa right now.",
  "NOT_IN_THESSA": "Not in Thessa",
  "UPLOAD_AN_EXCEL_FILE_WITH_SUPERVISOR_RELATIONSHIPS": "Upload an Excel file with supervisor relationships",
  "CHANGE_FILE": "Change file",
  "GO_BACK": "Go back",
  "SAVE_SUPERVISOR_RELATIONSHIPS": "Save supervisor relationships",
  "DOCTORAL_CANDIDATE_ROW": "Doctoral researcher row",
  "IGNORE": "Ignore",
  "COORDINATING_ACADEMIC_EMAIL": "Coordinating academic email",
  "MAIN_SUPERVISOR_EMAIL": "Main supervisor email",
  "SUPERVISOR_EMAIL": "Supervisor email",
  "SUPERVISOR_RELATIONSHIPS": "Supervisor relationships",
  "UPLOAD_A_FILE_WITH_SUPERVISOR_RELATIONSHIPS": "Upload a file with supervisor relationships",
  "PICK_COLUMNS_AND_ROWS_TO_IMPORT": "Pick columns and rows to import",
  "FOR_EACH_COLUMN_WITH_RELEVANT_DATA": "For each column with relevant data (student number and supervisors' email addresses), pick the correct type of column from the dropdown menu. Leave rest of the columns marked as ignored.",
  "THEN_SELECT_EACH_ROW_WITH_A_SUPERVISOR": "Then, select each row with a supervisor relationship and press the Preview button.",
  "CHECK_THAT_THE_FOLLOWING_PREVIEW_IS_CORRECT": "Check that the following preview is correct. If the preview is correct, press the Save supervisor relationships button to start the import. If the preview is incorrect, you can upload a new Excel file or press the go back button and change the column and row selections.",
  "IMPORT_SUCCESSFUL": "Import successful",
  "IMPORT_FAILED_PLEASE_CONTACT_US_FOR_HELP": "<0>{{message}}</0> If needed, please <1>contact us</1> for help.",
  "FILE_UPLOAD_WAS_NOT_SUCCESSFUL_PLEASE_TRY_AGAIN": "<0>File upload was not successful.</0> Please try again.",
  "NOTES": "Notes",
  "DONE1": "Done",
  "COURSE_TITLE": "Course title",
  "SALARY_SOURCE": "Salary source",
  "GRANT_SOURCE": "Grant source",
  "STUDENT": "Student",
  "STATUS_OF_THE_PHD_PROJECT": "Status of the PhD project",
  "TIME_SPENT_ON_PHD": "Time spent on PhD",
  "SOCIAL_IMPACT_AND_PUBLIC_OUTREACH": "Social impact and public outreach",
  "ATTACHMENTS": "Attachments",
  "THREE_POSITIVE_THINGS": "Three positive things",
  "THREE_NEGATIVE_THINGS": "Three negative things",
  "CAREER_PLANNING": "Career planning",
  "CONCLUSIONS": "Conclusions",
  "OTHER_WORK": "Other work",
  "WAIT_FOR_ONE_MINUTE_BEFORE_RESENDING": "Wait for one minute before resending",
  "ACCOUNT_IS_CLOSED": "Account is closed",
  "ACCOUNT_IS_ACTIVE": "Account is active",
  "ACCOUNT_IS_INACTIVE": "Account is inactive",
  "ESTIMATED_STATUS_OF_THE_PHD_PROJECT": "Estimated status of the PhD project",
  "ESTIMATED_TIME_OF_PHD_DEFENCE": "Estimated time of PhD defence",
  "CAREER_PLANS_AFTER_YOUR_PHD": "Career plans after your PhD",
  "BACK_TO_OVERVIEW": "Back to overview",
  "BACK_TO_REPORT": "Back to report",
  "CHANGE_SUPERVISOR": "Change supervisor",
  "ADD_SUPERVISOR_FOR_NAME": "Add supervisor for {{name}}",
  "FAILED_TO_ADD_SUPERVISOR_PLEASE_TRY_AGAIN_OR": "<0>Failed to add supervisor.</0> Please try again or <1>contact Thessa support</1> for help.",
  "FAILED_TO_INVITE_SUPERVISOR_PLEASE_TRY_AGAIN_OR": "<0>Failed to invite supervisor.</0> Please try again or <1>contact Thessa support</1> for help.",
  "INVITE_SUPERVISOR_FOR_NAME": "Invite supervisor for {{name}}",
  "CHOOSE_LANGUAGE": "Choose language",
  "PREPARATION_AND_ANALYSIS": "Preparation and analysis",
  "WRITING": "Writing",
  "NAME_IS_NOT_YET_ON_THESSA_WHEN_THEY_SIGN_UP": "<0>{{name}} is not yet on Thessa.</0> When they sign up to Thessa, you will be able to see their details.",
  "INVITE_THESIS_COMMITTEE": "Invite thesis committee",
  "EDIT_THESIS_COMMITTEE": "Edit thesis committee",
  "YOUR_USER_ACCOUNT_NEEDS_TO_BE_ACTIVATED": "Your user account needs to be activated before you can create your first report. Before your account can be activated, you need to <0>invite all thesis committee members</0> in Thessa.",
  "YOU_WILL_RECEIVE_AN_EMAIL_WHEN_YOUR_ACCOUNT": "You will receive an email when your account has been activated.",
  "ESTIMATE_MADE_ON": "Estimate made on",
  "PHD_PROJECT_STATUS": "Status",
  "TIME_SPENT_PERIOD": "Period",
  "EDIT_PRELIMINARY_TITLE_OF_DISSERTATION": "Edit preliminary title of dissertation",
  "CREATE_PRELIMINARY_TITLE_OF_DISSERTATION": "Add preliminary title of dissertation",
  "SUBJECT_OR_STUDENT": "Subject or student",
  "APPLICATION_SENT": "Application sent",
  "PLANNING_TO_APPLY": "Planning to apply",
  "CREDITS_one": "{{count}} credit",
  "CREDITS_other": "{{count}} credits",
  "CR": "{{count}} cr",
  "WHAT_WAS_DECIDED": "What was decided?",
  "ROLE_NAME": {
    "key": "value",
    "doctoral-candidate": "Doctoral researcher",
    "coordinating-academic": "Coordinating academic",
    "main-supervisor": "Main supervisor",
    "supervisor": "Supervisor",
    "thesis-committee-member": "Thesis committee member",
    "salary-system-foreman": "Line manager",
    "coordinator": "Coordinator"
  },
  "CHECK_REPORT_STATUS": "Check report status.",
  "CHECK_SUPERVISION_PLAN_STATUS": "Check supervision plan status.",
  "SEARCH_BY": "Search by {{keyword}}",
  "COMPLETE_THE_REPORT_FORM": "Complete the report form and submit the report.",
  "CONCLUSION_CAN_BE_WRITTER_AFTER": "Conclusion can be written after report has been submitted.",
  "YES": "Yes",
  "NO": "No",
  "PLEASE_INVITE_TCMS_TO_THESSA": "Please invite all your thesis committee members to Thessa.",
  "COORDINATOR_TOOLS": {
    "key": "value",
    "MAIN_HEADING": "Tools",
    "HEADINGS": {
      "key": "value",
      "MANAGE_USERS": "Users",
      "STATISTICS": "Statistics",
      "OTHER": "Other"
    },
    "TOOLS": {
      "key": "value",
      "LATEST_REPORTS": "Latest reports",
      "INCOMPLETE_REPORTS": "Incomplete reports",
      "ADMIN_REPORT": "Thessa Admin Report",
      "IMPORT_SUPERVISOR_RELATIONSHIPS": "Import supervisor relationships"
    },
    "DESCRIPTIONS": {
      "key": "value",
      "DOCTORAL_CANDIDATES": "Manage doctoral researchers",
      "USERS": "Manage all users",
      "REPORT_CONCLUSIONS_OVERVIEW": "See report conclusion for every doctoral researcher",
      "LATEST_REPORTS": "See latest report's status for every doctoral researcher",
      "INCOMPLETE_REPORTS": "See doctoral researchers and supervisors for incomplete reports",
      "ADMIN_REPORT": "Export details of doctoral researchers to CSV",
      "IMPORT_SUPERVISOR_RELATIONSHIPS": "From Excel to Thessa"
    }
  },
  "SHOW_ALL_DOCTORAL_CANDIDATES": "Show all doctoral researchers",
  "HIDE_INACTIVE_DOCTORAL_RESEARCHERS": "Hide inactive doctoral researchers",
  "DOCTORAL_RESEARCHER_OVERVIEW": "Doctoral researcher overview",
  "LAST_COMPLETED_REPORT": "Last completed report",
  "FILTER_DOCTORAL_RESEARCHERS": "Filter doctoral researchers",
  "SUPERVISOR_RELATIONSHIP_OVERVIEW": {
    "key": "value",
    "LINK": "https://studies.helsinki.fi/instructions/article/services-doctoral-education",
    "IF_MISSING": "If a coordinating academic or supervisors are missing from the list,",
    "IF_MISSING_THEN_CONTACT": "contact doctoral student services of your faculty",
    "IF_CHANGED": "If there are changes in people,",
    "IF_CHANGED_THEN_CONTACT": "contact your doctoral programme"
  },
  "THESIS_COMMITTEE_MEMBERS": {
    "key": "value",
    "SUPERVISORS_ARE_HANDLED_BY_OTHERS": "Supervisors are handled by doctoral student services of your faculty and by your doctoral programme's officer. Contact details are in ",
    "LINK": "https://studies.helsinki.fi/instructions/article/services-doctoral-education",
    "LINK_TITLE": "instructions for students",
    "INVITATION_HEADING": "Invite thesis committee members",
    "INVITATION_HEADING_FOR_SUPERVISORS": "Invite supervisors",
    "INVITATION_LABEL": "Thesis committee member's email address",
    "INVITATION_LABEL_FOR_SUPERVISORS": "Supervisor's email address"
  },
  "CONTACT": {
    "HEADING": "Contact us",
    "PLANNING_OFFICER": "Doctoral programme planning officer",
    "DOCTORAL_PROGRAMME_CONTACT_LINK_PREFIX": "Contact details for your doctoral programme:",
    "DOCTORAL_PROGRAMME_CONTACT_LINK_URL": "https://studies.helsinki.fi/instructions/article/services-doctoral-education",
    "PLANNING_OFFICER_HELPS_WITH": "Planning officers help with such topics as:",
    "PLANNING_OFFICER_HELPS_WITH_ITEMS": [
      "Annual reports",
      "Supervisors and thesis committee",
      "Supervision plans",
      "Do I need to use Thessa?"
    ],
    "TECHNICAL_PROBLEMS": "Technical problems",
    "TECHNICAL_EMAIL_PREFIX": "You can send email about technical problems and errors in Thessa to",
    "TECHNICAL_EMAIL_LINK_TEXT": "Thessa's developers"
  },
  "DATE_RANGE_PICKER": {
    "INVALID_START_DATE": "The start date is not a valid date.",
    "INVALID_END_DATE": "The end date is not a valid date.",
    "INVALID_END_IS_BEFORE_START": "The end date must be after the start date."
  },
  "COORDINATOR_DOCTORAL_CANDIDATES": {
    "PLACEHOLDER": "Doctoral programme or student number",
    "LABEL": "Search by doctoral programme or student number",
    "ACTION": "Search"
  },
  "END_OF_SENTENCE_PERIOD": ".",
  "RESOURCES": {
    "INTRO_VIDEO": "LF7j_ITOYTw"
  },
  "MENU": "Menu",
  "FRONTPAGE": "Front page",
  "OPEN_PROFILE_MENU": "Open profile menu",
  "CLOSE_PROFILE_MENU": "Close profile menu",
  "OPEN_MAIN_MENU": "Open main menu",
  "CLOSE_MAIN_MENU": "Close main menu",
  "CHANGE_LANGUAGE": "Change language",
  "PROFILE_MENU": "Profile menu",
  "MAIN_MENU": "Main menu",
  "FOOTER_MENU": "Footer menu",
  "DATE_NOT_SPECIFIED": "Date not specified",
  "EMAIL_OR_USER_INPUT": {
    "HINT": "A valid email address is required, or pick a user from suggestions"
  },
  "FORM": {
    "REQUIRED_FIELD": "Required",
    "OPTIONAL_FIELD": "Optional",
    "VALID_EMAIL_ADDRESS_REQUIRED": "Please enter a valid email address",
    "PASSWORD_REQUIREMENTS": "Minimum length 10 characters",
    "VALID": "Valid",
    "INVALID": "Invalid",
    "OPTIONAL": "Optional",
    "COMPLETE_FIELDS_TO_SAVE_FORM": "Please note that all fields must be completed in order to save the form.",
    "COMPLETE_FIELDS_TO_SUBMIT_FORM": "Please note that all fields must be completed in order to submit the form."
  },
  "INVITATION_ROLE": {
    "COORDINATING_ACADEMIC": "a coordinating academic",
    "MAIN_SUPERVISOR": "a main supervisor",
    "SUPERVISOR": "a supervisor",
    "THESIS_COMMITTEE_MEMBER": "a thesis committee member"
  },
  "EDIT_PUBLICATION": "Edit publication",
  "EDIT_SALARY": "Edit salary",
  "EDIT_GRANT": "Edit grant",
  "EDIT_COURSE": "Edit course",
  "EDIT_COURSE_WORKSHOP": "Edit course/workshop",
  "EDIT_THESIS_INSTRUCTION": "Edit thesis instruction",
  "EDIT_TEACHING": "Edit teaching",
  "FOR_STUDENT": "for student",
  "CREATE_NEW_REMINDER": "Create new reminder",
  "LOGO_OF_UNIVERSITY_OF_HELSINKI": "Logo of University of Helsinki",
  "NEW": "New",
  "CHANGED": "Changed",
  "INDICATOR": "indicator",
  "STATUS_IN_THE_MOST_RECENT": "Status in the most recent report",
  "SHOW_DETAILS": "Show details",
  "HIDE_DETAILS": "Hide details",
  "ADD_PUBLICATION": "Add publication",
  "ADD_COURSE": "Add course",
  "EDIT_NOTES": "Edit <0>notes</0>",
  "CANCEL_EDITING_NOTES": "Cancel <0>editing notes</0>",
  "SAVE_NOTES": "Save <0>notes</0>",
  "COURSE_IS_COMPLETED": "Course is completed",
  "SEND_MESSAGE": "Send message",
  "REMOVE_COMMENT": "Remove comment",
  "REPORT_VIEW": {
    "ERROR": {
      "MISSING_REPORT": "The report is missing.",
      "FAILED_TO_SAVE_REPORT": "<0>Could not save report.</0> Check the report form for errors and try again, or <1>contact us</1>.",
      "FAILED_TO_SUBMIT_REPORT": "<0>Could not submit report for review.</0> Check the report form for errors and try again, or <1>contact us</1>.",
      "FAILED_TO_DELETE_REPORT": "<0>Deleting report failed.</0> Try again or <1>contact us</1>."
    }
  },
  "PHD_PROJECT": {
    "ERROR": {
      "GENERAL_ERROR_HEADING": "An error has occurred in Thessa.",
      "YOU_CAN_RETRY_OR": "You can try refreshing the page but if it does not help, please",
      "INCLUDE_THIS_TEXT": "Please include this text in your email",
      "CANNOT_INITIALIZE": "Failed to load PhD Project details from server."
    }
  },
  "CANCEL_INVITING_THESIS_COMMITTEE_MEMBER": "Cancel inviting thesis committee member",
  "INFORMATION": "Information",
  "COMMENT": "Comment",
  "WRITE_YOUR_COMMENT_HERE": "Write your comment here",
  "HIDE_DISCUSSION": "Hide discussion",
  "SHOW_DISCUSSION": "Show discussion",
  "VIEW_SUPERVISION_PLAN": "View supervision plan",
  "ADD_COURSE_WORKSHOP": "Add course/workshop",
  "ADD_THESIS_INSTRUCTION": "Add thesis instruction",
  "REPORT_STATUS": "Report status",
  "UNTITLED": "Untitled",
  "UNTITLED_PUBLICATION": "Untitled publication",
  "UNTITLED_SALARY": "Untitled salary",
  "UNTITLED_GRANT": "Untitled grant",
  "UNTITLED_COURSE": "Untitled course",
  "UNTITLED_TEACHING": "Untitled teaching",
  "REMOVAL_CONFIRMATION": "Removal confirmation",
  "THESSA_INTRODUCTION_VIDEO": "Thessa introduction video",
  "ACCESSIBILITY_STATEMENT": "Accessibility statement",
  "INFORMATION_ON_THE_ACCESSIBILITY_OF_THE_WEBSITE": "Information on the accessibility of the website.",
  "LAST_UPDATED": "Last updated",
  "LOGIN_FOR_EXTERNAL_USERS": "Login with non-university account",
  "YOU_WILL_NOT_BE_ABLE_TO_ACCESS": "You will not be able to access Thessa when your account is closed.",
  "ASK_YOUR_DOCTORAL_PROGRAMME": "Ask your doctoral programme for details.",
  "REMINDER": "Reminder",
  "BACK_TO_STATISTICS": "Back to statistics",
  "BACK_TO_USER_DETAILS": "Back to user details",
  "TOTAL_AMOUNT_OF_CREDITS": "Total amount of credits",
  "FOR_EXAMPLE": "For example",
  "DOWNLOAD": "Download",
  "PLEASE_SELECT_ONE_OF_THE_FOLLOWING_TYPES": "Please select one of the following types.",
  "PLEASE_SELECT_YOUR_ROLE_IN_THE_CONFERENCE": "Please select your role in the conference.",
  "PLEASE_NOTE_YOU_HAVE_THE_OPTION_TO_SAVE": "Please note you have the option to save your progress at any time, even if not all required fields are filled. Remember to return and complete any missing information before final submission.",
  "DURATION_OF_TEACHING": "Duration of teaching",
  "CURRENT": "Current",
  "UPCOMING": "Upcoming",
  "SITEMAP": "Sitemap",
  "UPON_LOGGING_IN": "Upon logging in, this sitemap will automatically update to reflect the pages and features accessible to you based on your role in Thessa.",
  "OVERVIEW_OF_ALL_SITE_PAGES": "Overview of all site pages, designed for easy navigation and quick access to content.",
  "UPDATED": "Updated",
  "PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS": "Please fill out all required fields.",
  "PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS_AND_DATES": "Please fill out all required fields and enter valid dates.",
  "PLEASE_ENTER_VALID_DATES": "Please enter valid dates.",
  "IS_MISSING": "is missing",
  "THE_VALUE_ENTERED_IS_INVALID": "The value entered is invalid",
  "CLOSE": "Close",
  "CHOOSE": "Choose",
  "SUBMITTING_FORM_FAILED": "Submitting form failed",
  "TRANSCRIPT_OF_THESSA_INTRODUCTION_VIDEO": "Transcript of Thessa introduction video",
  "INTRODUCTION_TO_USING_THESSA_FOR_DOCTORAL": "Introduction to using Thessa for doctoral researchers",
  "CHOOSE_LANGUAGE_FROM_THE_LANGUAGE_NAVIGATION": "Choose language from the language navigation",
  "AFTER_LOGGING_IN": "After logging in, you will land on the homepage called \"My PhD Project\" page, which consists of sections for",
  "SUPERVISION_PLANS_ARE_CREATED_BY_YOUR_SUPERVISORS": "Supervision plans are created by your supervisors. You will receive an email when your supervisors have created a new supervision plan for you. To sign the plan, click the link in the email, and then click the \"Accept Plan\" button on the supervision plan page.",
  "PLANS_FOR_YOUR_PHD_PROJECT": "Plans for your PhD project",
  "PLANS_FOR_YOUR_PHD_PROJECT_ARE_CREATED_BY_YOU": "Plans for your PhD project are created by you. These plans include information about your publications, conferences, courses, and other activities related to your PhD project.",
  "REPORTS_ARE_CREATED_BY_YOU_AND_INCLUDE_INFORMATION": "Reports are created by you and include information about your progress in your PhD project. They can be saved as drafts and submitted when you are ready.",
  "YOUR_SUPERVISORS_ARE_ADDED_AUTOMATICALLY": "Your supervisors are added automatically. You can see who your supervisors are and their contact information.",
  "YOU_CAN_SEE_INFORMATION_ABOUT_YOUR_THESIS_COMMITTEE": "You can see information about your thesis committee members. Thesis committees members need to be invited manually by navigating to the thesis committee page.",
  "DIGITAL_SERVICE_ACCESSIBILITY_STATUS": "Digital service accessibility status",
  "GENERAL_SECTIONS_AND_FEATURES_THAT_ARE_NOT_ACCESSIBLE": "General sections and features that are not accessible",
  "SERVICES_FOR_DOCTORAL_RESEARCHERS_THAT_ARE_NOT_ACCESSIBLE": "Services for doctoral researchers that are not accessible",
  "SERVICES_FOR_SUPERVISORS_THAT_ARE_NOT_ACCESSIBLE": "Services for supervisors that are not accessible",
  "SERVICES_FOR_COORDINATORS_THAT_ARE_NOT_ACCESSIBLE": "Services for coordinators that are not accessible",
  "NOT_WITHIN_THE_SCOPE_OF_LEGISLATION": "Not within the scope of legislation",
  "ACCESSIBILITY_FEEDBACK": "Accessibility feedback",
  "SUPERVISORY_AUTHORITY": "Supervisory authority",
  "CONTACT_INFORMATION_OF_THE_REGULATOR": "Contact information of the regulator",
  "REGULATOR": "Finnish Transport and Communications Agency Traficom",
  "ACCESSIBILITY_CONTROL_UNIT": "Digital Accessibility Supervision Unit",
  "TEL_SWITCHBOARD": "Tel. (switchboard)",
  "PERCEIVABLE": "Perceivable",
  "OPERABLE": "Operable",
  "UNDERSTANDABLE": "Understandable",
  "ROBUST": "Robust",
  "ACCESSIBILITY_OF_THESSA": "Accessibility of Thessa",
  "THIS_ACCESSIBILITY_STATEMENT_APPLIES": "This accessibility statement applies to <0>the Thessa service for University of Helsinki</0>",
  "THE_SERVICE_IS_SUBJECT_TO_THE_LAW": "The service is subject to the law on providing digital services, which requires that public online services must be accessible. We have assessed the accessibility of the service ourselves.",
  "THE_ACCESSIBILITY_STATEMENT_HAS_BEEN_CREATED_ON": "The accessibility statement has been created on {{ createdDate }} and last updated on {{ lastUpdatedDate }}.",
  "INFO_AND_RELATIONSHIPS": "WCAG 1.3.1: Info and Relationships",
  "ERROR_IDENTIFICATION": "WCAG 3.3.1: Error Identification",
  "ON_INPUT": "WCAG 3.2.2: On Input",
  "USE_OF_COLOR": "WCAG 1.4.1: Use of Color",
  "CONTRAST_MINIMUM": "WCAG 1.4.3: Contrast (Minimum)",
  "PARSING": "WCAG 4.1.1: Parsing",
  "NAME_ROLE_VALUE": "WCAG 4.1.2: Name, Role, Value",
  "STATUS_MESSAGES": "WCAG 4.1.3: Status Messages",
  "THE_THESSA_SERVICE_OF_THE_UNIVERSITY_OF_HELSINKI_MEETS": "The Thessa service of the University of Helsinki meets most of the A and AA level accessibility requirements of the Web Content Accessibility Guidelines (WCAG) 2.1. The accessibility deficiencies identified are described in detail below. The aim is to remedy these deficiencies in connection with the maintenance of the digital service. Read more about <0>accessibility at the University of Helsinki.</0>",
  "DATA_TABLES_ARE_NOT_PROGRAMMATICALLY_DISPLAYED": "Data tables are not programmatically displayed in table format.",
  "THE_INFORMATION_ON_THE_CONFERENCES_AND_MOBILITY": "The information on the 'Conferences and mobility' section of the 'My PhD Project' page is not programmatically displayed in table format.",
  "FORMS_DO_NOT_SUFFICIENTLY_GUIDE_USERS_ON_INCORRECT": "Forms do not sufficiently guide users on incorrect or incomplete feeds.",
  "THE_SUBMISSION_BUTTONS_IN_THE_FORMS_FOR_SUPERVISION": "The submission buttons in the forms for supervision plans and reports are disabled when the form is filled in incompletely or incorrectly.",
  "A_FORM_DOES_NOT_DISPLAY_THE_SUBMISSION_BUTTON": "A form does not display the submission button.",
  "THE_SUBMISSION_BUTTON_FOR_THE_PROGRESS_FEEDBACK": "The submission button for the progress feedback form submitted in connection with the report is only displayed in preview mode and is otherwise hidden from users.",
  "GRAPHIC_ELEMENTS_LACK_TEXT_ALTERNATIVES_AND_ARE_NOT": "Graphic elements lack text alternatives and are not programmatically labelled as images.",
  "ON_THE_LIST_DOCTORAL_RESEARCHERS_ACCORDING_TO_REPORT": "On the 'List doctoral researchers according to report status' page, some graphic elements have been insufficiently labelled programmatically as images, while some elements lack text alternatives.",
  "TITLES_HAVE_NOT_BEEN_USED_TO_PARSE_CONTENT": "Titles have not been used to structure content.",
  "THE_TITLES_ON_THE_OVERVIEW_OF_CONCLUDING_STATEMENTS": "The titles on the 'Overview of concluding statements' page have not been used to parse content.",
  "THE_DISTINCTIVENESS_OF_LINKS_FROM_OTHER_TEXT_AND_FOCUS": "The distinctiveness of links from other text and focus styles rely solely on colours.",
  "IN_THE_COORDINATOR_SERVICES_THE_DISTINCTIVENESS": "In the coordinator services, the distinctiveness of links from other text and the focus styles of interactive elements can rely solely on colour changes.",
  "THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_COMPARED": "The contrast ratio of text elements compared to the background is insufficient.",
  "THE_CONTRAST_RATIO_OF_TEXT_ELEMENTS_ON_THE": "The contrast ratio of text elements on the 'Overview of all concluding statements' page compared to the background is insufficient.",
  "THE_SUBMISSION_BUTTON_IN_THE_FORM_FOR_ADDING_SUPERVISORS": "The submission button in the form for adding supervisors on the 'Doctoral researchers' page is disabled when the form is filled in incompletely or incorrectly.",
  "THERE_ARE_DEFICIENCIES_IN_THE_PROGRAMMATIC": "There are deficiencies in the programmatic structure of the website.",
  "THE_USER_INFORMATION_PAGE_CONTAINS_BUTTONS_THAT": "The 'User information' page contains buttons that are nested contrary to the specification, making their use challenging for users relying on keyboards or screen readers.",
  "BUTTONS_HAVE_NO_VISIBLE_OR_PROGRAMMATIC_TEXT": "Buttons have no visible or programmatic text.",
  "THE_SUPERVISOR_FORM_ON_THE_DOCTORAL_RESEARCHERS_PAGE": "The supervisor form on the 'Doctoral researchers' page contains buttons that do not contain visible or programmatic text.",
  "SCREEN_READERS_DO_NOT_AUTOMATICALLY": "Screen readers do not automatically display status messages.",
  "ON_THE_DOCTORAL_RESEARCHERS_AND_LIST_THE_REVIEWERS": "On the 'Doctoral researchers' and 'List the reviewers of incomplete reports' pages, screen readers do not automatically display status messages.",
  "NOT_COVERED_BY_LEGISLATION": "Not covered by legislation",
  "CONTENT_PUBLISHED_BY_USERS_OR_OTHER_THIRD_PARTIES": "Content published by users or other third parties in the service that is not produced, funded or controlled by the service provider.",
  "CONTENT_PUBLISHED_BY_USERS_THAT_CANNOT": "Content published by users that cannot be edited to comply with the accessibility requirements may appear on the website. Content published by users has not been programmatically marked to use the language of the content of the publication.",
  "DID_YOU_NOTICE_AN_ACCESSIBILITY_ISSUE_IN": "Did you notice an accessibility issue in our digital service? Tell us about it via email and we will do our best to rectify it.",
  "IF_YOU_NOTICE_ACCESSIBILITY_ISSUES_ON": "If you notice accessibility issues on the website, first leave your feedback with us, the administrators of the website. It may take up to 14 days to receive an answer. If you are not satisfied with the answer or do not receive an answer from us within two weeks, <0>you may file a complaint with the Finnish Transport and Communications Agency Traficom</0>. The Traficom website has detailed instructions on how to file a complaint and how the issue will be handled.",
  "PAGINATION": "Pagination",
  "GO_TO_PAGE": "Go to page",
  "AMOUNT_OF_ITEMS_PER_PAGE": "Amount of items per page",
  "THERE_WAS_AN_ERROR_IN_LOADING_SUPERVISION_PLANS": "<0>There was an error in loading supervision plans.</0> Please <1>let us know</1> if the problem does not go away.",
  "PHD_PROJECT_LIST": {
    "ERROR_DELETE_FAILED": "Failed to delete."
  },
  "THESIS_COMMITTEE": {
    "TITLE": "Thesis committee",
    "MEETINGS": "Meetings",
    "CHOOSE_FIRST_MEETING_DATE": "Select first thesis committee meeting's date",
    "NO_FIRST_MEETING_DATE_YET": "First meeting date has not yet been chosen",
    "NO_NEXT_MEETING_DATE_YET": "Next meeting date has not yet been chosen",
    "NOT_SCHEDULED": "Not scheduled",
    "CHOOSE_DATE": "Select date",
    "CHANGE_DATE": "Change date",
    "MEETING": "Thesis committee meeting",
    "FIRST_MEETING": "First thesis committee meeting",
    "NEXT_MEETING": "Next thesis committee meeting",
    "MEETING_DATE": "Meeting date",
    "MEETING_DATE_IN_PAST": "Meeting date is in the past",
    "ERROR_CHOOSING_DATE": "Error occurred while saving the meeting. Please try again.",
    "ERROR_MISSING_DATE": "Please choose meeting date before saving.",
    "REPORT_FOR_MEETING": "Report for the meeting",
    "REPORT_FOR_MEETING_READY": "<0>A report</0> is ready to be discussed in the meeting.",
    "REPORT_FOR_MEETING_MISSING": "<0>Report is missing.</0> Submit a report between <0>{{ startDate }}–{{ endDate }}</0> for it to be discussed in this meeting."
  },
  "SUPERVISION_PLAN_TITLE_NO_END_DATE": "Valid from {{startDate}}",
  "SUPERVISION_PLAN_HEAD_TITLE": "Supervision plan {{startDate}} - {{endDate}}",
  "SUPERVISION_PLAN_HEAD_TITLE_NO_END_DATE": "Supervision plan from {{startDate}}",
  "TO_UPLOAD_AN_EXCEL_FILE": "To upload an Excel file, it must contain columns for student numbers and supervisors email addresses. Each row should represent an individual doctoral researcher. Once the file is ready and properly formatted, use the upload button above to submit the Excel file",
  "NEED_A_TEMPLATE_FOR_THE_EXCEL_FILE": "Need a template for the Excel file? Download a sample file and use it by following these steps:",
  "DOWNLOAD_THE_SAMPLE_EXCEL_FILE": "<0>Download the sample Excel file:</0> Click the link below to download the sample Excel file. This file has the columns and one example row.",
  "FILL_IN_THE_DATA": "<0>Fill in the data:</0> Open the downloaded file and enter student numbers and supervisors email addresses into the provided columns. Each row should represent an individual doctoral researcher. Add new rows if more doctoral researchers need to be included.",
  "SAVE_AND_UPLOAD_THE_FILE": "<0>Save and upload the file:</0> Once the data has been filled in, save the file on the computer in the .xlsx format. Then return to this page and use the upload button above to submit the Excel file",
  "SAMPLE_EXCEL_FILE": "Download sample Excel file",
  "VALIDITY": "Validity",
  "COPY_DECISIONS_TO_NEW_PLAN": "Copy decisions to new plan",
  "COPY_DECISIONS_FROM_A_PREVIOUS_PLAN": "Copy decisions from a previous plan",
  "PREVIEW_OF_THE_SUPEVISION_PLAN_FOR": "Preview of the supervision plan for {{name}}",
  "SELECT_A_PREVIOUS_SUPERVISION_PLAN_TO_COPY_FROM": "Select a previous supervision plan to copy from",
  "PLEASE_NOTE_THAT_SUPERVISION_PLANS_MAY_CONTAIN": "Please note that supervision plans may contain sensitive information, such as research details and personal data.",
  "DOCTORAL_CANDIDATE_PARTICIPATES_IN_THE_DOCTORAL_EDUCATION_PILOT": "Doctoral researcher belongs to the doctoral education pilot",
  "DOCTORAL_EDUCATION_PILOT_PARTICIPANT": "Doctoral education pilot participant",
  "CHAPTER": "Chapter",
  "CHAPTERS": "Chapters",
  "CHAPTERS_AND_MILESTONES": "Chapters and Milestones",
  "CURRENT_CHAPTER": "Current chapter",
  "MILESTONE": "Milestone",
  "MILESTONES": "Milestones",
  "PERSONAL_MILESTONE": "Personal milestone",
  "PERSONAL_MILESTONES": "Personal milestones",
  "COMMON_MILESTONE": "General milestone",
  "COMMON_MILESTONES": "General milestones",
  "ADD_PERSONAL_MILESTONE": "Add personal milestone",
  "EDIT_PERSONAL_MILESTONE": "Edit personal milestone",
  "ADD_COMMON_MILESTONE": "Add general milestone",
  "EDIT_COMMON_MILESTONE": "Edit general milestone",
  "STARTING": "Starting",
  "STARTED": "Started",
  "PLANNED": "Planned",
  "ESTIMATED": "Estimated",
  "STARTED_FROM_GRANTING_STUDY_RIGHT": "Started <0>{{ date }}</0> from granting study right",
  "STARTED_AT_THE_THESIS_COMMITTEE_MEETING_HELD_ON": "Started at the thesis committee meeting held on <0>{{ date }}</0>",
  "BEGIN_AT_THE_THESIS_COMMITTEE_MEETING_HELD_ON": "Begins at the thesis committee meeting held on <0>{{ date }}</0>",
  "BEGIN_AT_THE_MEETING_ESTIMATED_TO_BE_HELD_ON": "Begins at the meeting estimated to be held on <0>{{ date }}</0>",
  "EACH_CHAPTER_REPRESENTS_THE_TIME_PERIOD": "Each chapter represents the time period between two thesis committee meetings.",
  "OVERVIEW": "Overview",
  "NO_MILESTONES_IN_THE_PLAN_FOR_THE_CHAPTER_YET": "No milestones in the plan for the chapter yet.",
  "VIEW_CHAPTERS": "View chapters",
  "EDIT_MILESTONES": "Edit milestones",
  "CUSTOM_MILESTONES_CREATED_BY_THE_USER": "Custom milestones created by the user. You can add as many personal milestones as you like and assign them to any chapter.",
  "A_PREDEFINED_SET_OF_MILESTONES_THAT_MUST_BE_ASSIGNED": "A predefined set of milestones, each of which must be placed in any chapter.",
  "STUDY_RIGHT_GRANTED": "Study right granted",
  "ADD_ITEM": "Add item <0>to chapter {{ id }}</0>",
  "MILESTONE_DESCRIPTION": "Milestone description",
  "MOVE": "Move",
  "OPEN_CONTEXT_MENU": "Open context menu for \"{{ milestone }}\" milestone",
  "CONTEXT_MENU": "Context menu",
  "SET_AS_COMPLETED": "Set as completed",
  "COMMON": "General",
  "PERSONAL": "Personal",
  "ADDING_MILESTONE_FAILED_WITH_ERROR_CODE": "Adding milestone failed with error code",
  "UPDATING_MILESTONE_FAILED_WITH_ERROR_CODE": "Updating milestone failed with error code",
  "DELETING_MILESTONE_FAILED_WITH_ERROR_CODE": "Deleting milestone failed with error code",
  "PRESSING_ENTER_ON_THE_KEYBOARD_WILL_ADD_THE_MILESTONE": "Pressing enter on the keyboard will add the milestone",
  "ALL_COMMON_MILESTONES_HAVE_BEEN_ASSIGNED_TO_CHAPTERS": "All general milestones have been assigned to chapters",
  "NO_COMMON_MILESTONES_AVAILABLE": "No general milestones available",
  "INPUT_EXCEEDS_THE_CHARACTER_LIMIT": "Input exceeds the character limit of {{ maxLength }} characters",
  "UNASSIGNED_COMMON_MILESTONES": "Following general milestones are not set for any chapter",
  "UNASSIGNED_COMMON_MILESTONES_AMOUNT": "{{ amount }} general milestones are not set for any chapter"
}

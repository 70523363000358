import { useEffect, useState } from 'react'
import $ from 'jquery'
import JQuery from 'jquery'
import { getHeaders } from './AjaxUtil'
import { getCoordinatorId } from './stores/AuthenticationStore'
import { getLocalStorageValue } from './util'
import { AvailableLanguage, defaultLanguage } from './i18n/init'
import jqXHR = JQuery.jqXHR

export interface Endpoint {
  path: string
}

export const Endpoints = {
  login: (): Endpoint => ({ path: '/rest/auth' }),
  signUp: (): Endpoint => ({ path: '/rest/external-users' }),
  invitation: (token: string): Endpoint => ({ path: `/rest/invitations/${token}` }),
  invitationById: (id: number): Endpoint => ({ path: `/rest/coordinators/${getCoordinatorId()}/invitations/${id}` }),
  doctoralProgrammes: (): Endpoint => ({
    path: '/rest/sisu/doctoral-programmes'
  }),
  doctoralCandidates: (): Endpoint => ({
    path: `/rest/coordinators/doctoral-candidates`
  }),
  invitations: (): Endpoint => ({
    path: `/rest/coordinators/${getCoordinatorId()}/invitations`
  }),
  verifySupervisor: (): Endpoint => ({
    path: `/rest/coordinators/verify`
  }),
  endSupervisorRelationship: (doctoralCandidateId: number, supervisorRelationshipId: number): Endpoint => ({
    path: `/rest/coordinators/${getCoordinatorId()}/doctoral-candidates/${doctoralCandidateId}/supervisor-relationships/${supervisorRelationshipId}`
  }),
  supervisorRelationship: (supervisorRelationshipId: number): Endpoint => ({
    path: `/rest/coordinators/${getCoordinatorId()}/supervisor-relationships/${supervisorRelationshipId}`
  }),
  findUsersByEmail: (email: string) => ({
    path: `/rest/coordinators/${getCoordinatorId()}/users-by-email?email=${email}`
  }),
  updateSupervisorRoles: (supervisorRelationshipId: number): Endpoint => ({
    path: `/rest/supervisor-relationships/${supervisorRelationshipId}/roles`
  }),
  createConclusionFeedback: (doctoralCandidateId: number, reportId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}/conclusion-feedback`
  }),
  createSupervisorRelationship: (studentNumber: string): Endpoint => ({
    path: `/rest/coordinators/${getCoordinatorId()}/doctoral-candidates/${studentNumber}/supervisor-relationships`
  }),
  createSupervisorRelationships: (): Endpoint => ({
    path: `/rest/coordinators/supervisor-relationships`
  }),
  myDoctoralCandidates: (): Endpoint => ({
    path: '/rest/doctoral-candidates'
  }),
  doctoralCandidateOverview: (doctoralCandidateId: number | string): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}`
  }),
  internationalActivities: (doctoralCandidateId: number | string): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/international-activities`
  }),
  internationalActivity: (doctoralCandidateId: number, conferenceOrMobilityId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/international-activities/${conferenceOrMobilityId}`
  }),
  reports: (doctoralCandidateId: number | string): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/reports`
  }),
  report: (doctoralCandidateId: number | string, reportId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}`
  }),
  reportIsSubmitted: (doctoralCandidateId: number | string, reportId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}/is-submitted`
  }),
  reportCommentsAndEvents: (doctoralCandidateId: number | string, reportId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}/comments`
  }),
  reportComment: (doctoralCandidateId: number | string, reportId: number, commentId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/reports/${reportId}/comments/${commentId}`
  }),
  reportDeadline: (doctoralCandidateId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/deadline`
  }),
  roars: (): Endpoint => ({
    path: '/rest/roar/versions'
  }),
  reportingStatistics: (): Endpoint => ({
    path: `/rest/coordinators/${getCoordinatorId()}/statistics/reporting`
  }),
  thesisCommitteeMeetings: (doctoralCandidateId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/thesis-committee/meetings`
  }),
  updateFirstThesisCommitteeMeetingDate: (doctoralCandidateId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/thesis-committee/first-meeting`
  }),
  milestones: (doctoralCandidateId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/milestones`
  }),
  createMilestone: (doctoralCandidateId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/milestones`
  }),
  updateMilestone: (doctoralCandidateId: number, milestoneId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/milestones/${milestoneId}`
  }),
  deleteMilestone: (doctoralCandidateId: number, milestoneId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/milestones/${milestoneId}`
  }),
  commonMilestones: (doctoralCandidateId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/common-milestones`
  }),
  createCommonMilestone: (doctoralCandidateId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/common-milestones`
  }),
  updateCommonMilestone: (doctoralCandidateId: number, milestoneId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/common-milestones/${milestoneId}`
  }),
  deleteCommonMilestone: (doctoralCandidateId: number, milestoneId: number): Endpoint => ({
    path: `/rest/doctoral-candidates/${doctoralCandidateId}/common-milestones/${milestoneId}`
  })
}

export const CustomHeaders = {
  localization: (language?: AvailableLanguage) => ({
    'X-Language': (language || getLocalStorageValue('currentLanguage')) ?? defaultLanguage
  })
}

export function useGet<T>(endpoint: Endpoint, defaultValue?: T) {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState(defaultValue)
  const [error, setError] = useState<jqXHR>()

  const makeRequest = () => {
    setIsLoading(true)
    setIsError(false)
    setError(undefined)

    $.ajax({
      method: 'get',
      url: endpoint.path,
      headers: getHeaders()
    }).then(
      (response: T) => {
        setData(response)
        setIsLoading(false)
      },
      (error) => {
        setError(error)
        setIsError(true)
        setIsLoading(false)
        console.error(`Got error when requesting from path: ${endpoint.path}`, error)
      }
    )
  }

  const revalidate = () => {
    makeRequest()
  }

  useEffect(() => revalidate(), [])

  return { isLoading, data, isError, error, revalidate }
}

export function get<T>(endpoint: Endpoint): JQuery.jqXHR<T> {
  return $.ajax({
    method: 'get',
    url: endpoint.path,
    headers: getHeaders()
  })
}

export function post<T>(endpoint: Endpoint, data?: unknown): JQuery.jqXHR<T> {
  return $.ajax({
    method: 'post',
    url: endpoint.path,
    data: data !== undefined ? JSON.stringify(data) : undefined,
    headers: getHeaders({ 'Content-Type': 'application/json' })
  })
}

export function put<T>(endpoint: Endpoint, data?: unknown): JQuery.jqXHR<T> {
  return $.ajax({
    method: 'put',
    url: endpoint.path,
    data: data !== undefined ? JSON.stringify(data) : undefined,
    headers: getHeaders({ 'Content-Type': 'application/json' })
  })
}

export function del<T>(endpoint: Endpoint): JQuery.jqXHR<T> {
  return $.ajax({
    method: 'delete',
    url: endpoint.path,
    headers: getHeaders()
  })
}

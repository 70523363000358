import React from 'react'
import styled, { css } from 'styled-components'
import { DEFAULT_FONT } from './typography'
import {
  ADDITIONAL_YELLOW,
  GRAYSCALE_DARK,
  GRAYSCALE_MEDIUM_DARK,
  GRAYSCALE_WHITE,
  GRAYSCALE_DARK_TEXT,
  LINK_BLUE
} from './colors'
import { HINT_CONTAINER_HEIGHT } from './UniInput'
import { UniFieldsetWithLegend } from './UniFieldsetWithLegend'
import { HyIcon } from '../icon/HyIcon'
import { OptionalText, RequiredText } from './UniLabel'
import VisuallyHidden from '../VisuallyHidden'
import UniTag, { TagProps } from './UniTag'

interface UniCheckboxProps {
  id?: string
  label: string
  visuallyHideLabel?: boolean
  hideHelperText?: boolean
  options: UniCheckboxOption[]
}

interface UniCheckboxOption {
  label: string | React.ReactNode
  checked?: boolean
  onChange?: (checked: boolean) => void
  required?: boolean
  disabled?: boolean
  hideHelperText?: boolean
  tags?: TagProps[]
  gray?: boolean
  white?: boolean
  fullWidth?: boolean
}

export const UniCheckbox = ({ id, label, visuallyHideLabel, hideHelperText, options }: UniCheckboxProps) => (
  <CheckboxWrapper>
    <UniFieldsetWithLegend id={id} legend={visuallyHideLabel ? <VisuallyHidden>{label}</VisuallyHidden> : label} row>
      {options.map(({ label, checked, onChange, required, disabled }, key) => (
        <OptionWrapper className="checkbox" key={key}>
          <UniCheckboxOption
            label={label}
            checked={checked}
            onChange={onChange}
            required={required}
            disabled={disabled}
            hideHelperText={hideHelperText}
          />
        </OptionWrapper>
      ))}
    </UniFieldsetWithLegend>
    <Spacer />
  </CheckboxWrapper>
)

const CheckboxWrapper = styled.div``

const OptionWrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;

  .checkbox + & {
    margin: 0;
  }
`

export const UniCheckboxOption = ({
  label,
  checked,
  onChange,
  required,
  disabled,
  hideHelperText,
  tags,
  gray,
  white,
  fullWidth
}: UniCheckboxOption) => (
  <OptionContainer disabled={disabled} gray={gray} white={white} fullWidth={fullWidth}>
    <OptionInput
      type="checkbox"
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
      required={required}
      disabled={disabled}
    />
    <OptionIcon checked={checked} $disabled={disabled}>
      <Checkmark valid checked={checked} />
    </OptionIcon>
    <OptionContent>
      <OptionText bold={gray || white}>{disabled ? label : <Hover>{label}</Hover>}</OptionText>
      {tags && tags.length > 0 ? (
        <OptionTags>
          {tags.map((tag, key) =>
            tag.children ? (
              <OptionTag key={key} minimal={tag.minimal}>
                <UniTag {...tag} bold={false} />
              </OptionTag>
            ) : null
          )}
        </OptionTags>
      ) : null}
      {!hideHelperText && (required ? <RequiredText /> : null)}
      {!hideHelperText && (!required ? <OptionalText /> : null)}
    </OptionContent>
  </OptionContainer>
)

const OptionContainer = styled.label<{ disabled?: boolean; gray?: boolean; white?: boolean; fullWidth?: boolean }>`
  align-items: start;
  color: ${GRAYSCALE_DARK_TEXT};
  display: flex;
  font-size: 16px;
  font-weight: 400;
  gap: 6px;
  line-height: 24px;
  ${DEFAULT_FONT}

  .checkbox & {
    margin: 0;
    padding: 0;
    min-height: none;

    ${(props) =>
      props.disabled &&
      css`
        cursor: default;
      `}
  }
  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer !important;
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${GRAYSCALE_DARK};
      cursor: auto;
    `}

  ${(props) =>
    props.gray &&
    css`
      background-color: rgba(0, 0, 0, 0.04);
      padding: 12px 18px;
      margin: 0;
      gap: 24px;
    `}

  ${(props) =>
    props.white &&
    css`
      background-color: ${GRAYSCALE_WHITE};
      padding: 12px 18px;
      margin: 0;
      gap: 24px;
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`

const OptionInput = styled.input`
  appearance: none;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute;
  width: 1px;
  height: 1px;
`

export const OptionIcon = styled.div<{ checked: boolean; $disabled?: boolean }>`
  background-color: ${GRAYSCALE_WHITE};
  border: 2px solid ${LINK_BLUE};
  flex-shrink: 0;
  height: 20px;
  margin-top: 1px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
    `}

  ${OptionInput}:focus + & {
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }

  ${OptionInput}:disabled + & {
    border: 2px solid ${GRAYSCALE_MEDIUM_DARK};
    color: ${GRAYSCALE_MEDIUM_DARK};
  }
`

export const Checkmark = styled(HyIcon)<{ valid: boolean; checked: boolean }>`
  opacity: 0;
  transform: translate(0, 0) scale(1.1);
  transform-origin: 0 100%;
  filter: drop-shadow(0 -2px 0 ${GRAYSCALE_WHITE}) drop-shadow(2px 0 0 ${GRAYSCALE_WHITE});
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;

  ${(props) =>
    props.checked &&
    css`
      transform: translate(0px, 2px) scale(1.4);
      opacity: 1;
    `}
`

const OptionContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`

const OptionText = styled.div<{ bold?: boolean }>`
  vertical-align: middle;
  letter-spacing: -0.4px;

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `}
`

const Hover = styled.span`
  ${OptionContainer}:hover & {
    text-decoration: underline;
  }
`

const OptionTags = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`

const OptionTag = styled.div<{ minimal?: boolean }>`
  margin: 0;

  ${(props) =>
    props.minimal &&
    css`
      &:not(:last-child) {
        &:after {
          content: ',';
        }
      }
    `}
`

const Spacer = styled.div`
  min-height: calc(${HINT_CONTAINER_HEIGHT}px);
`

import React, { forwardRef, KeyboardEvent } from 'react'
import { UniLabel } from './UniLabel'
import styled, { css } from 'styled-components'
import { UniTextarea } from './UniTextarea'

export interface UniTextareaWithLabelProps {
  id: string
  label: string
  value: string
  onChange: (value: string) => void
  action?: React.ReactNode
  fullWidth?: boolean
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void
  rows?: number
  maxLength?: number
  autoFocus?: boolean
  placeholder?: string
  required?: boolean
  disabled?: boolean
  isValid?: boolean
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  hasInvalidHighlight?: boolean
  hasUpdatedHighlight?: boolean
  visuallyHideLabel?: boolean
  visuallyHideLabelHelperText?: boolean
  visuallyHideValidationHintAfter?: boolean
  visuallyHideValidationHintBefore?: boolean
  className?: string
}

export const UniTextareaWithLabel = forwardRef<HTMLTextAreaElement, UniTextareaWithLabelProps>(
  (
    {
      id,
      label,
      value,
      onChange,
      action,
      fullWidth,
      onKeyDown,
      autoFocus,
      placeholder,
      required,
      disabled,
      rows,
      maxLength,
      isValid,
      validationHintAfter,
      validationHintBefore,
      hasInvalidHighlight,
      hasUpdatedHighlight,
      visuallyHideLabel,
      visuallyHideLabelHelperText,
      visuallyHideValidationHintAfter,
      visuallyHideValidationHintBefore,
      className
    },
    ref
  ) => (
    <Container fullWidth={fullWidth} className="form-group" flex={visuallyHideLabel ? false : true}>
      <UniLabel
        required={required}
        htmlFor={id}
        visuallyHideText={visuallyHideLabel}
        visuallyHideHelperText={visuallyHideLabelHelperText}
      >
        {label}
      </UniLabel>
      <UniTextarea
        id={id}
        className={className}
        label={label}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        action={action}
        fullWidth={fullWidth}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        rows={rows}
        maxLength={maxLength}
        isValid={isValid}
        validationHintAfter={validationHintAfter}
        validationHintBefore={validationHintBefore}
        hasInvalidHighlight={hasInvalidHighlight}
        hasUpdatedHighlight={hasUpdatedHighlight}
        visuallyHideValidationHintAfter={visuallyHideValidationHintAfter}
        visuallyHideValidationHintBefore={visuallyHideValidationHintBefore}
        ref={ref}
      />
    </Container>
  )
)

UniTextareaWithLabel.displayName = 'UniTextareaWithLabel'

const Container = styled.div<{ fullWidth?: boolean; flex?: boolean }>`
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      flex-direction: column;
      gap: 12px;
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  &.form-group {
    margin: 0;
  }
`

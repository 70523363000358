import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { inject } from 'mobx-react'
import k from '../../i18n/keys'
import type { MyDoctoralCandidate } from './MyDoctoralCandidates'
import sortBy from 'lodash/sortBy'
import styled from 'styled-components'
import moment from 'moment'
import { WindowWidthContext } from '../../WindowWidthContext'
import { PageBodyTextButton } from '../common/page/PageBodyTextButton'
import PageBodyText from '../common/page/PageBodyText'
import Table from '../common/table/Table'
import CellDefault from '../common/table/CellDefault'
import Tooltip from '../common/Tooltip'
import { UniProgressBar } from '../common/uni/UniProgressBar'
import { Actions } from '../common/uni/UniForm'
import { UniParagraph } from '../common/uni/UniParagraph'
import { TFunction } from 'i18next'
import { Link } from 'react-router'
import VisuallyHidden from '../common/VisuallyHidden'
import UniPlaceholder from '../common/uni/UniPlaceholder'
import { IsNewOrChanged } from '../common/icon/IsNewOrChanged'
import HighlightStore from '../highlight/HighlightStore'
import { HIGHLIGHT } from '../highlight/constants'
import { StudentNumber } from './StudentNumber'

interface DoctoralCandidateListProperties {
  doctoralCandidates: MyDoctoralCandidate[]
  HighlightStore?: HighlightStore
}

export const DoctoralCandidateTable = inject('HighlightStore')(
  ({ doctoralCandidates, HighlightStore }: DoctoralCandidateListProperties) => {
    const { t } = useTranslation()
    const [showOnlyActiveStudyRights, setShowOnlyActiveStudyRights] = useState(true)
    const filteredDoctoralCandidates = doctoralCandidates.filter((doctoralCandidate) => {
      if (showOnlyActiveStudyRights) {
        return doctoralCandidate.hasActiveStudyRight
      }
      return true
    })
    const sortedDoctoralCandidates = sortBy(filteredDoctoralCandidates, 'name')
    const hasNoDoctoralCandidates = doctoralCandidates.length === 0
    const isNoActiveDoctoralCandidatesNotificationVisible =
      showOnlyActiveStudyRights && sortedDoctoralCandidates.length === 0 && doctoralCandidates.length > 0
    const hasMoreDoctoralCandidates = doctoralCandidates.length > sortedDoctoralCandidates.length
    const isToggleOnlyActiveDoctoralCandidatesButtonVisible = hasMoreDoctoralCandidates || !showOnlyActiveStudyRights

    const sessionStorageId = 'doctoral-candidate-list'
    const { isPhoneOrSmaller, isTabletOrSmaller } = useContext(WindowWidthContext)
    const columns = useMemo(
      () => [
        {
          Header: function NameHeader() {
            return <CellDefault>{t(k.NAME)}</CellDefault>
          },
          id: 'name',
          accessor: 'name',
          maxWidth: isTabletOrSmaller ? 70 : 90,
          Cell: ({ row }: any) => {
            const { original } = row
            const { name, roles, doctoralCandidateId } = original
            const formattedRoleNames = t(k.ROLE_NAME, { returnObjects: true }) as { [index: string]: string }
            const sortedRoles = roles.map((role: string) => formattedRoleNames[role]).sort()
            const hasChanged = HighlightStore.hasDoctoralCandidateHighlights(doctoralCandidateId)
              ? HIGHLIGHT.CHANGED
              : null
            const hasActiveStudyRight = original.hasActiveStudyRight
            return (
              <CellDefault>
                {hasChanged ? (
                  <span className="list-highlight-cell">
                    <IsNewOrChanged doctoralCandidateList newOrChanged={hasChanged} />
                  </span>
                ) : null}
                <div className="name-and-roles">
                  <UniParagraph semiBold noMargin muted={!doctoralCandidateId} className="name">
                    {doctoralCandidateId ? (
                      <Link to={`/doctoral-candidates/${doctoralCandidateId}`} className="name-link">
                        {name}
                      </Link>
                    ) : (
                      name
                    )}
                  </UniParagraph>
                  {!hasActiveStudyRight ? (
                    <UniParagraph semiBold noMargin small className="inactive-user">
                      {`(${t(k.INACTIVE_USER)})`}
                    </UniParagraph>
                  ) : null}
                  <UniParagraph muted cursive noMargin small className="roles">
                    {sortedRoles.join(', ')}
                  </UniParagraph>
                </div>
              </CellDefault>
            )
          }
        },
        {
          Header: function FastDoctoralCandidateCell() {
            return (
              <CellDefault
                alignCenter={!isPhoneOrSmaller}
                justifyEnd={!isPhoneOrSmaller}
                visuallyHideText={!isPhoneOrSmaller}
              >
                {t(k.DOCTORAL_EDUCATION_PILOT_PARTICIPANT)}
              </CellDefault>
            )
          },
          id: 'isFastDoctoralCandidate',
          accessor: 'isFastDoctoralCandidate',
          maxWidth: isTabletOrSmaller ? 0 : 5,
          Cell: ({ row }: any) => {
            const { original } = row
            const { isFastDoctoralCandidate } = original
            return (
              <CellDefault alignCenter={!isPhoneOrSmaller}>
                {isFastDoctoralCandidate && isPhoneOrSmaller ? (
                  <Actions noPadTop noWrap>
                    <FastIcon className="glyphicon glyphicon-flash" role="img" aria-hidden="true" />
                    <div>{t(k.YES)}</div>
                  </Actions>
                ) : null}
                {!isFastDoctoralCandidate && isPhoneOrSmaller ? t(k.NO) : null}
                {isFastDoctoralCandidate && !isPhoneOrSmaller ? (
                  <>
                    <VisuallyHidden>{t(k.YES)}</VisuallyHidden>
                    <Tooltip content={t(k.DOCTORAL_CANDIDATE_PARTICIPATES_IN_THE_DOCTORAL_EDUCATION_PILOT)}>
                      <FastIcon
                        className="glyphicon glyphicon-flash"
                        role="img"
                        aria-label={t(k.DOCTORAL_CANDIDATE_PARTICIPATES_IN_THE_DOCTORAL_EDUCATION_PILOT)}
                      />
                    </Tooltip>
                  </>
                ) : null}
                {!isFastDoctoralCandidate && !isPhoneOrSmaller ? <VisuallyHidden>{t(k.NO)}</VisuallyHidden> : null}
              </CellDefault>
            )
          }
        },
        {
          Header: function StudentNumberHeader() {
            return <CellDefault>{t(k.STUDENT_NUMBER)}</CellDefault>
          },
          id: 'studentNumber',
          accessor: 'studentNumber',
          maxWidth: isTabletOrSmaller ? 60 : 80,
          Cell: ({ row }: any) => {
            const { original } = row
            const { studentNumber } = original
            return (
              <CellDefault alignCenter={!isPhoneOrSmaller}>
                <StudentNumber studentNumber={studentNumber} />
              </CellDefault>
            )
          }
        },
        {
          Header: function ProgressHeader() {
            return <CellDefault>{t(k.PROGRESS)}</CellDefault>
          },
          id: 'progress',
          accessor: 'progress',
          maxWidth: isTabletOrSmaller ? 80 : 200,
          Cell: ({ row }: any) => {
            const { original } = row
            const { progress, doctoralCandidateId } = original

            const hasAccount = !!doctoralCandidateId

            if (!hasAccount) {
              return (
                <CellDefault alignCenter={!isPhoneOrSmaller}>
                  <UniParagraph muted noMargin small>
                    {t(k.NOT_IN_THESSA)}
                  </UniParagraph>
                </CellDefault>
              )
            }

            return (
              <CellDefault alignCenter={!isPhoneOrSmaller} key={`progress-${doctoralCandidateId}`}>
                {typeof progress === 'number' ? (
                  <Actions noPadTop noWrap fullWidth={!isTabletOrSmaller}>
                    {!isTabletOrSmaller ? <UniProgressBar valueNow={progress} small /> : null}
                    {progress}
                    {`%`}
                  </Actions>
                ) : (
                  <CellDefault>{progress}</CellDefault>
                )}
              </CellDefault>
            )
          }
        },
        {
          Header: function NextThesisCommitteeMeetingHeader() {
            return <CellDefault justifyEnd={!isPhoneOrSmaller}>{t(k.THESIS_COMMITTEE.NEXT_MEETING)}</CellDefault>
          },
          id: 'nextThesisCommitteeMeeting',
          accessor: 'nextThesisCommitteeMeeting',
          maxWidth: isTabletOrSmaller ? 40 : 90,
          Cell: ({ row }: any) => {
            const { original } = row
            const { nextThesisCommitteeMeeting, doctoralCandidateId } = original
            return (
              <CellDefault alignCenter={!isPhoneOrSmaller} justifyEnd={!isPhoneOrSmaller}>
                <UniParagraph
                  muted={!nextThesisCommitteeMeeting}
                  noMargin
                  small
                  className={`next-thesis-committee-meeting-date-${doctoralCandidateId}`}
                >
                  {nextThesisCommitteeMeeting
                    ? moment(nextThesisCommitteeMeeting).format('D.M.YYYY')
                    : t(k.THESIS_COMMITTEE.NOT_SCHEDULED)}
                </UniParagraph>
              </CellDefault>
            )
          }
        },
        {
          Header: function StatusHeader() {
            return <CellDefault justifyCenter={!isPhoneOrSmaller}>{t(k.STATUS)}</CellDefault>
          },
          id: 'status',
          accessor: 'status',
          maxWidth: 50,
          Cell: ({ row }: any) => {
            function getProblemTitle(
              hasReportStatusProblem: boolean,
              hasSupervisionPlanStatusProblem: boolean,
              t: TFunction
            ): string[] {
              const problemTitles = []
              if (hasReportStatusProblem) {
                problemTitles.push(t(k.CHECK_REPORT_STATUS))
              }
              if (hasSupervisionPlanStatusProblem) {
                problemTitles.push(t(k.CHECK_SUPERVISION_PLAN_STATUS))
              }
              return problemTitles
            }
            const { original } = row
            const { doctoralCandidateId } = original
            const hasAccount = !!doctoralCandidateId

            if (!hasAccount) {
              return (
                <CellDefault visuallyHideText={!isPhoneOrSmaller}>
                  <UniParagraph muted noMargin small>
                    {t(k.NOT_IN_THESSA)}
                  </UniParagraph>
                </CellDefault>
              )
            }

            const { latestReportStatus, supervisionPlanStatus } = original
            const hasReportStatusProblem = latestReportStatus === 'not ok'
            const hasSupervisionPlanStatusProblem = supervisionPlanStatus === 'not ok'
            const hasProblem = hasReportStatusProblem || hasSupervisionPlanStatusProblem
            const problemTitles = getProblemTitle(hasReportStatusProblem, hasSupervisionPlanStatusProblem, t)

            return (
              <CellDefault justifyCenter={!isPhoneOrSmaller} alignCenter={!isPhoneOrSmaller}>
                {isPhoneOrSmaller && hasProblem ? (
                  <Actions noPadTop noWrap>
                    <ProblemIcon className="problem-icon glyphicon glyphicon-alert" role="img" aria-hidden="true" />
                    <div>
                      {problemTitles.map((problemTitle, key) => (
                        <UniParagraph key={key} noMargin>
                          {problemTitle}
                        </UniParagraph>
                      ))}
                    </div>
                  </Actions>
                ) : null}

                {isPhoneOrSmaller && !hasProblem ? (
                  <Actions noPadTop noWrap>
                    <OkIcon className="glyphicon glyphicon-ok" role="img" aria-hidden="true" />
                    <div>{t(k.OK)}</div>
                  </Actions>
                ) : null}

                {!isPhoneOrSmaller && hasProblem ? (
                  <Tooltip
                    content={problemTitles.map((problemTitle) => (
                      <ProblemTitle key={problemTitle}>{problemTitle}</ProblemTitle>
                    ))}
                  >
                    <ProblemIcon
                      className="problem-icon glyphicon glyphicon-alert"
                      role="img"
                      aria-label={problemTitles.join(' ')}
                    />
                  </Tooltip>
                ) : null}

                {!isPhoneOrSmaller && !hasProblem ? (
                  <Tooltip content={t(k.OK)}>
                    <OkIcon className="glyphicon glyphicon-ok" />
                  </Tooltip>
                ) : null}
              </CellDefault>
            )
          }
        }
      ],
      [isPhoneOrSmaller, isTabletOrSmaller]
    )

    return (
      <>
        {hasNoDoctoralCandidates ? (
          <UniPlaceholder>
            <UniParagraph noMargin small>
              {t(k.YOU_HAVE_NO_DOCTORAL_CANDIDATE)}
            </UniParagraph>
          </UniPlaceholder>
        ) : null}

        {isNoActiveDoctoralCandidatesNotificationVisible ? (
          <UniPlaceholder>
            <UniParagraph noMargin small>
              {t(k.YOU_HAVE_NO_ACTIVE_DOCTORAL_CANDIDATES_IN_THESSA)}
            </UniParagraph>
          </UniPlaceholder>
        ) : null}

        {!hasNoDoctoralCandidates && !isNoActiveDoctoralCandidatesNotificationVisible ? (
          <Table
            className="report-list"
            id={sessionStorageId}
            columns={columns}
            data={sortedDoctoralCandidates}
            truncatedCells={false}
            defaultSortByID={'name'}
            cardTitleAccessor="name"
            sorting
            edgePadding
          />
        ) : null}

        {isToggleOnlyActiveDoctoralCandidatesButtonVisible && (
          <ToggleOnlyActiveStudyRightsContainer>
            <ToggleOnlyActiveStudyRightsButton onClick={() => setShowOnlyActiveStudyRights(!showOnlyActiveStudyRights)}>
              {showOnlyActiveStudyRights ? t(k.SHOW_ALL_DOCTORAL_CANDIDATES) : t(k.HIDE_INACTIVE_DOCTORAL_RESEARCHERS)}
            </ToggleOnlyActiveStudyRightsButton>
          </ToggleOnlyActiveStudyRightsContainer>
        )}
      </>
    )
  }
)

const ToggleOnlyActiveStudyRightsContainer = styled(PageBodyText)`
  display: flex;
  justify-content: center;
  padding: 12px 15px;
`

const ToggleOnlyActiveStudyRightsButton = styled(PageBodyTextButton)`
  &:active,
  &:focus {
    outline: none;
  }
`

const ProblemTitle = styled.div`
  text-align: center;

  & + & {
    margin-top: 3px;
  }
`

const ProblemIcon = styled.span`
  color: #e10000;
`

const OkIcon = styled.span`
  color: #008000;
`

const FastIcon = styled.span`
  color: #008000;
`

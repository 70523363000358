import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { UniButton } from '../common/uni/UniButton'
import { MilestoneForm } from './milestone/MilestoneForm'
import k from '../../i18n/keys'
import { Trans, useTranslation } from 'react-i18next'
import { UniPopover } from '../common/uni/UniPopover'
import { ChapterItemType } from './types'
import VisuallyHidden from '../common/VisuallyHidden'
import UniPlaceholder from '../common/uni/UniPlaceholder'

interface ChapterActionsProps {
  chapterId: number
  widget?: boolean
  timelineVisualScale: number
}

const ChapterActions = ({ chapterId, timelineVisualScale }: ChapterActionsProps) => {
  const { t } = useTranslation()
  const [isAdding, setIsAdding] = useState(false)
  const [itemType, setItemType] = useState<ChapterItemType | null>(null)
  const [options, setOptions] = useState<{ [key: string]: any } | null>(null)
  const [isAddingPopoverVisible, setIsAddingPopoverVisible] = useState(false)

  const handleAddClick = (type: ChapterItemType, options?: { [key: string]: any }) => {
    setIsAdding(true)
    setIsAddingPopoverVisible(false)
    setItemType(type)
    setOptions(options)
  }

  const handleAddPopoverVisible = () => {
    setIsAddingPopoverVisible(true)
  }

  return (
    <Wrapper timelineVisualScale={timelineVisualScale} isAdding={isAdding}>
      {isAdding && itemType === ChapterItemType.Milestone ? (
        <MilestoneForm chapterId={chapterId} closeAction={() => setIsAdding(false)} {...options} />
      ) : (
        <UniPlaceholder fullWidth>
          <UniPopover
            isVisible={isAddingPopoverVisible}
            bottom
            small
            items={[
              {
                label: t(k.ADD_PERSONAL_MILESTONE),
                onClick: () => handleAddClick(ChapterItemType.Milestone)
              },
              {
                label: t(k.ADD_COMMON_MILESTONE),
                onClick: () => handleAddClick(ChapterItemType.Milestone, { isCommon: true })
              }
            ]}
            onCancel={() => setIsAddingPopoverVisible(false)}
          >
            <UniButton minimal tertiary small icon="plus" onClick={handleAddPopoverVisible} className="chapter-actions">
              <Trans
                t={t}
                i18nKey={k.ADD_ITEM}
                values={{ id: chapterId }}
                components={[<VisuallyHidden />]} //eslint-disable-line react/jsx-key
              />
            </UniButton>
          </UniPopover>
        </UniPlaceholder>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ timelineVisualScale: number; isAdding?: boolean }>`
  display: flex;
  gap: ${({ timelineVisualScale }) => timelineVisualScale * 10}px;

  ${({ isAdding }) =>
    isAdding &&
    css`
      justify-content: stretch;
    `}
`

export default ChapterActions

import { del, Endpoints, get, post, put } from '../../../network'
import { PhdProjectActionType } from '../types'
import { usePhdProject, usePhdProjectDispatch } from './PhdProjectContextProvider'
import Report, { ReportResponse } from '../../../models/Report'
import { InternationalActivityResponse } from '../responseTypes'
import InternationalActivity from '../../../models/InternationalActivity'
import { ReportFieldsWithConclusion } from '../../report/concluded-report/ConclusionManager'
import { ReportCommentsAndEventsResponse } from '../../report/review-tool/types'
import { ReportFields, ReportStatus } from '../../report/types'
import { ThesisCommitteeMeeting } from '../../thesis-committee/types'
import { ChapterMilestone } from '../../chapters/types'

export function usePhdProjectActions() {
  const { doctoralCandidateId } = usePhdProject()
  const dispatch = usePhdProjectDispatch()
  return {
    createInternationalActivity: async (
      internationalActivity: InternationalActivity
    ): Promise<InternationalActivity> => {
      const createdInternationalActivityResponse = await post<InternationalActivityResponse[number]>(
        Endpoints.internationalActivities(doctoralCandidateId),
        internationalActivity
      )
      const createdInternationalActivity = new InternationalActivity(createdInternationalActivityResponse)
      dispatch({
        type: PhdProjectActionType.CreateInternationalActivity,
        internationalActivity: createdInternationalActivity
      })
      return createdInternationalActivity
    },

    editInternationalActivity: async (internationalActivity: InternationalActivity): Promise<InternationalActivity> => {
      const editedInternationalActivityResponse = await put<InternationalActivityResponse[number]>(
        Endpoints.internationalActivity(doctoralCandidateId, internationalActivity.id),
        internationalActivity
      )
      const editedInternationalActivity = new InternationalActivity(editedInternationalActivityResponse)
      dispatch({
        type: PhdProjectActionType.EditInternationalActivity,
        internationalActivity: editedInternationalActivity
      })
      return editedInternationalActivity
    },

    deleteInternationalActivity: async (internationalActivityId: number): Promise<void> => {
      await del(Endpoints.internationalActivity(doctoralCandidateId, internationalActivityId))
      dispatch({
        type: PhdProjectActionType.DeleteInternationalActivity,
        internationalActivityId
      })
    },

    loadReports: async (): Promise<void> => {
      const reportsResponse = await get<ReportResponse[]>(Endpoints.reports(doctoralCandidateId))
      const reports = reportsResponse.map((reportResponse) => new Report(reportResponse))
      dispatch({ type: PhdProjectActionType.UpdateReports, reports: reports })
    },

    createReport: async (): Promise<Report> => {
      const emptyReportResponse = await post<ReportResponse>(Endpoints.reports(doctoralCandidateId))
      const emptyReport = new Report(emptyReportResponse)
      dispatch({ type: PhdProjectActionType.CreateReport, report: emptyReport })
      return emptyReport
    },

    submitReport: async (reportId: number, report: ReportFields): Promise<Report> => {
      await put<ReportResponse>(Endpoints.report(doctoralCandidateId, reportId), report)
      const reportResponse = await put<ReportResponse>(Endpoints.reportIsSubmitted(doctoralCandidateId, reportId))
      const submittedReport = new Report(reportResponse)
      dispatch({ type: PhdProjectActionType.EditReport, report: submittedReport })
      return submittedReport
    },

    editReport: async (
      reportId: number,
      report: ReportFields | ReportFieldsWithConclusion,
      loadReportCommentsAndEvents: (reportId: number) => void
    ): Promise<Report> => {
      const reportResponse = await put<ReportResponse>(Endpoints.report(doctoralCandidateId, reportId), report)
      const editedReport = new Report(reportResponse)
      dispatch({ type: PhdProjectActionType.EditReport, report: editedReport })

      if (editedReport.status === ReportStatus.Submitted) {
        loadReportCommentsAndEvents(reportId)
      }

      return editedReport
    },

    deleteReport: async (reportId: number): Promise<void> => {
      await del(Endpoints.report(doctoralCandidateId, reportId))
      dispatch({ type: PhdProjectActionType.DeleteReport, reportId })
    },

    loadReportCommentsAndEvents: async (reportId: number) => {
      const reportCommentsAndEvents = await get<ReportCommentsAndEventsResponse>(
        Endpoints.reportCommentsAndEvents(doctoralCandidateId, reportId)
      )
      dispatch({ type: PhdProjectActionType.UpdateReportCommentsAndEvents, reportCommentsAndEvents })
    },

    createReportComment: async (reportId: number, comment: string) => {
      const reportCommentsAndEvents = await post<ReportCommentsAndEventsResponse>(
        Endpoints.reportCommentsAndEvents(doctoralCandidateId, reportId),
        {
          comment: comment
        }
      )
      dispatch({ type: PhdProjectActionType.UpdateReportCommentsAndEvents, reportCommentsAndEvents })
    },

    deleteReportComment: async (reportId: number, commentId: number) => {
      const reportCommentsAndEvents = await del<ReportCommentsAndEventsResponse>(
        Endpoints.reportComment(doctoralCandidateId, reportId, commentId)
      )
      dispatch({ type: PhdProjectActionType.UpdateReportCommentsAndEvents, reportCommentsAndEvents })
    },

    updateFirstThesisCommitteeMeetingDate: async (date: string) => {
      const thesisCommitteeMeetings = await post<ThesisCommitteeMeeting[]>(
        Endpoints.updateFirstThesisCommitteeMeetingDate(doctoralCandidateId),
        { date }
      )
      dispatch({ type: PhdProjectActionType.UpdateThesisCommitteeMeetings, thesisCommitteeMeetings })
    },

    createMilestone: async (milestone: string, isCompleted?: boolean, chapterId?: number) => {
      const milestones = await post<ChapterMilestone[]>(Endpoints.createMilestone(doctoralCandidateId), {
        milestone,
        isCompleted: isCompleted ?? null,
        chapterId: chapterId ?? null
      })
      dispatch({ type: PhdProjectActionType.UpdateMilestones, milestones })
    },

    updateMilestone: async (milestoneId: number, milestone?: string, isCompleted?: boolean) => {
      const milestones = await put<ChapterMilestone[]>(Endpoints.updateMilestone(doctoralCandidateId, milestoneId), {
        milestone,
        isCompleted: isCompleted ?? null
      })
      dispatch({ type: PhdProjectActionType.UpdateMilestones, milestones })
    },

    deleteMilestone: async (milestoneId: number) => {
      const milestones = await del<ChapterMilestone[]>(Endpoints.deleteMilestone(doctoralCandidateId, milestoneId))
      dispatch({ type: PhdProjectActionType.UpdateMilestones, milestones })
    },

    updateCommonMilestone: async (
      milestoneId: number,
      isCompleted?: boolean,
      chapterId?: number,
      chapterAssignedAt?: string
    ) => {
      const commonMilestones = await put<ChapterMilestone[]>(
        Endpoints.updateCommonMilestone(doctoralCandidateId, milestoneId),
        {
          isCompleted: isCompleted ?? null,
          chapterId: chapterId ?? null,
          chapterAssignedAt: chapterAssignedAt ?? null
        }
      )
      dispatch({ type: PhdProjectActionType.UpdateCommonMilestones, commonMilestones })
    }
  }
}

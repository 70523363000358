import React, { forwardRef, KeyboardEvent } from 'react'
import { UniLabel } from './UniLabel'
import styled, { css } from 'styled-components'
import { Option, OptionGroup, UniSelect } from './UniSelect'

export interface UniSelectWithLabelProps {
  id: string
  label: string | JSX.Element
  value: string
  onChange: (value: string) => void
  required?: boolean
  autoFocus?: boolean
  disabled?: boolean
  isValid?: boolean
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  validationHintInline?: string | JSX.Element
  hasInvalidHighlight?: boolean
  placeholder?: string
  small?: boolean
  fullWidth?: boolean
  flex?: boolean
  visuallyHideLabel?: boolean
  visuallyHideLabelHelperText?: boolean
  visuallyHideValidationHintAfter?: boolean
  visuallyHideValidationHintBefore?: boolean
  hideHelperText?: boolean
  className?: string
  onKeyDown?: (event: KeyboardEvent<HTMLSelectElement>) => void
  options?: (Option | OptionGroup)[]
}

export const UniSelectWithLabel = forwardRef<HTMLSelectElement, UniSelectWithLabelProps>(
  (
    {
      id,
      label,
      value,
      onChange,
      required,
      autoFocus,
      disabled,
      isValid,
      validationHintAfter,
      validationHintBefore,
      validationHintInline,
      hasInvalidHighlight,
      placeholder,
      small,
      fullWidth,
      flex,
      visuallyHideLabel,
      visuallyHideLabelHelperText,
      visuallyHideValidationHintAfter,
      visuallyHideValidationHintBefore,
      hideHelperText,
      className,
      onKeyDown,
      options
    },
    ref
  ) => (
    <Container fullWidth={fullWidth} flex={visuallyHideLabel ? false : flex} small={small}>
      <UniLabel
        required={required}
        htmlFor={id}
        visuallyHideText={visuallyHideLabel}
        visuallyHideHelperText={visuallyHideLabelHelperText}
        hideHelperText={hideHelperText}
        small={small}
      >
        {label}
      </UniLabel>
      <UniSelect
        id={id}
        className={className}
        ref={ref}
        label={label}
        required={required}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        autoFocus={autoFocus}
        disabled={disabled}
        isValid={isValid}
        validationHintAfter={validationHintAfter}
        validationHintBefore={validationHintBefore}
        validationHintInline={validationHintInline}
        hasInvalidHighlight={hasInvalidHighlight}
        visuallyHideValidationHintAfter={visuallyHideValidationHintAfter}
        visuallyHideValidationHintBefore={visuallyHideValidationHintBefore}
        placeholder={placeholder}
        small={small}
        fullWidth={fullWidth}
        onKeyDown={onKeyDown}
        options={options}
      />
    </Container>
  )
)

UniSelectWithLabel.displayName = 'UniSelectWithLabel'

const Container = styled.div<{ fullWidth?: boolean; flex?: boolean; small?: boolean }>`
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex: 1 1 auto;
    `}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}


  ${(props) =>
    props.small &&
    css`
      gap: 8px;
    `}
`

import React, { useEffect, useState } from 'react'
import LoadingIcon from '../icon/LoadingIcon'

interface LoadingIndicatorProps {
  isLoading?: boolean
  delay?: number
}

const LoadingIndicator = ({ isLoading, delay = 0 }: LoadingIndicatorProps) => {
  const [showIndicator, setShowIndicator] = useState(false)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (isLoading) {
      timer = setTimeout(() => {
        setShowIndicator(true)
      }, delay)
    } else {
      setShowIndicator(false)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isLoading, delay])

  if (!showIndicator) {
    return null
  }

  return <LoadingIcon animate={true} />
}

export default LoadingIndicator

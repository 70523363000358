import React from 'react'
import type { Cell } from '../../../model'
import styled, { css } from 'styled-components'
import VisuallyHidden from '../VisuallyHidden'

type CellDefaultProperties<T extends object> = Partial<Cell<T>> & {
  justifyEnd?: boolean
  justifyCenter?: boolean
  alignStart?: boolean
  alignCenter?: boolean
  alignEnd?: boolean
  gap?: boolean
  visuallyHideText?: boolean
  notification?: 'new' | 'changed'
  className?: string
  children?: React.ReactNode
}

const CellDefault = <T extends object>({
  justifyEnd,
  justifyCenter,
  alignStart,
  alignCenter,
  alignEnd,
  gap,
  visuallyHideText,
  value,
  className,
  children
}: CellDefaultProperties<T>) => {
  return (
    <Align
      className={className}
      flex={justifyEnd || justifyCenter || alignStart || alignCenter || alignEnd || gap}
      justifyEnd={justifyEnd}
      justifyCenter={justifyCenter}
      alignStart={alignStart}
      alignCenter={alignCenter}
      alignEnd={alignEnd}
      gap={gap}
    >
      {visuallyHideText ? <VisuallyHidden>{value ?? children}</VisuallyHidden> : value ?? children}
    </Align>
  )
}

const Align = styled.span<{
  flex?: boolean
  justifyEnd?: boolean
  justifyCenter?: boolean
  alignStart?: boolean
  alignCenter?: boolean
  alignEnd?: boolean
  gap?: boolean
}>`
  height: 100%;

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
    `}

  ${({ justifyEnd }) =>
    justifyEnd &&
    css`
      width: 100%;
      justify-content: flex-end;
      text-align: end;
    `}

  ${({ justifyCenter }) =>
    justifyCenter &&
    css`
      width: 100%;
      justify-content: center;
    `}

  ${({ alignStart }) =>
    alignStart &&
    css`
      align-items: flex-start;
    `}

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      align-items: center;
    `}

  ${({ alignEnd }) =>
    alignEnd &&
    css`
      align-items: flex-end;
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: 16px;
    `}
`

export default CellDefault

import { css } from 'styled-components'
import { fromPhoneUp, fromTabletUp, fromDesktopUp } from '../../styledUtils'
import { GRAYSCALE_WHITE, GRAYSCALE_MEDIUM } from '../uni/colors'

export const DropdownStyles = (
  alignment?: 'top' | 'bottomRight' | 'bottomLeft' | 'right' | 'bottom' | 'left' | 'static',
  small?: boolean
) => css`
  background-color: ${GRAYSCALE_WHITE};
  position: absolute;
  padding: 0;
  border: 1px solid ${GRAYSCALE_MEDIUM};
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 20%);
  padding: ${small ? '6px 4px' : '12px 8px'};
  top: calc(100% + 6px);

  ${fromPhoneUp(css`
    top: calc(100%);
  `)}

  ${fromTabletUp(css`
    top: calc(100% + 12px);
  `)}

  ${alignment === 'static' &&
  css`
    position: relative;
    top: 0;
  `}

  ${alignment === 'bottomLeft' &&
  css`
    left: 12px;
    right: 12px;

    ${fromPhoneUp(css`
      left: 12px;
      right: auto;
    `)}

    ${fromTabletUp(css`
      left: -18px;
      right: auto;
    `)}

    ${fromDesktopUp(css`
      left: -36px;
      right: auto;
    `)}
  `}

  ${alignment === 'bottomRight' &&
  css`
    left: 12px;
    right: 12px;

    ${fromPhoneUp(css`
      left: auto;
      right: 12px;
    `)}

    ${fromTabletUp(css`
      left: auto;
      right: -18px;
    `)}

    ${fromDesktopUp(css`
      left: auto;
      right: -36px;
    `)}
  `}

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    background-color: ${GRAYSCALE_WHITE};

    ${alignment === 'static' &&
    css`
      display: none;
    `}

    ${alignment === 'top' &&
    css`
      top: auto;
      left: 50%;
      bottom: 0px;
      border-top: none;
      border-bottom: 1px solid ${GRAYSCALE_MEDIUM};
      border-left: 1px solid ${GRAYSCALE_MEDIUM};
      transform: translateY(7px) translateX(-50%) rotate(-45deg);
    `}

    ${alignment === 'bottom' &&
    css`
      top: 0;
      left: 50%;
      border-top: 1px solid ${GRAYSCALE_MEDIUM};
      border-left: 1px solid ${GRAYSCALE_MEDIUM};
      transform: translateY(-7px) translateX(-50%) rotate(45deg);
    `}

    ${alignment === 'bottomLeft' &&
    css`
      top: 0;
      left: 32px;
      right: auto;
      border-top: 1px solid ${GRAYSCALE_MEDIUM};
      border-left: 1px solid ${GRAYSCALE_MEDIUM};
      transform: translateY(-7px) translateX(-50%) rotate(45deg);

      ${fromPhoneUp(css`
        left: 48px;
        right: auto;
      `)}

      ${fromTabletUp(css`
        left: 32px;
        right: auto;
      `)}
    `}

    ${alignment === 'bottomRight' &&
    css`
      top: 0;
      left: auto;
      right: 32px;
      border-top: 1px solid ${GRAYSCALE_MEDIUM};
      border-left: 1px solid ${GRAYSCALE_MEDIUM};
      transform: translateY(-7px) translateX(-50%) rotate(45deg);

      ${fromPhoneUp(css`
        left: auto;
        right: 48px;
      `)}

      ${fromTabletUp(css`
        left: auto;
        right: 32px;
      `)}
    `}

    ${alignment === 'left' &&
    css`
      top: 50%;
      left: auto;
      right: 0px;
      border-bottom: 1px solid ${GRAYSCALE_MEDIUM};
      border-right: 1px solid ${GRAYSCALE_MEDIUM};
      transform: translateY(-50%) translateX(7px) rotate(-45deg);
    `}

    ${alignment === 'right' &&
    css`
      top: 50%;
      left: 0px;
      border-top: 1px solid ${GRAYSCALE_MEDIUM};
      border-left: 1px solid ${GRAYSCALE_MEDIUM};
      transform: translateY(-50%) translateX(-7px) rotate(-45deg);
    `}
  }
`

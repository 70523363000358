import { Role } from '../../../stores/role'
import { Dispatch } from 'react'
import { Field, PhdProjectAction, PhdProjectActionType } from '../types'
import { Endpoints, get } from '../../../network'
import { InternationalActivityResponse, ReportDeadlineResponse } from '../responseTypes'
import Report, { ReportResponse } from '../../../models/Report'
import InternationalActivity from '../../../models/InternationalActivity'
import k from '../../../i18n/keys'
import { TFunction } from 'i18next'
import { ThesisCommitteeMeeting } from '../../thesis-committee/types'
import { ChapterMilestone } from '../../chapters/types'
import { DoctoralCandidateResponse } from '../../../models/DoctoralCandidate'

export async function initializePhdProject(
  doctoralCandidateId: number,
  isFastDoctoralCandidate: boolean,
  role: Role,
  dispatch: Dispatch<PhdProjectAction>,
  t: TFunction
) {
  try {
    const [
      internationalActivitiesResponse,
      reportsResponse,
      reportDeadline,
      thesisCommitteeMeetings,
      [beginningOfStudies, expectedGraduationDate],
      milestones,
      commonMilestones
    ] = await Promise.all([
      get<InternationalActivityResponse>(Endpoints.internationalActivities(doctoralCandidateId)),
      get<ReportResponse[]>(Endpoints.reports(doctoralCandidateId)),
      get<ReportDeadlineResponse>(Endpoints.reportDeadline(doctoralCandidateId)),
      isFastDoctoralCandidate
        ? get<ThesisCommitteeMeeting[]>(Endpoints.thesisCommitteeMeetings(doctoralCandidateId))
        : [],
      get<DoctoralCandidateResponse>(Endpoints.doctoralCandidateOverview(doctoralCandidateId)).then((response) => [
        response.beginningOfStudies,
        response.expectedGraduationDate
      ]),
      isFastDoctoralCandidate ? get<ChapterMilestone[]>(Endpoints.milestones(doctoralCandidateId)) : [],
      isFastDoctoralCandidate ? get<ChapterMilestone[]>(Endpoints.commonMilestones(doctoralCandidateId)) : []
    ])

    const internationalActivities = internationalActivitiesResponse.map(
      (internationalActivityResponse) => new InternationalActivity(internationalActivityResponse)
    )

    const reports = reportsResponse.map((reportResponse) => new Report(reportResponse))

    dispatch({
      type: PhdProjectActionType.Initialize,
      doctoralCandidateId,
      isFastDoctoralCandidate,
      role,
      [Field.InternationalActivities]: internationalActivities,
      [Field.Reports]: reports,
      [Field.ReportDeadline]: reportDeadline,
      [Field.ThesisCommitteeMeetings]: thesisCommitteeMeetings,
      [Field.BeginningOfStudies]: beginningOfStudies,
      [Field.ExpectedGraduationDate]: expectedGraduationDate,
      [Field.Milestones]: milestones,
      [Field.CommonMilestones]: commonMilestones
    })
  } catch (error) {
    dispatch({ type: PhdProjectActionType.Error, errorTranslationKey: k.PHD_PROJECT.ERROR.CANNOT_INITIALIZE })
    console.error(error)
  }
}

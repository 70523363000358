import React from 'react'
import styled, { css } from 'styled-components'
import { GRAYSCALE_WHITE, GRAYSCALE_DARK, ADDITIONAL_GREEN_DARK } from './colors'
import { fromTabletUp } from '../../styledUtils'
import VisuallyHidden from '../VisuallyHidden'
import { UniParagraph, UniParagraphStyles } from './UniParagraph'

interface Properties {
  id?: string
  valueNow: number
  valueMax?: number
  label?: string | React.ReactNode
  labelInside?: boolean
  small?: boolean
  className?: string
}

export const UniProgressBar = ({
  id,
  valueNow,
  valueMax = 100,
  label,
  labelInside = false,
  className,
  small = false
}: Properties) => {
  const percentage = (valueNow / valueMax) * 100
  return (
    <Container>
      <VisuallyHidden id={id} hidden={true}>
        {label}
      </VisuallyHidden>
      <Progress
        className={className}
        role="progressbar"
        aria-valuenow={valueNow}
        aria-valuemax={valueMax}
        aria-labelledby={id}
      >
        <ProgressBar
          aria-hidden="true"
          small={small}
          className={`${className}-bar ${className}-bar-success`}
          style={{ width: `${percentage}%` }}
        >
          {labelInside ? <span className={`${className}-label`}>{label}</span> : null}
        </ProgressBar>
      </Progress>
      {!labelInside ? (
        <Label noMargin className={`${className}-label`}>
          {label}
        </Label>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 12px;

  ${fromTabletUp(css`
    gap: 24px;
  `)}
`

const Progress = styled.div`
  flex: 1;
  height: auto;
  box-shadow: none;
  background-color: ${GRAYSCALE_WHITE};
  border-radius: 0;
  border: 1px solid ${GRAYSCALE_DARK};

  &.progress {
    margin: 0;
  }
`

const ProgressBar = styled.div<{ small?: boolean }>`
  ${UniParagraphStyles}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background-color: ${ADDITIONAL_GREEN_DARK};
  color: ${GRAYSCALE_WHITE};
  font-weight: 600;
  box-shadow: inset 0 0 0px 2px ${GRAYSCALE_WHITE};

  ${({ small }) =>
    small &&
    css`
      height: 24px;
    `}
`

const Label = styled(UniParagraph)`
  &.progress-bar {
    float: none;
    width: auto;
    height: auto;
    background-color: transparent;
    box-shadow: none;
  }
`

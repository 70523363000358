import React from 'react'
import styled from 'styled-components'
import { ChapterItemType } from './types'
import { ChapterProps } from './Chapter'
import { Milestone } from './milestone/Milestone'
import Meeting from './meeting/Meeting'
import { GRAYSCALE_BLACK, GRAYSCALE_WHITE } from '../common/uni/colors'
import { UniList, UniListItem } from '../common/uni/UniList'
import { getIsNextStartEventCompleted, getProgressDotHeight, useChapterProgress } from './util'
import StudyRight from './study-right/StudyRight'
import ChapterActions from './ChapterActions'
import { Role } from '../../stores/role'

export interface ChapterProgressProps {
  timelineVisualScale: number
  chapter: ChapterProps['chapter']
  chapters: ChapterProps['chapters']
  widget?: boolean
  teaser?: boolean
  viewerRole: Role
  className?: string
}

const ChapterProgress = ({
  timelineVisualScale,
  chapter,
  chapters,
  widget,
  teaser,
  viewerRole,
  className
}: ChapterProgressProps) => {
  const { containerRef, itemReferences, barHeight, topOffset, completedHeight } = useChapterProgress(
    chapter,
    chapters,
    timelineVisualScale
  )
  const isNextStartEventCompleted = getIsNextStartEventCompleted(chapters, chapter)
  const { items } = chapter || {}
  const filteredItems = teaser ? items?.filter((item) => item.type === ChapterItemType.Meeting) : items
  const isItemReadOnly = viewerRole !== Role.DOCTORAL_CANDIDATE
  const showItemActions = !widget && viewerRole === Role.DOCTORAL_CANDIDATE
  const showChapterActionsWrapper = !teaser
  const showChapterActions = !widget && viewerRole === Role.DOCTORAL_CANDIDATE

  return (
    <ProgressBarContainer ref={containerRef} className={className} timelineVisualScale={timelineVisualScale}>
      <ProgressBarLine top={topOffset} height={barHeight} timelineVisualScale={timelineVisualScale} />

      <CompletedBarLine
        timelineVisualScale={timelineVisualScale}
        top={topOffset}
        height={completedHeight}
        isVisible={completedHeight > 0}
      />

      {!isNextStartEventCompleted ? (
        <Dot
          timelineVisualScale={timelineVisualScale}
          top={topOffset + completedHeight - timelineVisualScale * 2}
          hollow={false}
          isVisible={completedHeight > 0}
        />
      ) : null}

      <List noMargin timelineVisualScale={timelineVisualScale}>
        {filteredItems?.map((item, index) => (
          <Item
            key={`${item.id}-${index}`}
            noBullet
            noMargin
            timelineVisualScale={timelineVisualScale}
            ref={(element) => (itemReferences.current[index] = element)}
          >
            {item.type === ChapterItemType.StudyRight ? (
              <StudyRight
                timelineVisualScale={timelineVisualScale}
                content={item}
                chapter={chapter}
                chapters={chapters}
                isNextStartEventCompleted={isNextStartEventCompleted}
              />
            ) : null}
            {item.type === ChapterItemType.Meeting ? (
              <Meeting
                timelineVisualScale={timelineVisualScale}
                content={item}
                chapter={chapter}
                chapters={chapters}
                isNextStartEventCompleted={isNextStartEventCompleted}
              />
            ) : null}
            {item.type === ChapterItemType.Milestone ? (
              <Milestone
                timelineVisualScale={timelineVisualScale}
                content={item}
                chapter={chapter}
                gray={widget}
                white={!widget}
                isReadOnly={isItemReadOnly}
                showActions={showItemActions}
                minimal={widget}
              />
            ) : null}
          </Item>
        ))}
      </List>
      {showChapterActionsWrapper ? (
        <ActionsWrapper timelineVisualScale={timelineVisualScale}>
          {showChapterActions ? (
            <ChapterActions chapterId={chapter.id} timelineVisualScale={timelineVisualScale} />
          ) : null}
        </ActionsWrapper>
      ) : null}
    </ProgressBarContainer>
  )
}

const ProgressBarContainer = styled.div<{ timelineVisualScale: number }>`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  max-width: 640px;
  width: 100%;
`

const List = styled(UniList)<{ timelineVisualScale: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ timelineVisualScale }) => timelineVisualScale * 6}px;
  margin-bottom: ${({ timelineVisualScale }) => timelineVisualScale * 6}px;
  width: 100%;
`

const Item = styled(UniListItem)<{ timelineVisualScale: number }>``

const ProgressBarLine = styled.div<{
  timelineVisualScale: number
  top: number
  height: number
}>`
  position: absolute;
  left: ${({ timelineVisualScale }) => timelineVisualScale * 7 - timelineVisualScale / 2}px;
  top: ${(props) => props.top}px;
  width: 2px;
  height: ${(props) => props.height}px;
  border-left: ${({ timelineVisualScale }) => timelineVisualScale}px dotted ${GRAYSCALE_BLACK};
`

const CompletedBarLine = styled.div<{
  timelineVisualScale: number
  top: number
  height: number
  isVisible: boolean
}>`
  position: absolute;
  left: ${({ timelineVisualScale }) => timelineVisualScale * 7 - timelineVisualScale / 2}px;
  top: ${(props) => props.top}px;
  width: 2px;
  height: ${(props) => props.height}px;
  border-left: ${({ timelineVisualScale }) => timelineVisualScale}px solid ${GRAYSCALE_BLACK};
  transition: top 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`

const Dot = styled.div<{
  timelineVisualScale: number
  top: number
  hollow?: boolean
  isVisible?: boolean
}>`
  position: absolute;
  left: ${({ timelineVisualScale }) => timelineVisualScale * 7 - timelineVisualScale * 2}px;
  top: ${(props) => props.top}px;
  width: ${({ timelineVisualScale }) => getProgressDotHeight(timelineVisualScale)}px;
  height: ${({ timelineVisualScale }) => getProgressDotHeight(timelineVisualScale)}px;
  background-color: ${({ hollow }) => (hollow ? GRAYSCALE_WHITE : GRAYSCALE_BLACK)};
  border: ${({ hollow, timelineVisualScale }) =>
    hollow ? `${timelineVisualScale}px solid ${GRAYSCALE_BLACK}` : 'none'};
  border-radius: 50%;
  transition: top 0.3s ease, opacity 0.3s ease;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`

const ActionsWrapper = styled.div<{
  timelineVisualScale: number
}>`
  margin-left: ${({ timelineVisualScale }) => timelineVisualScale * 20}px;
  margin-bottom: ${({ timelineVisualScale }) => timelineVisualScale * 12}px;
`

export default ChapterProgress

import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { GRAYSCALE_BLACK } from '../uni/colors'

interface LoadingIconProperties {
  animate?: boolean
  className?: string
}

const LoadingIcon = ({ animate, className }: LoadingIconProperties) => {
  return (
    <Wrapper className={className} aria-hidden="true">
      <Icon xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" animate={animate}>
        <path d="M480-46q-90 0-168.97-34.08-78.97-34.07-137.92-93.03-58.96-58.95-93.03-137.92Q46-390 46-480q0-90.14 34.06-168.88 34.07-78.74 93-137.93Q232-846 311-880t169-34q26 0 44.5 18.5T543-851q0 26-18.5 44.5T480-788q-128.01 0-218.01 89.99-89.99 89.99-89.99 218T261.99-262q89.99 90 218 90T698-261.99q90-90 90-218.01 0-26 18.5-44.5T851-543q26 0 44.5 18.5T914-480q0 90-34.06 169.01-34.07 79.01-93 138Q728-114 649.14-80 570.28-46 480-46Z" />
      </Icon>
    </Wrapper>
  )
}

const loading = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Icon = styled.svg<{ animate?: boolean }>`
  fill: ${GRAYSCALE_BLACK};
  animation: ${({ animate }) =>
    animate
      ? css`
          ${loading} 1s linear infinite
        `
      : 'none'};
`

export default LoadingIcon

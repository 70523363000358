import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import k from '../../../i18n/keys'
import React from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react'
import PageSubHeading from '../../common/page/PageSubHeading'
import { ReportList } from './ReportList'
import { WithRole } from '../../phd-project/WithRole'
import { Deadline } from './Deadline'
import CreateReportButton from '../../report/CreateReportButton'
import { maxPhoneQuery } from '../../styledUtils'
import DoctoralCandidate from '../../../models/DoctoralCandidate'
import { usePhdProject } from '../phd-project-context/PhdProjectContextProvider'
import { ReportStatus } from '../../report/types'
import { UniButton } from '../../common/uni/UniButton'
import { Actions } from '../../common/uni/UniForm'
import UniPlaceholder from '../../common/uni/UniPlaceholder'
import { UniParagraph } from '../../common/uni/UniParagraph'

interface ReportOverviewProps {
  baseUrl: string
  doctoralCandidate: DoctoralCandidate
}

export const ReportOverview = observer(({ baseUrl, doctoralCandidate }: ReportOverviewProps) => {
  const { t } = useTranslation()
  const { reports } = usePhdProject()
  const isReportListVisible = reports.length > 0
  const isNoReportsNotificationVisible = reports.length === 0
  const nonDraftReports = reports.filter((report) => report.status !== ReportStatus.Draft)
  const isCumulativeReportLinkVisible = nonDraftReports.length > 0
  return (
    <>
      <PageSubHeading>{t(k.REPORTS)}</PageSubHeading>
      {isReportListVisible && <ReportList reports={reports} doctoralCandidate={doctoralCandidate} />}
      {isNoReportsNotificationVisible && (
        <UniPlaceholder className="report-overview__no-reports">
          <UniParagraph noMargin small>
            {t(k.NO_REPORTS_YET)}
          </UniParagraph>
        </UniPlaceholder>
      )}

      <Actions firstToStart noPadTop>
        <Actions noPadTop>
          <WithRole doctoralCandidate>
            <CreateReportButtonContainer>
              <CreateReportButton />
            </CreateReportButtonContainer>
          </WithRole>

          <Deadline />
        </Actions>

        {isCumulativeReportLinkVisible && (
          <ReportActionsRight>
            <UniButton secondary as={Link} to={`${baseUrl}cumulative-report`} className="view-cumulative-report-button">
              {t(k.VIEW_CUMULATIVE_REPORT)}
            </UniButton>
          </ReportActionsRight>
        )}
      </Actions>
    </>
  )
})

const ReportActionsRight = styled.div`
  align-items: stretch;
  display: flex;

  ${maxPhoneQuery(css`
    margin-top: 10px;
  `)}
`

const CreateReportButtonContainer = styled.div`
  margin-right: 12px;
`

import React from 'react'
import k from '../../i18n/keys'
import UniTag from '../common/uni/UniTag'

const statusConfigurations = {
  'planning-to-apply': { variant: 'info', key: k.PLANNING_TO_APPLY },
  'application-sent': { variant: 'warning', key: k.APPLICATION_SENT },
  'response-negative': { variant: 'error', key: k.NOT_GRANTED },
  'response-positive': { variant: 'success', key: k.GRANTED }
}

export const getFormattedStatuses = (t) => {
  return Object.keys(statusConfigurations).reduce((accumulator, status) => {
    accumulator[status] = t(statusConfigurations[status].key)
    return accumulator
  }, {})
}

export function formatStatus(status, t, minimal) {
  const configuration = statusConfigurations[status]

  if (!configuration) return null

  return (
    <UniTag variant={configuration.variant} minimal={minimal}>
      {t(configuration.key)}
    </UniTag>
  )
}

import { Location } from 'history'
import { useTranslation } from 'react-i18next'
import { Moment } from 'moment'
import { TFunction } from 'i18next'
import React, {
  Dispatch,
  RefObject,
  KeyboardEvent,
  KeyboardEventHandler,
  SetStateAction,
  useEffect,
  useState,
  useRef
} from 'react'
import { SupervisorRole } from './model'
import k from './i18n/keys'

function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void
): void

function useEventListener<K extends keyof HTMLElementEventMap, T extends HTMLElement = HTMLDivElement>(
  eventName: K,
  handler: (event: HTMLElementEventMap[K]) => void,
  element: RefObject<T>
): void

function useEventListener<
  KW extends keyof WindowEventMap,
  KH extends keyof HTMLElementEventMap,
  T extends HTMLElement | void = void
>(
  eventName: KW | KH,
  handler: (event: WindowEventMap[KW] | HTMLElementEventMap[KH] | Event) => void,
  element?: RefObject<T>
) {
  const savedHandler = useRef<typeof handler>()
  useEffect(() => {
    const targetElement: T | Window = element?.current || window
    if (!(targetElement && targetElement.addEventListener)) {
      return
    }
    if (savedHandler.current !== handler) {
      savedHandler.current = handler
    }
    const eventListener: typeof handler = (event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event)
      }
    }
    targetElement.addEventListener(eventName, eventListener)
    return () => {
      targetElement.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element, handler])
}

export const singularOrPlural = (count: number, singular: string, plural: string): string => {
  if (count === 1) {
    return singular
  }
  return plural
}

export function sortByFunction<T>(fieldName: keyof T) {
  return function (itemA: T, itemB: T): number {
    if (itemA[fieldName] < itemB[fieldName]) {
      return -1
    } else if (itemA[fieldName] > itemB[fieldName]) {
      return 1
    }
    return 0
  }
}

export function alphabeticalSortByFunction() {
  return function (itemA: string, itemB: string) {
    if (itemA < itemB) {
      return -1
    }
    if (itemA > itemB) {
      return 1
    }
    return 0
  }
}

export const isCreatingNewPlanItem = (location: Location<{ isNew?: 'true' }>) => location.query.isNew === 'true'

export function useSessionStorage<T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
  function getSessionStorageOrDefault(key: string, defaultValue: T) {
    try {
      const stored = sessionStorage.getItem(key)
      if (!stored) {
        return defaultValue
      }
      return JSON.parse(stored)
    } catch (error) {
      return defaultValue
    }
  }

  const [value, setValue] = useState<T>(getSessionStorageOrDefault(key, defaultValue))

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

export function toString(value: string[] | string | number | undefined, separator = ', '): string {
  return Array.isArray(value) ? value.join(`${separator}`) : value !== undefined ? `${value}` : ''
}

export enum AuthStatus {
  NotLoggedIn = 3,
  NotRegistered = 4,
  Closed = 6,
  LoggedIn = 2
}

export function useAuthStatus(authStatus: any): AuthStatus | null {
  const [status, setStatus] = useState<AuthStatus>(null)
  useEffect(() => {
    authStatus.onValue((status: any) => setStatus(status))
  }, [])
  return status
}

export const supervisorRoles: SupervisorRole[] = [
  SupervisorRole.CoordinatingAcademic,
  SupervisorRole.MainSupervisor,
  SupervisorRole.Supervisor
]

export function sortSupervisorRoles(roles: SupervisorRole[]): string[] {
  roles.sort((roleA: SupervisorRole, roleB: SupervisorRole): number => {
    const roleAIndex = supervisorRoles.indexOf(roleA)
    const roleBIndex = supervisorRoles.indexOf(roleB)
    if (roleAIndex < roleBIndex) {
      return -1
    } else if (roleAIndex > roleBIndex) {
      return 1
    }
    return 0
  })
  return roles
}

export function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay === null) {
      return
    }
    const id = setTimeout(() => savedCallback.current(), delay)
    return () => clearTimeout(id)
  }, [delay])
}

export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch (error) {
    console.log('parsing error on', { value })
    return undefined
  }
}

export function getLocalStorageValue<T>(key: string): T | undefined {
  if (typeof window === 'undefined') {
    return undefined
  }
  try {
    const item = window.localStorage.getItem(key)
    return item ? (parseJSON(item) as T) : undefined
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error)
    return undefined
  }
}

export function useLocalStorage<T>(key: string, initialValue: T): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => getLocalStorageValue(key) ?? initialValue)
  const setValue: Dispatch<SetStateAction<T>> = (value) => {
    if (typeof window == 'undefined') {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`)
    }
    try {
      const newValue = value instanceof Function ? value(storedValue) : value
      window.localStorage.setItem(key, JSON.stringify(newValue))
      setStoredValue(newValue)
      window.dispatchEvent(new Event('local-storage'))
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error)
    }
  }

  useEffect(() => {
    setStoredValue(getLocalStorageValue(key) ?? initialValue)
  }, [])

  const handleStorageChange = () => {
    setStoredValue(getLocalStorageValue(key) ?? initialValue)
  }

  useEventListener('storage', handleStorageChange)
  useEventListener('local-storage' as 'storage', handleStorageChange)
  return [storedValue, setValue]
}

export function useCurrentLanguage() {
  const { i18n } = useTranslation()
  const [currentLanguage] = useLocalStorage('currentLanguage', 'fi')

  useEffect(() => {
    i18n.changeLanguage(currentLanguage)
  }, [currentLanguage])

  return currentLanguage
}

export function onKeyboardClick<T>(onClickHandler: () => void): KeyboardEventHandler<T> {
  return (event: KeyboardEvent<T>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      onClickHandler()
    }
  }
}

export function getQueryParameters(): { [key: string]: string[] } {
  return window.location.search
    .slice(1)
    .split('&')
    .map((queryItem) => queryItem.split('=', 2).map((item) => decodeURIComponent(item)))
    .reduce<{ [key: string]: string[] }>((queryParameters, [key, value]) => {
      queryParameters[key] = queryParameters[key] ? [...queryParameters[key], value] : [value]
      return queryParameters
    }, {})
}

export function useActionOutside(callback: () => void, ref: React.RefObject<HTMLElement>, eventType = 'mousedown') {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }
    document.addEventListener(eventType, handleClickOutside)
    return () => {
      document.removeEventListener(eventType, handleClickOutside)
    }
  }, [ref])
}

export function generateAccessibleColorFromString(string: string): string {
  function hashCode(string: string) {
    let hash = 0
    for (let index = 0; index < string.length; index++) {
      hash = string.charCodeAt(index) + ((hash << 5) - hash)
    }
    return hash
  }

  return `hsl(${hashCode(`${string}`) % 360}, 85%, 30%)`
}

export function useInterruptReloading() {
  useEffect(() => {
    window.onbeforeunload = function () {
      return ''
    }
    return () => (window.onbeforeunload = undefined)
  }, [])
}

export function debounce<F extends (...parameters: Parameters<F>) => ReturnType<F>>(
  callback: F,
  waitFor: number
): (...parameters: Parameters<F>) => void {
  let timeout: number
  return (...parameters: Parameters<F>): void => {
    clearTimeout(timeout)
    timeout = setTimeout(() => callback(...parameters), waitFor)
  }
}

export function useWindowSize(delay = 20) {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    const debouncedHandleResize = debounce(handleResize, delay)
    window.addEventListener('resize', debouncedHandleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [delay])

  return windowSize
}

export function generatePageTitle(...pageHierarchy: string[]): string {
  const filteredPageHierarchy = pageHierarchy.filter((name) => name)
  return filteredPageHierarchy.join(' | ')
}

export function formatDate(timestamp: string): string {
  return new Date(timestamp).toLocaleDateString('fi-FI', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

export function hasExceededMaxLength(value: string, maxLength?: number): boolean {
  return value.length > (maxLength ?? 0)
}

export function isTextFieldValid(value: string, maxLength?: number): boolean {
  if (value) {
    return value.trim() !== '' && maxLength ? !hasExceededMaxLength(value, maxLength) : true
  } else {
    return false
  }
}

export function isNumericFieldValid(value: number, minValue?: number, maxValue?: number): boolean {
  return value >= (minValue ?? 0) && value <= (maxValue ?? Number.MAX_SAFE_INTEGER)
}

export function isDateFieldValid(value: Moment, type?: 'date' | 'month', min?: Moment, max?: Moment): boolean {
  if (!value || !value.isValid()) {
    return false
  }

  const validMin = min && min.isValid() ? min : undefined
  const validMax = max && max.isValid() ? max : undefined

  if (type === 'date') {
    if (validMin && value.isBefore(validMin, 'day')) {
      return false
    }
    if (validMax && value.isAfter(validMax, 'day')) {
      return false
    }
  } else if (type === 'month') {
    if (validMin && value.isBefore(validMin, 'month')) {
      return false
    }
    if (validMax && value.isAfter(validMax, 'month')) {
      return false
    }
  }

  return true
}

export const useMutationObserver = (
  callback: MutationCallback,
  element: RefObject<HTMLElement>,
  debounceDelay?: number,
  options: MutationObserverInit = {
    childList: true,
    subtree: true,
    attributes: true,
    characterData: true
  }
) => {
  useEffect(() => {
    let observerCallback = callback

    if (debounceDelay !== undefined) {
      observerCallback = debounce(callback, debounceDelay)
    }

    const observer = new MutationObserver(observerCallback)
    if (element.current) {
      observer.observe(element.current, options)
    }

    return () => {
      observer.disconnect()
    }
  }, [callback, element, options, debounceDelay])
}

export const useResizeObserver = (
  callback: ResizeObserverCallback,
  element: RefObject<HTMLElement>,
  debounceDelay?: number
) => {
  useEffect(() => {
    let observerCallback = callback

    if (debounceDelay !== undefined) {
      observerCallback = debounce(callback, debounceDelay)
    }

    const observer = new ResizeObserver(observerCallback)
    if (element.current) {
      observer.observe(element.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [callback, element, debounceDelay])
}

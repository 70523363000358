import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { UniCheckboxOption } from '../../common/uni/UniCheckbox'
import { Chapter, ChapterMilestone } from '../types'
import { usePhdProjectActions } from '../../doctoral-candidate-overview/phd-project-context/usePhdProjectActions'
import { UniParagraph } from '../../common/uni/UniParagraph'
import { UniBanner } from '../../common/uni/UniBanner'
import { GRAYSCALE_WHITE } from '../../common/uni/colors'
import k from '../../../i18n/keys'
import { wasUpdatedNow } from '../../styledUtils'
import { MilestoneActions } from './MilestoneActions'
import UniLoadingIndicator from '../../common/uni/UniLoadingIndicator'
import { Actions } from '../../common/uni/UniForm'
import { MilestoneForm } from './MilestoneForm'

export type MilestoneAction = 'update' | 'delete' | 'edit'

interface MilestoneProps {
  timelineVisualScale: number
  content: ChapterMilestone
  chapter: Chapter
  gray?: boolean
  white?: boolean
  isReadOnly?: boolean
  showActions?: boolean
  minimal?: boolean
}

export const Milestone = ({
  timelineVisualScale,
  content,
  chapter,
  gray,
  white,
  isReadOnly,
  showActions,
  minimal
}: MilestoneProps) => {
  const { t } = useTranslation()
  const { id, title, completedAt, isCommon, chapterAssignedAt } = content || {}
  const [isCompleted, setIsCompleted] = useState(Boolean(completedAt))
  const [isHandling, setIsHandling] = useState(false)
  const [activeAction, setActiveAction] = useState<MilestoneAction | null>(null)
  const [errorStatus, setErrorStatus] = useState<string | null>(null)
  const { updateCommonMilestone, updateMilestone, deleteMilestone } = usePhdProjectActions()
  const { category } = content || {}
  const { id: chapterId } = chapter || {}
  const hasError = errorStatus !== null && errorStatus !== undefined
  const isLoading = isHandling && !hasError

  useEffect(() => {
    setIsCompleted(Boolean(completedAt))
  }, [completedAt])

  useEffect(() => {
    const handleEditAction = () => {
      setIsHandling(false)
    }

    const handleUpdateAction = async () => {
      setErrorStatus(null)
      try {
        if (isCommon) {
          await updateCommonMilestone(id, isCompleted, chapterId)
        } else {
          await updateMilestone(id, title, isCompleted)
        }
        setIsHandling(false)
      } catch (error) {
        const { status } = error
        setIsCompleted(Boolean(completedAt))
        setErrorStatus(status)
        setIsHandling(false)
      }
    }

    const handleDeleteAction = async () => {
      try {
        if (isCommon) {
          await updateCommonMilestone(id, isCompleted, null)
        } else {
          await deleteMilestone(id)
        }
      } catch (error) {
        const { status } = error
        setErrorStatus(status)
        setIsHandling(false)
      }
    }

    if (isHandling && activeAction === 'edit') {
      handleEditAction()
    }

    if (isHandling && activeAction === 'update') {
      handleUpdateAction()
    }

    if (isHandling && activeAction === 'delete') {
      handleDeleteAction()
    }
  }, [isHandling])

  return (
    <>
      <Wrapper
        timelineVisualScale={timelineVisualScale}
        showUpdatedBorder={false}
        className={`milestone ${isCommon ? 'common' : 'personal'} ${activeAction === 'edit' ? 'editing' : 'viewing'}`}
      >
        {activeAction === 'edit' ? (
          <MilestoneForm
            chapterId={chapterId}
            closeAction={() => setActiveAction(null)}
            milestoneId={id}
            value={title}
            action={'update'}
            completedAt={completedAt}
            chapterAssignedAt={chapterAssignedAt}
            isCommon={isCommon}
          />
        ) : (
          <>
            <UniCheckboxOption
              label={
                <Actions noPadTop>
                  {title} <UniLoadingIndicator isLoading={isLoading} delay={300} />
                </Actions>
              }
              checked={isCompleted}
              gray={gray}
              white={white}
              fullWidth
              tags={
                minimal
                  ? []
                  : [
                      {
                        minimal: true,
                        children: isCommon ? t(k.COMMON) : t(k.PERSONAL)
                      },
                      {
                        minimal: true,
                        children: category ? category.title : null
                      }
                    ]
              }
              onChange={(checked) => {
                setIsCompleted(checked)
                setIsHandling(true)
                setActiveAction('update')
              }}
              disabled={isReadOnly}
              hideHelperText
            />

            <WrapperActions white={white} gray={gray}>
              {showActions ? (
                <MilestoneActions
                  title={title}
                  milestoneId={id}
                  isHandling={isHandling}
                  setActiveAction={setActiveAction}
                  setIsHandling={setIsHandling}
                  setErrorStatus={setErrorStatus}
                />
              ) : null}
            </WrapperActions>
          </>
        )}
      </Wrapper>

      {hasError ? (
        <Wrapper timelineVisualScale={timelineVisualScale} marginTop>
          <UniBanner variant="error" small close={() => setErrorStatus(null)}>
            <UniParagraph noMargin small>
              {activeAction === 'update' ? t(k.UPDATING_MILESTONE_FAILED_WITH_ERROR_CODE) : null}
              {activeAction === 'delete' ? t(k.DELETING_MILESTONE_FAILED_WITH_ERROR_CODE) : null} {errorStatus}
              <br />
              <Trans
                t={t}
                i18nKey={k.TRY_AGAIN_LATER}
                components={[<a href="mailto:thessa-admin@helsinki.fi" />]} //eslint-disable-line react/jsx-key, jsx-a11y/anchor-has-content
              />
            </UniParagraph>
          </UniBanner>
        </Wrapper>
      ) : null}
    </>
  )
}

const Wrapper = styled.div<{ timelineVisualScale?: number; showUpdatedBorder?: boolean; marginTop?: boolean }>`
  margin-left: ${({ timelineVisualScale }) => timelineVisualScale * 20}px;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    ${(props) => wasUpdatedNow(props.showUpdatedBorder)}
  }

  ${({ marginTop, timelineVisualScale }) =>
    marginTop &&
    css`
      margin-top: ${timelineVisualScale * 6}px;
    `}
`

const WrapperActions = styled.div<{ white?: boolean; gray?: boolean }>`
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 10px;

  ${(props) =>
    props.gray &&
    css`
      background-color: rgba(0, 0, 0, 0.04);
    `}

  ${(props) =>
    props.white &&
    css`
      background-color: ${GRAYSCALE_WHITE};
    `}
`

export default Milestone

import { ChapterItemType, ChapterItem, ChapterAssignableItem, ChapterItemBase, ChapterMilestone } from './types'
import { useTranslation } from 'react-i18next'
import { usePhdProject } from '../doctoral-candidate-overview/phd-project-context/PhdProjectContextProvider'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useMutationObserver } from '../../util'
import { Chapter } from '../../models/Chapter'
import { Chapter as ChapterType } from './types'
import { OptionGroup, Option } from '../common/uni/UniSelect'

export function calculateCompletedAt(plannedAt: moment.Moment | undefined, interval: number) {
  return plannedAt && moment().isAfter(plannedAt) ? plannedAt.clone().add(interval, 'months') : undefined
}

export const useChapters = (): {
  chapters: ChapterType[]
  expectedGraduationDate: string | undefined
} => {
  const { t } = useTranslation()
  const { thesisCommitteeMeetings, beginningOfStudies, expectedGraduationDate, milestones, commonMilestones } =
    usePhdProject()
  const chapterGenerator = new Chapter(t)

  const allMilestones = [...milestones, ...commonMilestones]
  const allMilestonesSorted = sortMilestones(allMilestones)

  const chapters = chapterGenerator.generateChapters(
    thesisCommitteeMeetings,
    beginningOfStudies,
    expectedGraduationDate,
    {
      [ChapterItemType.Milestone]: allMilestonesSorted
    }
  )

  return {
    chapters,
    expectedGraduationDate
  }
}

export function useChapterProgress(chapter: ChapterType, chapters: ChapterType[], timelineVisualScale: number) {
  const { items } = chapter || {}
  const containerRef = useRef<HTMLDivElement>(null)
  const itemReferences = useRef<(HTMLLIElement | null)[]>([])
  const [barHeight, setBarHeight] = useState(0)
  const [completedHeight, setCompletedHeight] = useState(0)

  const isNextStartEventCompleted = getIsNextStartEventCompleted(chapters, chapter)
  const topOffset = getStartEventIconHeight(timelineVisualScale)

  const updateBarHeight = useCallback(() => {
    if (containerRef.current) {
      const container = containerRef.current
      const containerHeight = container.offsetHeight - topOffset
      setBarHeight(containerHeight)

      const isStartEventCompleted = !!items?.[0]?.completedAt

      if (isNextStartEventCompleted) {
        setCompletedHeight(containerHeight)
      } else if (isStartEventCompleted && !isNextStartEventCompleted) {
        const startEventItem = itemReferences.current[0]
        const chapterStartDate = getChapterStartEventDate(chapter)
        const nextChapterStartDate = getNextStartEventDate(chapters, chapter)
        const isCurrentDateBetweenChapterStartDateAndNextChapterStartDate =
          moment().isAfter(chapterStartDate) && moment().isBefore(nextChapterStartDate)

        if (startEventItem && isCurrentDateBetweenChapterStartDateAndNextChapterStartDate) {
          const percentageOfTimePassed =
            moment().diff(moment(chapterStartDate)) / moment(nextChapterStartDate).diff(moment(chapterStartDate))
          const roundedPercentageOfTimePassed = Math.round(percentageOfTimePassed * 100) / 100
          const completedHeight = containerHeight * roundedPercentageOfTimePassed

          setCompletedHeight(completedHeight)
        } else {
          const startEventItemBottom = startEventItem.offsetTop + startEventItem.offsetHeight
          const justBeforeNextStartEvent = containerHeight - startEventItemBottom / 2
          const completedHeight = justBeforeNextStartEvent

          setCompletedHeight(completedHeight)
        }
      } else {
        setCompletedHeight(0)
      }
    }
  }, [items])

  useEffect(() => {
    const requestAnimationFrameId = requestAnimationFrame(() => {
      updateBarHeight()
    })
    return () => cancelAnimationFrame(requestAnimationFrameId)
  }, [updateBarHeight])

  useMutationObserver(updateBarHeight, containerRef, 50)

  return {
    containerRef,
    itemReferences,
    barHeight,
    topOffset,
    completedHeight
  }
}

export function getCurrentChapter(chapters: ChapterType[]) {
  if (!Array.isArray(chapters)) {
    return null
  }
  return [...chapters]
    .reverse()
    .find((chapter) =>
      chapter.items.some(
        (item) =>
          (item.type === ChapterItemType.Meeting && item.completedAt) ||
          (item.type === ChapterItemType.StudyRight && item.completedAt)
      )
    )
}

export function getNextStartEvent(chapters: ChapterType[], chapter: ChapterType) {
  return chapters[chapters.indexOf(chapter) + 1]?.items?.[0]
}

export function getIsNextStartEventCompleted(chapters: ChapterType[], chapter: ChapterType) {
  return Boolean(getNextStartEvent(chapters, chapter)?.completedAt)
}

export function getNextStartEventDate(chapters: ChapterType[], chapter: ChapterType) {
  const nextStartEvent = getNextStartEvent(chapters, chapter)
  if (!nextStartEvent) return undefined
  return 'completedAt' in nextStartEvent && nextStartEvent.completedAt
    ? nextStartEvent.completedAt
    : 'plannedAt' in nextStartEvent && nextStartEvent.plannedAt
    ? nextStartEvent.plannedAt
    : 'suggestedAt' in nextStartEvent && nextStartEvent.suggestedAt
    ? nextStartEvent.suggestedAt
    : undefined
}

export function getNumberOfChaptersCompleted(chapters: ChapterType[]) {
  return chapters.filter((chapter) => {
    const isNextStartEventCompleted = getIsNextStartEventCompleted(chapters, chapter)
    return getAreAllChapterItemsCompleted(chapter) && isNextStartEventCompleted
  }).length
}

export function getAreAllChapterItemsCompleted(chapter: ChapterType) {
  return chapter?.items.every((item) => item.completedAt)
}

export function getIsChapterStartEventOnlyItem(chapter: ChapterType) {
  return (
    chapter.items.length === 1 &&
    (chapter.items[0].type === ChapterItemType.Meeting || chapter.items[0].type === ChapterItemType.StudyRight)
  )
}

export function getChapterStartEvent(chapter: ChapterType) {
  return chapter.items.find((item) => item.type === 'Meeting' || item.type === 'StudyRight')
}

export function findAllChapterStartEvents(chapter: ChapterType) {
  return chapter.items.filter((item) => item.type === 'Meeting' || item.type === 'StudyRight')
}

export function getChapterStartEventDate(chapter: ChapterType) {
  const startEvent = getChapterStartEvent(chapter)
  if (!startEvent) return undefined
  return 'completedAt' in startEvent && startEvent.completedAt
    ? startEvent.completedAt
    : 'plannedAt' in startEvent && startEvent.plannedAt
    ? startEvent.plannedAt
    : 'suggestedAt' in startEvent && startEvent.suggestedAt
    ? startEvent.suggestedAt
    : undefined
}

export function getChapterStatusText(chapter: ChapterType, chapters: ChapterType[], item: ChapterItem): string | null {
  const isChapterCompleted = getAreAllChapterItemsCompleted(chapter) && getIsNextStartEventCompleted(chapters, chapter)
  const currentChapter = getCurrentChapter(chapters)
  const isCurrentChapter = currentChapter?.id === chapter?.id

  if (!isChapterCompleted && isCurrentChapter) {
    return 'active'
  } else if ('completedAt' in item && item.completedAt) {
    return 'completed'
  } else if ('plannedAt' in item && item.plannedAt) {
    return 'planned'
  } else if ('suggestedAt' in item && item.suggestedAt) {
    return 'suggested'
  }
  return null
}

export function convertToChapterItem(
  item: ChapterAssignableItem,
  type: ChapterItemType,
  chapterNumber: number
): ChapterItem {
  const baseItem: ChapterItemBase = {
    id: item.id,
    completedAt: item.completedAt || undefined,
    chapterAssignedAt: item.chapterAssignedAt || undefined
  }

  switch (type) {
    case ChapterItemType.Milestone:
      return {
        ...baseItem,
        type,
        title: item.title,
        isCommon: Boolean(item.isCommon) || false,
        chapter: chapterNumber,
        category: item.category ?? undefined
      }
    default:
      throw new Error(`Unsupported ChapterItemType: ${type}`)
  }
}

export const getMilestonesByCommonStatus = (chapters: ChapterType[], isCommon: boolean, checkCompletedAt = false) => {
  return chapters.reduce((accumulator, chapter) => {
    if (Array.isArray(chapter.items)) {
      const milestones = chapter.items.filter((item) => {
        const isMilestone = item.type === ChapterItemType.Milestone && item.isCommon === isCommon
        const isCompleted = checkCompletedAt ? Boolean(item.completedAt) : true
        return isMilestone && isCompleted
      })
      return accumulator.concat(milestones)
    }
    return accumulator
  }, [])
}

export function groupItemsForSelect(items: ChapterMilestone[], currentMilestoneId?: number) {
  const groupedItems: Record<number, OptionGroup> = {}
  const uncategorizedOptions: Option[] = []

  items.forEach((item) => {
    const isUnassigned = !isCommonMilestoneUnassigned(item)
    const disabled = currentMilestoneId && item.id === currentMilestoneId ? false : isUnassigned

    if (item.category && item.category.id && item.category.title) {
      const categoryId = item.category.id
      const categoryTitle = item.category.title

      if (!groupedItems[categoryId]) {
        groupedItems[categoryId] = {
          label: categoryTitle,
          options: []
        }
      }

      groupedItems[categoryId].options.push({
        value: item.id.toString(),
        label: item.title,
        disabled
      })
    } else {
      uncategorizedOptions.push({
        value: item.id.toString(),
        label: item.title,
        disabled
      })
    }
  })

  return {
    groupedItems: Object.values(groupedItems),
    uncategorizedOptions
  }
}

export function getCommonMilestones(chapters: ChapterType[]) {
  return chapters.reduce((accumulator, chapter) => {
    return accumulator.concat(chapter.items.filter((item) => item.type === ChapterItemType.Milestone && item.isCommon))
  }, [])
}

export function getPersonalMilestones(chapters: ChapterType[]) {
  return chapters.reduce((accumulator, chapter) => {
    return accumulator.concat(chapter.items.filter((item) => item.type === ChapterItemType.Milestone && !item.isCommon))
  }, [])
}

export function getUnassignedCommonMilestones(milestones: ChapterMilestone[]) {
  return milestones.filter((milestone) => isCommonMilestoneUnassigned(milestone))
}

export function isCommonMilestoneUnassigned(milestone: ChapterMilestone) {
  return !Number.isInteger(milestone.chapter) || milestone.chapter === 0
}

export function findDefaultValue(options: (Option | OptionGroup)[], currentMilestoneId?: number) {
  if (currentMilestoneId) {
    return currentMilestoneId.toString()
  }
  for (const item of options) {
    if ('options' in item) {
      for (const option of item.options) {
        if (!option.disabled) {
          return option.value
        }
      }
    } else if (!item.disabled) {
      return item.value
    }
  }
  return null
}

export function sortMilestones(
  milestones: ChapterMilestone[],
  sortBy: 'chapterAssignedAt' | 'completedAt' = 'chapterAssignedAt'
) {
  return milestones.sort((firstMilestone, secondMilestone) => {
    const firstDateValue = firstMilestone[sortBy]
    const secondDateValue = secondMilestone[sortBy]

    const firstItemDate = firstDateValue ? moment(firstDateValue) : null
    const secondItemDate = secondDateValue ? moment(secondDateValue) : null

    if (!firstItemDate && !secondItemDate) return 0
    if (!firstItemDate) return 1
    if (!secondItemDate) return -1

    return firstItemDate.diff(secondItemDate)
  })
}

export function getMeetings(chapters: ChapterType[]) {
  return chapters.reduce((accumulator, chapter) => {
    return accumulator.concat(chapter.items.filter((item) => item.type === ChapterItemType.Meeting))
  }, [])
}

export function getStartEventIconHeight(timelineVisualScale: number) {
  return timelineVisualScale * 14
}

export function getProgressDotHeight(timelineVisualScale: number) {
  return timelineVisualScale * 4
}

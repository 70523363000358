import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import React, { useRef } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'
import { UniButton } from './UniButton'
import Tooltip, { Placement } from '../Tooltip'
import { Actions } from './UniForm'
import { UniParagraph } from './UniParagraph'
import { useWindowSize } from '../../../util'
import { TABLET_MIN } from '../../styledUtils'
import { DropdownStyles } from '../header/DropdownStyles'
import { LinkInteractionStyles, LinkStyles } from '../header/LinkStyles'
import { GRAYSCALE_MEDIUM } from './colors'
import { UniList, UniListItem } from './UniList'

function updatePlacement(placement: Placement, rect: DOMRect, windowWidth: number, windowHeight: number): Placement {
  const tooltipSize = { width: 300, height: 200 }

  function hasSpaceFor(side: Placement): boolean {
    switch (side) {
      case 'right':
        return rect.right + tooltipSize.width <= windowWidth
      case 'left':
        return rect.left - tooltipSize.width >= 0
      case 'top':
        return rect.top - tooltipSize.height >= 0
      case 'bottom':
        return rect.bottom + tooltipSize.height <= windowHeight
      default:
        return false
    }
  }

  const placements: Placement[] = ['right', 'left', 'top', 'bottom']
  if (!hasSpaceFor(placement)) {
    for (const updatedPlacement of placements) {
      if (updatedPlacement !== placement && hasSpaceFor(updatedPlacement)) {
        return updatedPlacement
      }
    }
  }

  return placement
}

interface PlacementStyles {
  top?: number | 'auto'
  right?: number | 'auto'
  left?: number | 'auto'
  bottom?: number | 'auto'
  transform?: string
}

function getPlacementValues(placement: Placement, rect: DOMRect): PlacementStyles {
  const offset = 4
  const commonTop = rect.top + rect.height / 2 + window.scrollY
  const commonLeft = rect.left + rect.width / 2 + window.scrollX
  const commonStyles: PlacementStyles = { right: 'auto', bottom: 'auto' }

  switch (placement) {
    case 'right':
      return {
        ...commonStyles,
        top: commonTop,
        left: rect.left + rect.width + window.scrollX + offset,
        transform: 'translateY(-50%)'
      }
    case 'left':
      return {
        ...commonStyles,
        top: commonTop,
        left: rect.left + window.scrollX - offset,
        transform: 'translateX(-100%) translateY(-50%)'
      }
    case 'bottom':
      return {
        ...commonStyles,
        top: rect.top + rect.height + window.scrollY + offset,
        left: commonLeft,
        transform: 'translateX(-50%)'
      }
    case 'top':
    default:
      return {
        ...commonStyles,
        top: rect.top + window.scrollY - offset,
        left: commonLeft,
        transform: 'translateX(-50%) translateY(-100%)'
      }
  }
}

interface UniPopoverProps {
  isVisible: boolean
  onCancel?: () => void
  onDelete?: () => void
  confirmationText?: string
  cancelText?: string
  deleteText?: string
  items?: { label: string; onClick: () => void; disabled?: boolean }[]
  top?: boolean
  right?: boolean
  bottom?: boolean
  left?: boolean
  small?: boolean
  children: React.ReactNode
}

export const UniPopover = ({
  isVisible,
  onCancel,
  onDelete,
  confirmationText = undefined,
  cancelText = undefined,
  deleteText = undefined,
  items = undefined,
  top = false,
  right = false,
  bottom = false,
  left = false,
  small = false,
  children
}: UniPopoverProps) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const windowSize = useWindowSize()
  const preferredPlacement = top ? 'top' : right ? 'right' : bottom ? 'bottom' : left ? 'left' : 'bottom'
  const rect = ref.current?.getBoundingClientRect() ?? {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0
  }
  const updatedPlacement = updatePlacement(preferredPlacement, rect, windowSize.width, windowSize.height)
  const placementValues = getPlacementValues(updatedPlacement, rect)
  ReactModal.setAppElement('#thessa-application')

  const modalContentLabel = onDelete ? t(k.REMOVAL_CONFIRMATION) : t(k.CONTEXT_MENU)

  return (
    <ModalControl ref={ref}>
      {children}
      <ReactModal
        isOpen={isVisible}
        contentLabel={modalContentLabel}
        onRequestClose={onCancel}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            position: 'absolute',
            inset: 0,
            zIndex: 1000,
            width: document.documentElement.scrollWidth,
            height: document.documentElement.scrollHeight,
            background: 'transparent'
          },
          content: {
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            border: 'none',
            background: 'transparent',
            overflow: 'visible',
            WebkitOverflowScrolling: 'touch',
            borderRadius: 0,
            outline: 'none',
            padding: 0,
            ...placementValues
          }
        }}
      >
        <ModalContent placement={updatedPlacement} small={small} className="uni-popover">
          <ConfirmTooltipContent
            onCancel={onCancel}
            onDelete={onDelete}
            confirmationText={confirmationText}
            cancelText={cancelText}
            deleteText={deleteText}
          />
          {items?.length > 0 ? (
            <ButtonList noMargin>
              {items?.map((item, index) => (
                <ButtonItem key={index} noBullet noMargin small={small}>
                  <Button key={index} onClick={item.onClick} small={small} disabled={item.disabled}>
                    <span>{item.label}</span>
                  </Button>
                </ButtonItem>
              ))}
              <ButtonItem noBullet noMargin small={small}>
                <Button onClick={onCancel} small={small}>
                  <span>{cancelText ?? t(k.CANCEL)}</span>
                </Button>
              </ButtonItem>
            </ButtonList>
          ) : null}
        </ModalContent>
      </ReactModal>
    </ModalControl>
  )
}

interface ConfirmTooltipContentProps {
  onCancel: () => void
  onDelete: () => void
  confirmationText?: string
  cancelText?: string
  deleteText?: string
  narrow?: boolean
}

const ConfirmTooltipContent = ({
  onCancel,
  onDelete,
  confirmationText,
  cancelText,
  deleteText
}: ConfirmTooltipContentProps) => {
  const { t } = useTranslation()
  const windowSize = useWindowSize()

  if (!onDelete && !confirmationText) {
    return null
  }

  return (
    <ConfirmTooltipContentContainer onClick={(clickEvent) => clickEvent.stopPropagation()}>
      <UniParagraph noMargin>{confirmationText ?? t(k.ARE_YOU_SURE)}</UniParagraph>
      <Actions noWrap={windowSize.width > TABLET_MIN}>
        <UniButton secondary onClick={onCancel} small noWrap={windowSize.width > TABLET_MIN}>
          {cancelText ?? t(k.CANCEL)}
        </UniButton>
        <UniButton onClick={onDelete} small noWrap={windowSize.width > TABLET_MIN}>
          {deleteText ?? t(k.CONFIRM_DELETE)}
        </UniButton>
      </Actions>
    </ConfirmTooltipContentContainer>
  )
}

const ConfirmTooltipContentContainer = styled.div`
  display: grid;
  padding: 5px 10px;
`

const ModalControl = styled.div`
  display: inline-block;
  position: relative;
`

const ModalContent = styled.div<{ placement?: Tooltip['placement']; small?: boolean }>`
  ${({ placement, small }) => (placement ? DropdownStyles(placement, small) : DropdownStyles('top', small))}
  position: relative;
  min-width: 150px;
`

const ButtonList = styled(UniList)`
  display: flex;
  flex-direction: column;
`

const ButtonItem = styled(UniListItem)<{ small?: boolean }>`
  list-style: none;

  &:not(:only-child):last-child {
    position: relative;
    margin-top: ${({ small }) => (small ? '8px' : '16px')};

    &:before {
      content: '';
      position: absolute;
      top: ${({ small }) => (small ? '-4px' : '-8px')};
      right: 12px;
      left: 12px;
      height: 1px;
      background-color: ${GRAYSCALE_MEDIUM};
    }
  }
`

const Button = styled.button<{ small?: boolean }>`
  background: none;
  border: none;
  ${({ small }) => LinkStyles(small)}
  width: 100%;
  font-size: 14px;
  ${LinkInteractionStyles()}
`

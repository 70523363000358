import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Tooltip, { TooltipFocusableControl } from '../Tooltip'
import k from '../../../i18n/keys'
import { fromTabletUp } from '../../styledUtils'
import styled, { css } from 'styled-components'
import { ADDITIONAL_YELLOW_MEDIUM_DARK } from '../uni/colors'

const HighlightBalloon = ({
  thessaList = false,
  phdProjectList = false,
  supervisionPlanList = false,
  doctoralCandidateList = false,
  tooltipContent
}) => {
  const { t } = useTranslation()
  return (
    <Tooltip content={tooltipContent}>
      <BalloonWrapper
        className={classNames('highlight-balloon', {
          'highlight-balloon--thessa-list': thessaList,
          'highlight-balloon--phd-project-list': phdProjectList,
          'highlight-balloon--supervision-plan-list': supervisionPlanList,
          'highlight-balloon--doctoral-candidate-list': doctoralCandidateList
        })}
        role="img"
        aria-label={`${tooltipContent} ${t(k.INDICATOR)}`}
        thessaList={thessaList}
        phdProjectList={phdProjectList}
        supervisionPlanList={supervisionPlanList}
        doctoralCandidateList={doctoralCandidateList}
      >
        <Balloon className="highlight-balloon__color-balloon" />
      </BalloonWrapper>
    </Tooltip>
  )
}

HighlightBalloon.propTypes = {
  tooltipContent: PropTypes.node.isRequired
}

export default HighlightBalloon

const BalloonWrapper = styled(TooltipFocusableControl)`
  align-items: center;
  background: #fff !important;
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;

  ${({ thessaList }) =>
    thessaList &&
    css`
      position: absolute;
      top: 14px;
      left: -10px;

      ${fromTabletUp(css`
        top: 10px;
        left: -34px;
      `)}
    `}

  ${({ phdProjectList }) =>
    phdProjectList &&
    css`
      position: absolute;
      left: -12px;
    `}

  ${({ supervisionPlanList }) =>
    supervisionPlanList &&
    css`
      position: absolute;
      left: -12px;
    `}

  ${({ doctoralCandidateList }) =>
    doctoralCandidateList &&
    css`
      position: absolute;
      top: 18px;
      left: -12px;
    `}
`

const Balloon = styled.div`
  background: ${ADDITIONAL_YELLOW_MEDIUM_DARK};
  border-radius: 50%;
  height: 12px;
  width: 12px;
`

import React, { forwardRef, InputHTMLAttributes, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import classnames from 'classnames'
import { DEFAULT_FONT } from './typography'
import {
  ADDITIONAL_GREEN_DARK,
  ADDITIONAL_RED_DARK,
  ADDITIONAL_YELLOW,
  BRAND_MAIN_LIGHT,
  GRAYSCALE_BACKGROUND_BOX,
  GRAYSCALE_DARK,
  GRAYSCALE_DARK_TEXT,
  GRAYSCALE_MEDIUM_DARK,
  GRAYSCALE_WHITE
} from './colors'
import { HyIcon } from '../icon/HyIcon'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'

interface UniInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element
  isValid?: boolean
  validationHintAfter?: string | JSX.Element
  validationHintBefore?: string | JSX.Element
  validationHintInline?: string | JSX.Element
  hasInvalidHighlight?: boolean
  fullWidth?: boolean
  small?: boolean
}

export enum InputStateIcon {
  Required,
  Valid,
  Invalid
}

export const UniInput = forwardRef<HTMLInputElement, UniInputProps>(
  (
    {
      id,
      label,
      required,
      isValid,
      validationHintAfter,
      validationHintBefore,
      validationHintInline,
      small,
      hasInvalidHighlight,
      fullWidth,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation()
    const [hasFocus, setHasFocus] = useState(false)
    const onFocus = useCallback(() => setHasFocus(true), [])
    const onBlur = useCallback(() => setHasFocus(false), [])
    const hasIcon = required === true && isValid !== undefined
    const isEmpty = !props.value || (props.type === 'number' && props.value === '0')
    const { icon, highlightInvalidField } = getIcon(required, isValid, hasInvalidHighlight, isEmpty, hasFocus)
    return (
      <InputWrapper fullWidth={fullWidth}>
        {validationHintBefore && (
          <ValidationHintBeforeContainer
            id={`${id}-help-before`}
            showInvalidColor={highlightInvalidField}
            aria-hidden="true"
          >
            {validationHintBefore}
          </ValidationHintBeforeContainer>
        )}
        <Container validationHintInline={validationHintInline}>
          <InputContainer>
            <Input
              id={id}
              ref={ref}
              {...props}
              fullWidth={fullWidth}
              small={small}
              showInvalidBorder={highlightInvalidField}
              aria-invalid={highlightInvalidField ? 'true' : undefined}
              hasIcon={hasIcon}
              onFocus={onFocus}
              onBlur={onBlur}
              aria-required={required ? 'true' : undefined}
              aria-describedby={
                validationHintBefore || validationHintInline || validationHintAfter || highlightInvalidField
                  ? `${validationHintBefore ? `${id}-help-before ` : ''}${
                      validationHintInline ? `${id}-help-inline ` : ''
                    }${validationHintAfter || highlightInvalidField ? `${id}-help-after ` : ''}`
                  : undefined
              }
            />

            {hasIcon && (
              <IconContainer icon={icon} className="validated-form-group__validation-status" small={small}>
                <Icon
                  valid={icon === InputStateIcon.Valid}
                  required={icon === InputStateIcon.Required}
                  info={icon === InputStateIcon.Invalid}
                  className={classnames({
                    'glyphicon-ok': icon === InputStateIcon.Valid
                  })}
                />
              </IconContainer>
            )}
          </InputContainer>

          {validationHintInline && (
            <ValidationHintInlineContainer
              id={`${id}-help-inline`}
              showInvalidColor={highlightInvalidField}
              aria-hidden="true"
            >
              {validationHintInline}
            </ValidationHintInlineContainer>
          )}
        </Container>

        <ValidationHintAfterContainer
          id={validationHintAfter || highlightInvalidField ? `${id}-help-after` : undefined}
          showInvalidColor={highlightInvalidField}
          aria-hidden="true"
        >
          {highlightInvalidField ? (
            <ValidationErrorAfterContainer>
              {isEmpty ? `${label} ${t(k.IS_MISSING)}` : t(k.THE_VALUE_ENTERED_IS_INVALID)}
            </ValidationErrorAfterContainer>
          ) : null}
          {validationHintAfter}
        </ValidationHintAfterContainer>
      </InputWrapper>
    )
  }
)

UniInput.displayName = 'UniInput'

function getIcon(
  isRequired: boolean,
  isValid: boolean,
  hasInvalidHighlight: boolean,
  isEmpty: boolean,
  hasFocus: boolean
): { icon: InputStateIcon | undefined; highlightInvalidField: boolean } {
  if (isRequired) {
    if (hasInvalidHighlight) {
      return { icon: InputStateIcon.Invalid, highlightInvalidField: true }
    }
    if (isValid) {
      return { icon: InputStateIcon.Valid, highlightInvalidField: false }
    }
    if (hasFocus) {
      return { icon: InputStateIcon.Required, highlightInvalidField: false }
    }
    if (isEmpty) {
      return { icon: InputStateIcon.Required, highlightInvalidField: false }
    }
    if (isValid === undefined) {
      return { icon: undefined, highlightInvalidField: false }
    }
    return { icon: InputStateIcon.Invalid, highlightInvalidField: true }
  }
  return { icon: undefined, highlightInvalidField: false }
}

export const InputWrapper = styled.div<{ fullWidth?: boolean }>`
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`

const Container = styled.div<{ validationHintInline?: string | JSX.Element }>`
  position: relative;

  ${(props) =>
    props.validationHintInline &&
    css`
      display: flex;
    `}
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`

const Input = styled.input<{ showInvalidBorder: boolean; hasIcon: boolean; fullWidth?: boolean; small?: boolean }>`
  background: ${GRAYSCALE_WHITE};
  border: 1px solid ${GRAYSCALE_MEDIUM_DARK};
  color: ${GRAYSCALE_DARK_TEXT};
  height: 44px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 12px;
  width: 100%;
  ${DEFAULT_FONT}

  &:focus {
    border: 1px solid ${BRAND_MAIN_LIGHT};
    outline: solid 2px ${ADDITIONAL_YELLOW};
    outline-offset: 2px;
  }

  &::-webkit-calendar-picker-indicator {
    position: relative;
    background-position: center;
    background-size: 22px;
    height: 44px;
    width: 44px;
    opacity: 0.6;

    &:hover,
    &:focus {
      cursor: pointer;
      opacity: 1;
    }

    &:focus {
      outline: solid 2px ${ADDITIONAL_YELLOW};
      outline-offset: -6px;
    }
  }

  ${(props) =>
    props.showInvalidBorder &&
    css`
      border-color: ${ADDITIONAL_RED_DARK};
      box-shadow: 0 0 0 1px ${ADDITIONAL_RED_DARK};
    `}

  ${(props) =>
    props.hasIcon &&
    css`
      padding-right: 36px;
    `}

    ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.small &&
    css`
      height: 32px;
      font-size: 14px;
      line-height: 20px;
      padding: 6px 8px;
      padding-right: 48px;
    `}
`

export const HINT_CONTAINER_HEIGHT = 21

export const IconContainer = styled.div<{ icon: InputStateIcon; small?: boolean }>`
  align-items: center;
  bottom: 0px;
  display: flex;
  height: 44px;
  position: absolute;
  right: 12px;

  ${(props) =>
    props.icon !== undefined &&
    css`
      color: ${colorByIcon[props.icon]};
    `}

  ${(props) =>
    props.small &&
    css`
      height: 32px;
    `}
`

const colorByIcon: { [key in InputStateIcon]: string } = {
  [InputStateIcon.Required]: BRAND_MAIN_LIGHT,
  [InputStateIcon.Valid]: ADDITIONAL_GREEN_DARK,
  [InputStateIcon.Invalid]: ADDITIONAL_RED_DARK
}

export const Icon = styled(HyIcon)`
  font-size: 10px;
  min-height: 1px;

  ${(props) =>
    props.valid &&
    css`
      font-size: 16px;
    `}

  ${(props) =>
    props.info &&
    css`
      font-size: 20px;
    `}
`

export const ValidationHintBeforeContainer = styled.div<{ showInvalidColor?: boolean }>`
  color: ${(props) => (props.showInvalidColor ? ADDITIONAL_RED_DARK : GRAYSCALE_DARK)};
  min-height: ${HINT_CONTAINER_HEIGHT}px;
  font-size: 13px;
  margin-bottom: 2px;
  ${DEFAULT_FONT}
`

export const ValidationHintInlineContainer = styled.div<{ showInvalidColor?: boolean }>`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 44px;
  color: ${(props) => (props.showInvalidColor ? ADDITIONAL_RED_DARK : GRAYSCALE_DARK)};
  text-align: right;
  padding: 2px 12px 0;
  background-color: ${GRAYSCALE_BACKGROUND_BOX};
  border: 1px solid ${GRAYSCALE_MEDIUM_DARK};
  border-left: none;
  color: ${GRAYSCALE_DARK_TEXT};
  ${DEFAULT_FONT}
`

export const ValidationHintAfterContainer = styled.div<{ showInvalidColor?: boolean }>`
  color: ${(props) => (props.showInvalidColor ? ADDITIONAL_RED_DARK : GRAYSCALE_DARK)};
  min-height: ${HINT_CONTAINER_HEIGHT}px;
  font-size: 13px;
  text-align: right;
  padding-top: 2px;
  ${DEFAULT_FONT}
`

export const ValidationErrorAfterContainer = styled.div<{ borderTop?: boolean }>`
  ${DEFAULT_FONT}
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  ${({ borderTop }) =>
    borderTop &&
    css`
      border-top: 2px solid ${ADDITIONAL_RED_DARK};
      padding-top: 2px;
      margin-top: 4px;
    `}‰
`

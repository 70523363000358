import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { inject, observer } from 'mobx-react'
import SupervisionPlanOverview from './SupervisionPlanOverview'
import StudyPlanOverview from './StudyPlanOverview'
import SupervisorsAndThesisCommitteeOverview from './SupervisorsAndThesisCommitteeOverview'
import { getDoctoralCandidateId, hasRole } from '../../stores/AuthenticationStore'
import InviteThesisCommitteeMembersNotification from './InviteThesisCommitteeMembersNotification'
import Page from '../common/page/Page'
import PageHeading from '../common/page/PageHeading'
import PageNavigation from '../common/page/PageNavigation'
import { withTranslation } from 'react-i18next'
import k from '../../i18n/keys'
import { PhdProjectContextProvider } from './phd-project-context/PhdProjectContextProvider'
import { getRequesterRole } from '../doctoral-candidate-list/MyDoctoralCandidates'
import { Role } from '../../stores/role'
import PageBlock from '../common/page/PageBlock'
import { Helmet } from 'react-helmet'
import { generatePageTitle } from '../../util'

@inject('SupervisionPlanStore', 'HighlightStore')
@observer
class DoctoralCandidateOverview extends React.Component {
  componentDidMount() {
    this.props.SupervisionPlanStore.loadSupervisionPlans(this.props.doctoralCandidate.id)
  }

  render() {
    const { t } = this.props

    if (isEmpty(this.props.doctoralCandidate)) {
      return null
    }

    const showBackToOverviewLinkAndChildContent = !!this.props.children
    if (showBackToOverviewLinkAndChildContent) {
      return (
        <Page>
          {this._renderHeading(showBackToOverviewLinkAndChildContent, t, this.props.role)}
          <PhdProjectContextProvider
            doctoralCandidateId={this.props.doctoralCandidate.id}
            isFastDoctoralCandidate={this.props.doctoralCandidate.isFastDoctoralCandidate}
            loggedInUserRoleToDoctoralCandidate={getRequesterRole(this.props.doctoralCandidate.roles)}
          >
            {this.props.children &&
              React.cloneElement(this.props.children, {
                doctoralCandidate: this.props.doctoralCandidate,
                viewerRole: this.props.role
              })}
          </PhdProjectContextProvider>
        </Page>
      )
    }

    return (
      <Page>
        {this._renderHeading(false, t, this.props.role)}
        <PhdProjectContextProvider
          doctoralCandidateId={this.props.doctoralCandidate.id}
          isFastDoctoralCandidate={this.props.doctoralCandidate.isFastDoctoralCandidate}
          loggedInUserRoleToDoctoralCandidate={getRequesterRole(this.props.doctoralCandidate.roles)}
        >
          {this._renderOverviewItems()}
        </PhdProjectContextProvider>
      </Page>
    )
  }

  _renderHeading(showBackToOverviewLink = false, t, viewerRole = Role.DOCTORAL_CANDIDATE) {
    const { doctoralCandidate, baseUrl } = this.props
    const sticky = !showBackToOverviewLink

    const isDoctoralCandidateLoggedInUser =
      hasRole(Role.DOCTORAL_CANDIDATE) && getDoctoralCandidateId() === this.props.doctoralCandidate.id
    if (isDoctoralCandidateLoggedInUser || !this.props.showBreadcrumbs) {
      return (
        <>
          <Helmet>
            {viewerRole === Role.DOCTORAL_CANDIDATE ? (
              <title>{generatePageTitle(t(k.MY_PHD_PROJECT), t(k.THESSA))}</title>
            ) : (
              <title>{generatePageTitle(t(k.DOCTORAL_RESEARCHER_OVERVIEW), doctoralCandidate.name, t(k.THESSA))}</title>
            )}
          </Helmet>
          {viewerRole === Role.DOCTORAL_CANDIDATE ? (
            <PageHeading wide visuallyHideText>
              {t(k.MY_PHD_PROJECT)} {'-'} {doctoralCandidate.name}
            </PageHeading>
          ) : null}
        </>
      )
    }

    return (
      <Fragment>
        <Helmet>
          <title>{generatePageTitle(doctoralCandidate.name, t(k.DOCTORAL_CANDIDATE), t(k.THESSA))}</title>
        </Helmet>
        <PageHeading
          parentHeading={t(k.MY_DOCTORAL_CANDIDATES1)}
          parentLink="/doctoral-candidates"
          sticky={sticky}
          wide
        >
          {doctoralCandidate.name}
        </PageHeading>
        {showBackToOverviewLink && (
          <PageBlock wide>
            <PageNavigation
              items={[
                {
                  path: baseUrl,
                  title: t(k.BACK_TO_OVERVIEW),
                  iconBefore: 'glyphicon-chevron-left'
                }
              ]}
              paddingBlock
            />
          </PageBlock>
        )}
      </Fragment>
    )
  }

  _renderOverviewItems() {
    const doctoralCandidate = this.props.doctoralCandidate
    const isUserDoctoralCandidate = this.props.role === Role.DOCTORAL_CANDIDATE
    const isUserSupervisor = this.props.role === Role.SUPERVISOR
    const doctoralCandidateHasThesisCommitteeMembersOrInvites =
      doctoralCandidate.thesisCommitteeMembers.length > 0 || doctoralCandidate.thesisCommitteeMemberInvitationCount > 0
    const shouldInviteThesisCommittee = isUserDoctoralCandidate && !doctoralCandidateHasThesisCommitteeMembersOrInvites
    return (
      <div>
        {shouldInviteThesisCommittee && <InviteThesisCommitteeMembersNotification />}
        <SupervisionPlanOverview
          baseUrl={this.props.baseUrl}
          doctoralCandidate={doctoralCandidate}
          isUserSupervisor={isUserSupervisor}
          useFluidContainer={this.props.useFluidContainer}
        />
        <StudyPlanOverview
          baseUrl={this.props.baseUrl}
          doctoralCandidate={doctoralCandidate}
          isUserDoctoralCandidate={isUserDoctoralCandidate}
          useFluidContainer={this.props.useFluidContainer}
          viewerRole={this.props.role}
        />
        <SupervisorsAndThesisCommitteeOverview
          supervisorRelationships={doctoralCandidate.supervisorRelationships}
          thesisCommitteeMembers={doctoralCandidate.thesisCommitteeMembers}
          doctoralCandidateId={doctoralCandidate.id}
        />
      </div>
    )
  }
}

DoctoralCandidateOverview.propTypes = {
  role: PropTypes.oneOf([
    Role.DOCTORAL_CANDIDATE,
    Role.SUPERVISOR,
    Role.COORDINATOR,
    Role.THESIS_COMMITTEE_MEMBER,
    Role.SALARY_SYSTEM_FOREMAN
  ]),
  useFluidContainer: PropTypes.bool,
  showBreadcrumbs: PropTypes.bool
}

DoctoralCandidateOverview.defaultProps = {
  useFluidContainer: false,
  showBreadcrumbs: true
}

export default withTranslation()(DoctoralCandidateOverview)

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import Heading, { SubHeading } from './Heading'
import Table from './Table'
import { formatTimePeriod, formatMonthPeriod, reportsToRows, createCell } from './Helpers'
import { getFormattedStatuses } from '../../funding/GrantHelpers'

const periodClassModifier = 'period'
const sourceClassModifier = 'funding-source'
const statusClassModifier = 'funding-status'

const getSalaryCells = (reportItem) => [
  createCell(formatTimePeriod(reportItem.beginsAt, reportItem.endsAt), periodClassModifier),
  createCell(reportItem.source, sourceClassModifier)
]

const salaryHeadings = (t) => [
  createCell(t(k.PERIOD), periodClassModifier),
  createCell(t(k.SOURCE_OF_SALARY), sourceClassModifier)
]

const getGrantCells = (t) => (reportItem) =>
  [
    createCell(formatMonthPeriod(reportItem.beginsAtMonth, reportItem.endsAtMonth), periodClassModifier),
    createCell(reportItem.source, sourceClassModifier),
    createCell(getFormattedStatuses(t)[reportItem.status], statusClassModifier)
  ]

const grantHeadings = (t) => [
  createCell(t(k.PERIOD), periodClassModifier),
  createCell(t(k.SOURCE_OF_GRANT), sourceClassModifier),
  createCell(t(k.STATUS), statusClassModifier)
]

const Funding = (props) => {
  const { t } = useTranslation()
  const salaryRows = reportsToRows(props.reports, ({ salaries }) => salaries, getSalaryCells)
  const grantRows = reportsToRows(props.reports, ({ grants }) => grants, getGrantCells(t))
  return (
    <div>
      <Heading text={t(k.FUNDING)} />
      <SubHeading text={t(k.SALARIES)} />
      <Table headings={salaryHeadings(t)} rows={salaryRows} label={t(k.SALARIES)} />
      <SubHeading text={t(k.GRANTS)} />
      <Table headings={grantHeadings(t)} rows={grantRows} label={t(k.GRANTS)} />
    </div>
  )
}

Funding.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.object)
}

export default Funding

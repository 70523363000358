import React, { FormEvent, useState, useEffect, KeyboardEvent, ReactNode } from 'react'
import { TFunction } from 'i18next'
import { Trans, useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import { Actions, UniForm } from '../../common/uni/UniForm'
import { UniButton } from '../../common/uni/UniButton'
import { GRAYSCALE_WHITE } from '../../common/uni/colors'
import { isTextFieldValid } from '../../../util'
import { usePhdProjectActions } from '../../doctoral-candidate-overview/phd-project-context/usePhdProjectActions'
import { UniTextareaWithLabel } from '../../common/uni/UniTextareaWithLabel'
import styled, { css } from 'styled-components'
import VisuallyHidden from '../../common/VisuallyHidden'
import { HINT_CONTAINER_HEIGHT } from '../../common/uni/UniInput'
import { UniCheckbox } from '../../common/uni/UniCheckbox'
import { UniSelectWithLabel } from '../../common/uni/UniSelectWithLabel'
import { usePhdProject } from '../../doctoral-candidate-overview/phd-project-context/PhdProjectContextProvider'
import { findDefaultValue, getUnassignedCommonMilestones, groupItemsForSelect } from '../util'
import { DropdownStyles } from '../../common/header/DropdownStyles'
import UniLoadingIndicator from '../../common/uni/UniLoadingIndicator'

type MilestoneFormAction = 'create' | 'update'

interface MilestoneFormProps {
  chapterId: number
  closeAction: () => void
  milestoneId?: number
  value?: string
  action?: MilestoneFormAction
  completedAt?: string | false
  chapterAssignedAt?: string
  isCommon?: boolean
}

export const MilestoneForm = ({
  chapterId,
  closeAction,
  milestoneId,
  value,
  action = 'create',
  completedAt = false,
  chapterAssignedAt,
  isCommon
}: MilestoneFormProps) => {
  const { t } = useTranslation()
  const [isCompleted, setIsCompleted] = useState(Boolean(completedAt))
  const [isHandling, setIsHandling] = useState(false)
  const [activeAction, setActiveAction] = useState<MilestoneFormAction | null>(action)
  const [errorStatus, setErrorStatus] = useState<string | null>(null)
  const { createMilestone, updateCommonMilestone, updateMilestone } = usePhdProjectActions()
  const { commonMilestones } = usePhdProject()
  const optionsCommonMilestones = [...commonMilestones]
  const unassignedCommonMilestones = getUnassignedCommonMilestones(optionsCommonMilestones)
  const { groupedItems, uncategorizedOptions } = groupItemsForSelect(optionsCommonMilestones, milestoneId)
  const options = [...groupedItems, ...uncategorizedOptions]
  const defaultMilestone = isCommon ? findDefaultValue(options, milestoneId) ?? '' : value
  const [milestone, setMilestone] = useState(defaultMilestone)
  const noCommonMilestonesAvailable = isCommon && commonMilestones.length === 0
  const areAllCommonMilestonesAssigned = isCommon && unassignedCommonMilestones.length === 0
  const maxLength = 255
  const errorMessage = getStatusMessage(
    errorStatus,
    milestone,
    noCommonMilestonesAvailable,
    areAllCommonMilestonesAssigned,
    isHandling,
    maxLength,
    activeAction,
    t
  )
  const hasError = errorMessage !== undefined
  const isLoading = isHandling && !hasError

  useEffect(() => {
    const handleCreateAction = async () => {
      setErrorStatus(null)
      try {
        if (isCommon) {
          await updateCommonMilestone(parseInt(milestone, 10), isCompleted, chapterId)
        } else {
          await createMilestone(milestone, isCompleted, chapterId)
        }
        setMilestone('')
        setIsHandling(false)
        closeAction()
      } catch (error) {
        const { status } = error
        setErrorStatus(status)
        setIsHandling(false)
      }
    }

    const handleUpdateAction = async () => {
      setErrorStatus(null)
      try {
        if (isCommon) {
          if (milestoneId === parseInt(milestone, 10)) {
            await updateCommonMilestone(milestoneId, isCompleted, chapterId)
            setIsHandling(false)
            closeAction()
          } else {
            await Promise.all([
              updateCommonMilestone(milestoneId, isCompleted, null),
              updateCommonMilestone(parseInt(milestone, 10), isCompleted, chapterId, chapterAssignedAt)
            ])
          }
        } else {
          await updateMilestone(milestoneId, milestone, isCompleted)
          setIsHandling(false)
          closeAction()
        }
      } catch (error) {
        const { status } = error
        setIsCompleted(Boolean(completedAt))
        setErrorStatus(status)
        setIsHandling(false)
      }
    }

    if (isHandling && activeAction === 'create' && isTextFieldValid(milestone, maxLength)) {
      handleCreateAction()
    }

    if (isHandling && activeAction === 'update' && isTextFieldValid(milestone, maxLength)) {
      handleUpdateAction()
    }
  }, [isHandling, isCommon])

  useEffect(() => {
    setMilestone(defaultMilestone)
  }, [unassignedCommonMilestones.length])

  function onSubmit(event: FormEvent) {
    event.preventDefault()
    setIsHandling(true)
    setActiveAction(activeAction)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey) {
      event.preventDefault()
      if (isTextFieldValid(milestone, maxLength)) {
        setIsHandling(true)
        setActiveAction(activeAction)
      }
    }
  }

  return (
    <Form
      noGap
      title={activeAction === 'create' ? t(k.ADD_PERSONAL_MILESTONE) : t(k.EDIT_PERSONAL_MILESTONE)}
      visuallyHideTitle
      onSubmit={onSubmit}
      statusMessage={errorMessage}
      statusMessageVariant={areAllCommonMilestonesAssigned ? 'info' : 'error'}
      closeStatusMessage={
        areAllCommonMilestonesAssigned
          ? undefined
          : () => {
              setErrorStatus(null)
              setIsHandling(false)
            }
      }
      fullWidth
    >
      <CheckboxWrapper white>
        <Checkbox>
          <UniCheckbox
            id={`chapter-action-${chapterId}`}
            label={t(k.SET_AS_COMPLETED)}
            visuallyHideLabel
            options={[
              {
                label: <VisuallyHidden>{milestone}</VisuallyHidden>,
                checked: isCompleted,
                onChange: (checked) => setIsCompleted(checked),
                disabled: isCommon && areAllCommonMilestonesAssigned
              }
            ]}
          />
        </Checkbox>
        {isCommon ? (
          <CommonMilestoneSelect
            label={activeAction === 'create' ? t(k.ADD_COMMON_MILESTONE) : t(k.EDIT_COMMON_MILESTONE)}
            required
            options={options}
            id={`chapter-action-${chapterId}`}
            onChange={(value) => setMilestone(value)}
            value={milestone}
            autoFocus
            fullWidth
            visuallyHideValidationHintAfter={true}
            visuallyHideLabelHelperText
            flex
            disabled={areAllCommonMilestonesAssigned}
          />
        ) : (
          <MilestoneInput
            label={activeAction === 'create' ? t(k.ADD_PERSONAL_MILESTONE) : t(k.EDIT_PERSONAL_MILESTONE)}
            required
            id={`chapter-action-${chapterId}`}
            onChange={(value) => {
              setMilestone(value)
              setIsHandling(false)
            }}
            value={milestone}
            autoFocus
            rows={1}
            maxLength={maxLength}
            placeholder={t(k.MILESTONE_DESCRIPTION)}
            fullWidth
            onKeyDown={handleKeyDown}
            isValid={isTextFieldValid(milestone, maxLength)}
            hasInvalidHighlight={isHandling && !isTextFieldValid(milestone, maxLength)}
            validationHintAfter={
              activeAction === 'create'
                ? t(k.PRESSING_ENTER_ON_THE_KEYBOARD_WILL_ADD_THE_MILESTONE)
                : t(k.PRESSING_ENTER_ON_THE_KEYBOARD_WILL_SAVE_THE_MILESTONE)
            }
            visuallyHideValidationHintAfter={true}
            visuallyHideLabelHelperText
          />
        )}
      </CheckboxWrapper>
      <ActionWrapper white paddingBottom={!errorStatus}>
        <Actions noPadTop lastToEnd fullWidth>
          <Actions noPadTop>
            <UniLoadingIndicator isLoading={isLoading} delay={300} />
            <UniButton disabled={areAllCommonMilestonesAssigned}>{t(k.SAVE)}</UniButton>
            <CloseButton small minimal tertiary icon="remove" visuallyHideText onClick={closeAction}>
              {t(k.CLOSE)}
            </CloseButton>
          </Actions>
        </Actions>
      </ActionWrapper>
    </Form>
  )
}

const getStatusMessage = (
  errorStatus: string | null,
  milestone: string,
  noCommonMilestonesAvailable: boolean,
  areAllCommonMilestonesAssigned: boolean,
  isHandling: boolean,
  maxLength: number,
  activeAction: MilestoneFormAction,
  t: TFunction
): string | ReactNode | undefined => {
  const hasError = errorStatus !== null && errorStatus !== undefined
  if (hasError) {
    return (
      <>
        {activeAction === 'create'
          ? t(k.ADDING_MILESTONE_FAILED_WITH_ERROR_CODE)
          : t(k.UPDATING_MILESTONE_FAILED_WITH_ERROR_CODE)}{' '}
        {errorStatus} <br />
        <Trans
          t={t}
          i18nKey={k.TRY_AGAIN_LATER}
          components={[<a href="mailto:thessa-admin@helsinki.fi" />]} //eslint-disable-line react/jsx-key, jsx-a11y/anchor-has-content
        />
      </>
    )
  } else if (noCommonMilestonesAvailable) {
    return t(k.NO_COMMON_MILESTONES_AVAILABLE)
  } else if (areAllCommonMilestonesAssigned) {
    return t(k.ALL_COMMON_MILESTONES_HAVE_BEEN_ASSIGNED_TO_CHAPTERS)
  } else if (isHandling && isTextFieldValid(milestone) === true && isTextFieldValid(milestone, maxLength) === false) {
    return t(k.INPUT_EXCEEDS_THE_CHARACTER_LIMIT, { maxLength: maxLength.toString() })
  } else if (isHandling && !isTextFieldValid(milestone)) {
    return t(k.PLEASE_FILL_OUT_ALL_REQUIRED_FIELDS)
  }
  return undefined
}

const Form = styled(UniForm)`
  ${DropdownStyles('static')}
`

const CheckboxWrapper = styled.div<{
  gray?: boolean
  white?: boolean
}>`
  display: flex;
  align-items: flex-end;
  gap: 3px;
  width: 100%;
  padding-inline: 18px;
  padding-block: 6px;

  ${(props) =>
    props.gray &&
    css`
      background-color: rgba(0, 0, 0, 0.04);
    `}

  ${(props) =>
    props.white &&
    css`
      background-color: ${GRAYSCALE_WHITE};
    `}
`

const Checkbox = styled.div`
  transform: translateY(${HINT_CONTAINER_HEIGHT * -1 + 8}px);
`

const MilestoneInput = styled(UniTextareaWithLabel)`
  font-weight: 600;
  letter-spacing: -0.4px;
`

const CommonMilestoneSelect = styled(UniSelectWithLabel)`
  font-weight: 600;
  letter-spacing: -0.4px;
`

const ActionWrapper = styled.div<{
  gray?: boolean
  white?: boolean
  paddingBottom?: boolean
}>`
  padding-inline: 18px;

  ${(props) =>
    props.gray &&
    css`
      background-color: rgba(0, 0, 0, 0.04);
    `}

  ${(props) =>
    props.white &&
    css`
      background-color: ${GRAYSCALE_WHITE};
    `}

  ${(props) =>
    props.paddingBottom &&
    css`
      padding-bottom: 22px;
    `}
`

const CloseButton = styled(UniButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`

import React, { createContext, Dispatch, PropsWithChildren, useContext, useEffect, useReducer } from 'react'
import i18next from 'i18next'
import { useAuthStatus } from '../../../util'
import { authStatus, IS_LOGGED_IN } from '../../../stores/AuthenticationStore'
import { PhdProject, PhdProjectAction, PhdProjectActionType } from '../types'
import { getEmptyPhdProject } from '../util'
import PageLoadingIndicator from '../../common/page/PageLoadingIndicator'
import { Role } from '../../../stores/role'
import { phdProjectReducer } from './phdProjectReducer'
import { initializePhdProject } from './InitializePhdProject'
import Page from '../../common/page/Page'
import PageBlock from '../../common/page/PageBlock'
import { PageAlert } from '../../common/page/PageAlert'
import { ContactUsLink } from '../../common/ContactUs'
import PageBodyText from '../../common/page/PageBodyText'
import { useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'

const PhdProjectContext = createContext<PhdProject>(null)
const PhdProjectDispatchContext = createContext<Dispatch<PhdProjectAction>>(null)

interface PhdProjectContextProviderProps {
  doctoralCandidateId: number
  isFastDoctoralCandidate: boolean
  loggedInUserRoleToDoctoralCandidate: Role
}

export const PhdProjectContextProvider = ({
  doctoralCandidateId,
  isFastDoctoralCandidate,
  loggedInUserRoleToDoctoralCandidate,
  children
}: PropsWithChildren<PhdProjectContextProviderProps>) => {
  const { t } = useTranslation()
  const authenticationStatus = useAuthStatus(authStatus)
  const [phdProject, dispatch] = useReducer(phdProjectReducer, null, getEmptyPhdProject)

  const initializeProject = () => {
    initializePhdProject(doctoralCandidateId, isFastDoctoralCandidate, loggedInUserRoleToDoctoralCandidate, dispatch, t)
  }

  const onLanguageChange = () => {
    dispatch({ type: PhdProjectActionType.Clear })
    initializeProject()
  }

  useEffect(() => {
    if (authenticationStatus === IS_LOGGED_IN) {
      initializeProject()
    }
    return () => {
      dispatch({ type: PhdProjectActionType.Clear })
    }
  }, [authenticationStatus, doctoralCandidateId])

  useEffect(() => {
    i18next.on('languageChanged', onLanguageChange)

    return () => {
      i18next.off('languageChanged', onLanguageChange)
    }
  }, [])

  const isError = !!phdProject.errorTranslationKey
  if (isError) {
    return (
      <Page>
        <PageBlock padTop>
          <PageAlert>
            <PageBodyText>
              <strong>{t(k.PHD_PROJECT.ERROR.GENERAL_ERROR_HEADING)}</strong> {t(k.PHD_PROJECT.ERROR.YOU_CAN_RETRY_OR)}{' '}
              <ContactUsLink>{t(k.CONTACT_US1)}</ContactUsLink>
              {'.'}
            </PageBodyText>
            <PageBodyText className="no-bottom-margin">
              <small>
                {t(k.PHD_PROJECT.ERROR.INCLUDE_THIS_TEXT)}
                {': "'}
                <em>{t(phdProject.errorTranslationKey)}</em>
                {'"'}
              </small>
            </PageBodyText>
          </PageAlert>
        </PageBlock>
      </Page>
    )
  }

  const isLoading = authenticationStatus !== IS_LOGGED_IN || Object.values(phdProject).some((value) => value === null)
  if (isLoading) {
    return <PageLoadingIndicator />
  }

  return (
    <PhdProjectContext.Provider value={phdProject}>
      <PhdProjectDispatchContext.Provider value={dispatch}>{children}</PhdProjectDispatchContext.Provider>
    </PhdProjectContext.Provider>
  )
}

export function usePhdProject() {
  return useContext(PhdProjectContext)
}

export function usePhdProjectDispatch() {
  return useContext(PhdProjectDispatchContext)
}

import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { UniButton } from '../../common/uni/UniButton'
import k from '../../../i18n/keys'
import ContextMenuIcon from '../../common/icon/ContextMenuIcon'
import { UniPopover } from '../../common/uni/UniPopover'
import { MilestoneAction } from './Milestone'

interface MilestoneActionsProps {
  title: string
  milestoneId: number
  isHandling: boolean
  setActiveAction: (action: MilestoneAction) => void
  setIsHandling: (isHandling: boolean) => void
  setErrorStatus: (errorStatus: string | null) => void
  widget?: boolean
}

export const MilestoneActions = ({ title, setActiveAction, setIsHandling, widget }: MilestoneActionsProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  if (widget) {
    return null
  }

  return (
    <Wrapper>
      <UniPopover
        right
        small
        onCancel={() => setIsOpen(false)}
        isVisible={isOpen}
        items={[
          {
            label: t(k.EDIT),
            onClick: () => {
              setIsHandling(true)
              setActiveAction('edit')
            }
          },
          {
            label: t(k.DELETE),
            onClick: () => {
              setIsHandling(true)
              setActiveAction('delete')
            }
          }
        ]}
      >
        <MenuButton
          onClick={toggleMenu}
          iconBefore={<ContextMenuIcon />}
          minimal
          secondary
          visuallyHideText
          className="milestone-actions"
        >
          <Trans t={t} i18nKey={k.OPEN_CONTEXT_MENU} values={{ milestone: title }} />
        </MenuButton>
      </UniPopover>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ widget?: boolean; gray?: boolean; white?: boolean }>`
  position: relative;
  display: inline-block;
`

const MenuButton = styled(UniButton)``

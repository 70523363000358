import React from 'react'
import styled, { css } from 'styled-components'
import { UniParagraphAnchorStyles } from './UniParagraph'
import { GRAYSCALE_BLACK } from './colors'
import { HyIcon } from '../icon/HyIcon'
import Tooltip from '../Tooltip'
import { TooltipFocusableControl } from '../Tooltip'

interface Properties {
  summary?: string | React.ReactNode
  summaryStyle?: 'link' | 'heading'
  open?: boolean
  marginTop?: boolean
  marginBottom?: boolean
  noArrow?: boolean
  showChevron?: boolean
  icon?: string
  iconTooltip?: string | React.ReactNode
  children: React.ReactNode
}

export const UniDetails = ({
  summary,
  open,
  marginTop,
  marginBottom,
  noArrow,
  showChevron,
  icon,
  iconTooltip,
  children
}: Properties) => {
  return (
    <Details open={open} marginTop={marginTop} marginBottom={marginBottom}>
      <Summary noArrow={noArrow} showChevron={showChevron}>
        {showChevron ? <Chevron caretDown /> : null}
        <SummaryText>{summary}</SummaryText>
        {icon && !iconTooltip ? <Icon {...{ [icon]: true }} /> : null}
        {icon && iconTooltip ? (
          <Tooltip content={iconTooltip}>
            <Control>
              <Icon {...{ [icon]: true }} />
            </Control>
          </Tooltip>
        ) : null}
      </Summary>
      <Content showChevron={showChevron}>{children}</Content>
    </Details>
  )
}

const Details = styled.details<{
  marginTop?: boolean
  marginBottom?: boolean
}>`
  margin-top: ${({ marginTop }) => (marginTop ? '12px' : '0')};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '12px' : '0')};
`

const Summary = styled.summary<{ link?: boolean; heading?: boolean; noArrow?: boolean; showChevron?: boolean }>`
  display: list-item;
  cursor: pointer;
  ${({ noArrow }) => (noArrow ? 'list-style: none;' : '')}

  ${({ showChevron }) =>
    showChevron &&
    css`
      display: flex;
      align-items: center;
      gap: 8px;
    `}
`

const SummaryText = styled.span`
  ${UniParagraphAnchorStyles}
  color: ${GRAYSCALE_BLACK};
`

const Chevron = styled(HyIcon)<{ small?: boolean; open?: boolean }>`
  flex: 0 0 auto;
  color: ${GRAYSCALE_BLACK};
  font-size: 12px;
  line-height: 1.5em;
  height: 1.5em;
  transition: transform 0.25s cubic-bezier(0.465, 0.183, 0.153, 0.946);

  ${Details}:not([open]) & {
    transform: rotate(-90deg);
  }
`

const Icon = styled(HyIcon)`
  font-size: 18px;
  margin-left: auto;
`

const Control = styled(TooltipFocusableControl)`
  margin-left: auto;
`

const Content = styled.div<{ showChevron?: boolean }>`
  margin-top: 8px;

  ${({ showChevron }) =>
    showChevron &&
    css`
      padding-left: 18px;
      padding-top: 8px;
    `}
`

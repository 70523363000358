import React from 'react'
import styled from 'styled-components'

const ContextMenuIcon = () => {
  return (
    <Wrapper>
      <Dot />
      <Dot />
      <Dot />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
`

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: black;
  border-radius: 50%;
  margin: 2px 0;
`

export default ContextMenuIcon

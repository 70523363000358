export enum ChapterItemType {
  StudyRight = 'StudyRight',
  Meeting = 'Meeting',
  Milestone = 'Milestone'
}

export interface ChapterItemBase {
  id: number
  completedAt?: false | string
  chapterAssignedAt?: string
}

export interface ChapterStudyRight extends ChapterItemBase {
  type: ChapterItemType.StudyRight
  title: string
}

export interface ChapterMeeting extends ChapterItemBase {
  type: ChapterItemType.Meeting
  title: string
  plannedAt?: string
  suggestedAt?: string
}

export interface ChapterMilestone extends ChapterItemBase {
  type: ChapterItemType.Milestone
  chapter: number | undefined
  title: string
  isCommon?: boolean
  category?: {
    id: number
    title: string
  }
}

export type ChapterItem = ChapterStudyRight | ChapterMeeting | ChapterMilestone

export interface Chapter {
  id: number
  items?: ChapterItem[]
  chapterInterval: number
}

export interface ChapterAssignableItem {
  chapter: number | undefined
  [key: string]: any
}

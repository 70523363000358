import React from 'react'
import styled, { css } from 'styled-components'
import { GRAYSCALE_DARK } from './colors'

export interface UniPlaceholderProps {
  fullWidth?: boolean
  className?: string
  children?: string | React.ReactNode
}

const UniPlaceholder = ({ fullWidth, className, children }: UniPlaceholderProps) => {
  return (
    <Placeholder className={className} fullWidth={fullWidth}>
      {children}
    </Placeholder>
  )
}

const Placeholder = styled.div<{ fullWidth?: boolean }>`
  background: rgba(0, 0, 0, 0.03);
  border: 2px dashed rgba(0, 0, 0, 0.1);
  color: ${GRAYSCALE_DARK};
  font-size: 14px;
  margin-bottom: 16px;
  padding: 8px 12px;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

export default UniPlaceholder

import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import k from '../../../i18n/keys'
import React, { Dispatch, SetStateAction } from 'react'
import { BRAND_MAIN_NEARLY_BLACK } from '../uni/colors'
import { fromPhoneUp } from '../../styledUtils'
import { LinkStyles, LinkInteractionStyles } from './LinkStyles'

interface Properties {
  isCollapsed: boolean
  setIsCollapsed: Dispatch<SetStateAction<boolean>>
  collapseAll: () => void
  controls: string
  className?: string
}

const ToggleButton = ({ isCollapsed, setIsCollapsed, collapseAll, controls, className }: Properties) => {
  const { t } = useTranslation()
  return (
    <Button
      onClick={() => {
        collapseAll()
        setIsCollapsed(!isCollapsed)
      }}
      className={className}
      aria-expanded={!isCollapsed}
      aria-controls={controls}
      aria-label={isCollapsed ? t(k.OPEN_MAIN_MENU) : t(k.CLOSE_MAIN_MENU)}
    >
      <Bars>
        <Bar />
        <Bar />
        <Bar />
      </Bars>
      <MenuText>{t(k.MENU)}</MenuText>
    </Button>
  )
}

const Button = styled.button`
  appearance: none;
  background: none;
  border: 0;
  ${LinkStyles()}
  font-size: 12px;
  ${({ 'aria-expanded': active }) => LinkInteractionStyles(active)}

  ${fromPhoneUp(css`
    font-size: 14px;
  `)}
`

const Bars = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 2px;
  width: 18px;
  height: 12px;
`

const Bar = styled.span`
  background-color: ${BRAND_MAIN_NEARLY_BLACK};
  flex: 1 1 auto;
  border-radius: 10px;
`

const MenuText = styled.div``

export default ToggleButton

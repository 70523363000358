import classNames from 'classnames'
import React from 'react'
import styled, { css } from 'styled-components'
import {
  GRAYSCALE_DARK,
  GRAYSCALE_WHITE,
  ADDITIONAL_YELLOW_DARK,
  ADDITIONAL_ORANGE,
  ADDITIONAL_BLUE,
  ADDITIONAL_GREEN_DARK,
  GRAYSCALE_MEDIUM_DARK,
  GRAYSCALE_DARK_TEXT,
  GRAYSCALE_BACKGROUND_BOX
} from './colors'
import { DEFAULT_FONT } from './typography'

export interface TagProps {
  variant?: 'error' | 'warning' | 'info' | 'success' | 'default'
  large?: boolean
  minimal?: boolean
  bold?: boolean
  iconBefore?: React.ReactNode
  iconAfter?: React.ReactNode
  children?: React.ReactNode
  className?: string
}

const UniTag = ({
  variant = 'default',
  large = false,
  minimal = false,
  bold,
  iconBefore,
  iconAfter,
  children,
  className,
  ...props
}: TagProps) => {
  const classNamePrefix = 'thessa-label'

  if (children) {
    return (
      <TagElement
        variant={variant}
        large={large}
        minimal={minimal}
        bold={bold}
        iconBefore={iconBefore}
        iconAfter={iconAfter}
        {...props}
        className={classNames(className, classNamePrefix, {
          [`${classNamePrefix}--${variant}`]: variant
        })}
      >
        {iconBefore && (
          <IconContainer large={large} iconBefore={iconBefore}>
            {iconBefore}
          </IconContainer>
        )}
        {children}
        {iconAfter && (
          <IconContainer large={large} iconAfter={iconAfter}>
            {iconAfter}
          </IconContainer>
        )}
      </TagElement>
    )
  }

  return null
}

export default UniTag

const TagElement = styled.div<TagProps>`
  display: inline-flex;
  align-items: center;
  ${DEFAULT_FONT}
  font-size: 12px;
  line-height: 16px;
  font-weight: ${({ bold }) => (bold ? '700' : '600')};
  background-color: ${GRAYSCALE_BACKGROUND_BOX};
  color: ${GRAYSCALE_DARK_TEXT};
  letter-spacing: -0.07px;
  padding: 4px 8px;
  border-radius: 0;
  text-transform: uppercase;

  ${({ iconBefore }) =>
    iconBefore &&
    css`
      gap: 6px;
    `}

  ${({ iconAfter }) =>
    iconAfter &&
    css`
      gap: 6px;
    `}

  ${({ large }) =>
    large &&
    css`
      font-size: 15px;
      line-height: 20px;
    `}

  ${({ minimal }) =>
    minimal &&
    css`
      padding: 0;
    `}

  ${({ variant, minimal }) =>
    variant === 'error' &&
    !minimal &&
    css`
      background-color: ${ADDITIONAL_ORANGE};
      color: ${GRAYSCALE_WHITE};
    `}

  ${({ variant, minimal, bold }) =>
    variant === 'error' &&
    minimal &&
    css`
      background-color: ${GRAYSCALE_WHITE};
      color: ${ADDITIONAL_ORANGE};
      font-weight: ${bold === false ? '600' : '700'};
    `}

  ${({ variant, minimal }) =>
    variant === 'warning' &&
    !minimal &&
    css`
      background-color: ${ADDITIONAL_YELLOW_DARK};
      color: ${GRAYSCALE_WHITE};
    `}
  
  ${({ variant, minimal }) =>
    variant === 'warning' &&
    minimal &&
    css`
      background-color: ${GRAYSCALE_WHITE};
      color: ${ADDITIONAL_YELLOW_DARK};
      font-weight: 700;
    `}

  ${({ variant, minimal, bold }) =>
    variant === 'warning' &&
    minimal &&
    css`
      background-color: ${GRAYSCALE_WHITE};
      color: ${ADDITIONAL_YELLOW_DARK};
      font-weight: ${bold === false ? '600' : '700'};
    `}

  ${({ variant, minimal }) =>
    variant === 'info' &&
    !minimal &&
    css`
      background-color: ${ADDITIONAL_BLUE};
      color: ${GRAYSCALE_WHITE};
    `}

  ${({ variant, minimal, bold }) =>
    variant === 'info' &&
    minimal &&
    css`
      background-color: ${GRAYSCALE_WHITE};
      color: ${ADDITIONAL_BLUE};
      font-weight: ${bold === false ? '600' : '700'};
    `}

  ${({ variant, minimal }) =>
    variant === 'success' &&
    !minimal &&
    css`
      background-color: ${ADDITIONAL_GREEN_DARK};
      color: ${GRAYSCALE_WHITE};
    `}

  ${({ variant, minimal, bold }) =>
    variant === 'success' &&
    minimal &&
    css`
      background-color: ${GRAYSCALE_WHITE};
      color: ${ADDITIONAL_GREEN_DARK};
      font-weight: ${bold === false ? '600' : '700'};
    `}

  ${({ variant }) =>
    variant === 'default' &&
    css`
      background-color: ${GRAYSCALE_DARK};
      color: ${GRAYSCALE_WHITE};
    `}

  ${({ variant, minimal, bold }) =>
    variant === 'default' &&
    minimal &&
    css`
      background-color: ${GRAYSCALE_WHITE};
      color: ${GRAYSCALE_DARK};
      font-weight: ${bold === false ? '600' : '700'};
    `}
`

const IconContainer = styled.div<{ large?: boolean; iconBefore?: React.ReactNode; iconAfter?: React.ReactNode }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.large ? '1.2em' : '1em')};

  *:disabled & {
    border-color: ${GRAYSCALE_MEDIUM_DARK};
  }
`

import React, { useState, useEffect, useContext } from 'react'
import { WindowWidthContext } from '../../WindowWidthContext'
import { Chapter } from './types'
import { getChapterStartEvent, getChapterStatusText } from './util'
import ChapterProgress from './ChapterProgress'
import { Role } from '../../stores/role'

export interface ChapterProps {
  chapter: Chapter
  chapters: Chapter[]
  isActiveChapter?: boolean
  widget?: boolean
  teaser?: boolean
  viewerRole: Role
}

const Chapter = ({ chapter, chapters, widget, teaser, viewerRole }: ChapterProps) => {
  const [timelineVisualScale, setTimelineVisualScale] = useState<number>(3)
  const { isTabletOrSmaller } = useContext(WindowWidthContext)
  const startEvent = getChapterStartEvent(chapter)
  const chapterStatusText = getChapterStatusText(chapter, chapters, startEvent)

  useEffect(() => {
    if (isTabletOrSmaller) {
      setTimelineVisualScale(2)
    } else {
      setTimelineVisualScale(3)
    }
  }, [isTabletOrSmaller])

  return (
    <ChapterProgress
      timelineVisualScale={timelineVisualScale}
      chapter={chapter}
      chapters={chapters}
      className={`chapter-${chapterStatusText?.toLowerCase()}`}
      widget={widget}
      teaser={teaser}
      viewerRole={viewerRole}
    />
  )
}

export default Chapter

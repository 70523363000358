import { Field, PhdProject, PhdProjectAction, PhdProjectActionType } from '../types'
import { getEmptyPhdProject } from '../util'
import { loadDoctoralCandidate } from '../../../actions/DoctoralCandidateActions'

export function phdProjectReducer(phdProject: PhdProject, action: PhdProjectAction): PhdProject {
  switch (action.type) {
    case PhdProjectActionType.Clear:
      return getEmptyPhdProject()

    case PhdProjectActionType.Initialize:
      return {
        doctoralCandidateId: action.doctoralCandidateId,
        isFastDoctoralCandidate: action.isFastDoctoralCandidate,
        role: action.role,
        [Field.InternationalActivities]: action[Field.InternationalActivities],
        [Field.Reports]: action[Field.Reports],
        [Field.ReportCommentsAndEvents]: { comments: [], events: [] },
        [Field.ReportDeadline]: action[Field.ReportDeadline],
        [Field.ThesisCommitteeMeetings]: action[Field.ThesisCommitteeMeetings],
        [Field.BeginningOfStudies]: action[Field.BeginningOfStudies],
        [Field.ExpectedGraduationDate]: action[Field.ExpectedGraduationDate],
        [Field.Milestones]: action[Field.Milestones],
        [Field.CommonMilestones]: action[Field.CommonMilestones]
      }

    case PhdProjectActionType.Error:
      return {
        ...phdProject,
        errorTranslationKey: action.errorTranslationKey
      }

    case PhdProjectActionType.CreateInternationalActivity:
      return {
        ...phdProject,
        [Field.InternationalActivities]: [...phdProject[Field.InternationalActivities], action.internationalActivity]
      }

    case PhdProjectActionType.EditInternationalActivity: {
      const internationalActivities = phdProject.internationalActivities.map((internationalActivity) => {
        if (internationalActivity.id === action.internationalActivity.id) {
          return action.internationalActivity
        } else {
          return internationalActivity
        }
      })
      return {
        ...phdProject,
        [Field.InternationalActivities]: [...internationalActivities]
      }
    }

    case PhdProjectActionType.DeleteInternationalActivity:
      return {
        ...phdProject,
        [Field.InternationalActivities]: phdProject[Field.InternationalActivities].filter(
          (internationalActivity) => internationalActivity.id !== action.internationalActivityId
        )
      }

    case PhdProjectActionType.UpdateReports:
      return {
        ...phdProject,
        [Field.Reports]: action.reports
      }

    case PhdProjectActionType.CreateReport:
      return {
        ...phdProject,
        [Field.Reports]: [...phdProject[Field.Reports], action.report]
      }

    case PhdProjectActionType.EditReport: {
      const reports = phdProject[Field.Reports].map((report) => {
        if (report.id === action.report.id) {
          return action.report
        } else {
          return report
        }
      })
      return {
        ...phdProject,
        [Field.Reports]: [...reports]
      }
    }

    case PhdProjectActionType.DeleteReport:
      loadDoctoralCandidate.push(phdProject.doctoralCandidateId)
      return {
        ...phdProject,
        [Field.Reports]: phdProject[Field.Reports].filter((report) => report.id !== action.reportId)
      }

    case PhdProjectActionType.UpdateReportCommentsAndEvents:
      return {
        ...phdProject,
        [Field.ReportCommentsAndEvents]: action.reportCommentsAndEvents
      }

    case PhdProjectActionType.UpdateThesisCommitteeMeetings:
      return {
        ...phdProject,
        [Field.ThesisCommitteeMeetings]: action.thesisCommitteeMeetings
      }

    case PhdProjectActionType.UpdateMilestones:
      return {
        ...phdProject,
        [Field.Milestones]: action.milestones
      }

    case PhdProjectActionType.UpdateCommonMilestones:
      return {
        ...phdProject,
        [Field.CommonMilestones]: action.commonMilestones
      }

    default: {
      throw Error('Unknown action: ' + JSON.stringify(action))
    }
  }
}

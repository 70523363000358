import React, { forwardRef, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { ADDITIONAL_ORANGE, ADDITIONAL_RED } from '../../common/uni/colors'

export interface HyIconProps {
  green?: boolean
  blue?: boolean
  white?: boolean
  black?: boolean
  red?: boolean
  orange?: boolean
  valid?: boolean
  required?: boolean
  spinner?: boolean
  edit?: boolean
  plus?: boolean
  remove?: boolean
  caretDown?: boolean
  caretUp?: boolean
  arrowRight?: boolean
  arrowLeft?: boolean
  alert?: boolean
  info?: boolean
  avatar?: boolean
  login?: boolean
  logout?: boolean
  wrench?: boolean
  search?: boolean
  date?: boolean
  person?: boolean
  people?: boolean
  secondary?: boolean
  className?: string
}

export const HyIcon = forwardRef<HTMLSpanElement, HyIconProps & HTMLAttributes<HTMLSpanElement>>(
  (props: HyIconProps, ref) => (
    <Span ref={ref} role="img" aria-hidden={true} {...props}>
      {getIcon(props)}
    </Span>
  )
)

HyIcon.displayName = 'HyIcon'

function getIcon(props: HyIconProps): string {
  if (props.valid) {
    return ''
  }

  if (props.required) {
    return ''
  }

  if (props.spinner) {
    return ''
  }

  if (props.edit) {
    return ''
  }

  if (props.plus) {
    return '\uEA2F'
  }

  if (props.remove) {
    return ''
  }

  if (props.caretDown) {
    return ''
  }

  if (props.caretUp) {
    return ''
  }

  if (props.arrowRight) {
    return ''
  }

  if (props.arrowLeft) {
    return ''
  }

  if (props.alert) {
    return ''
  }

  if (props.info) {
    return ''
  }

  if (props.avatar) {
    return ''
  }

  if (props.login) {
    return ''
  }

  if (props.logout) {
    return ''
  }

  if (props.wrench) {
    return ''
  }

  if (props.search) {
    return ''
  }

  if (props.date) {
    return '\uEA09'
  }

  if (props.person) {
    return '\uea2e'
  }

  if (props.people) {
    return '\uea2c'
  }

  return ''
}

const Span = styled.span<Pick<HyIconProps, 'green' | 'blue' | 'white' | 'black' | 'red' | 'orange' | 'required'>>`
  font-family: 'hy-icons';
  user-select: none;

  &:before {
    content: '';
  }

  ${(props) =>
    props.green &&
    css`
      color: #96ba3c;
    `}

  ${(props) =>
    props.blue &&
    css`
      color: #107eab;
    `}

  ${(props) =>
    props.white &&
    css`
      color: #fff;
    `}

  ${(props) =>
    props.black &&
    css`
      color: #000;
    `}

  ${(props) =>
    props.red &&
    css`
      color: ${ADDITIONAL_RED};
    `}

  ${(props) =>
    props.orange &&
    css`
      color: ${ADDITIONAL_ORANGE};
    `}

  ${(props) =>
    props.required &&
    css`
      font-size: 10px;
    `}
`

import React from 'react'
import styled from 'styled-components'
import { UniParagraph, UniParagraphStyles } from '../../common/uni/UniParagraph'
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next'
import k from '../../../i18n/keys'
import { Chapter, ChapterStudyRight } from '../types'
import { GRAYSCALE_BLACK, GRAYSCALE_WHITE } from '../../common/uni/colors'
import { HyIcon } from '../../common/icon/HyIcon'
import { getAreAllChapterItemsCompleted, getCurrentChapter, getStartEventIconHeight } from '../util'

export interface StudyRightProps {
  timelineVisualScale: number
  content: ChapterStudyRight
  chapter: Chapter
  chapters: Chapter[]
  isNextStartEventCompleted: boolean
}

const StudyRight = ({
  timelineVisualScale,
  content,
  chapter,
  chapters,
  isNextStartEventCompleted
}: StudyRightProps) => {
  const { t } = useTranslation()
  const { id, completedAt } = content || {}
  const isChapterCompleted = getAreAllChapterItemsCompleted(chapter) && isNextStartEventCompleted
  const currentChapter = getCurrentChapter(chapters)
  const isCurrentChapter = currentChapter?.id === chapter?.id

  const chapterStatusText = !isChapterCompleted && isCurrentChapter ? t(k.ACTIVE) : null

  const chapterHeading = [t(k.CHAPTER), id, chapterStatusText].filter(Boolean).join(' ')

  const chapterStudyRightText = completedAt ? (
    <Trans
      t={t}
      i18nKey={k.STARTED_FROM_GRANTING_STUDY_RIGHT}
      values={{ date: moment(completedAt).format('D. MMMM YYYY') }}
      components={[<span className="chapter-meeting-date" />]} //eslint-disable-line react/jsx-key
    />
  ) : null

  return (
    <Inline timelineVisualScale={timelineVisualScale}>
      <ChapterIcon
        fillTimeline={Boolean(completedAt)}
        timelineVisualScale={timelineVisualScale}
        isChapterCompleted={Boolean(completedAt) && (isChapterCompleted || !isCurrentChapter)}
      >
        <ActiveIcon timelineVisualScale={timelineVisualScale} isVisible={!isChapterCompleted && isCurrentChapter} />
        <Icon valid={Boolean(completedAt)} date={!completedAt} isVisible={isChapterCompleted || !isCurrentChapter} />
      </ChapterIcon>
      <Text>
        <Heading>{id ? <b>{chapterHeading}</b> : null}</Heading>
        <UniParagraph muted noMargin>
          {chapterStudyRightText}
        </UniParagraph>
      </Text>
    </Inline>
  )
}

const Inline = styled.div<{ timelineVisualScale: number }>`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: ${({ timelineVisualScale }) => timelineVisualScale * 6}px;
`

const ChapterIcon = styled.div<{
  fillTimeline: boolean
  timelineVisualScale: number
  isChapterCompleted: boolean
}>`
  position: relative;
  z-index: 1;
  flex: 0 0 auto;
  background-color: ${({ fillTimeline }) => (fillTimeline ? GRAYSCALE_BLACK : GRAYSCALE_WHITE)};
  color: ${({ fillTimeline }) => (fillTimeline ? GRAYSCALE_WHITE : GRAYSCALE_BLACK)};
  font-size: ${({ timelineVisualScale }) => getStartEventIconHeight(timelineVisualScale) / 2.3}px;
  width: ${({ timelineVisualScale }) => getStartEventIconHeight(timelineVisualScale)}px;
  height: ${({ timelineVisualScale }) => getStartEventIconHeight(timelineVisualScale)}px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ timelineVisualScale }) => timelineVisualScale}px solid ${GRAYSCALE_BLACK};
`

const ActiveIcon = styled.div<{
  fillTimeline?: boolean
  mostRecent?: boolean
  timelineVisualScale: number
  isVisible: boolean
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({ timelineVisualScale }) => getStartEventIconHeight(timelineVisualScale) / 4}px;
  height: ${({ timelineVisualScale }) => getStartEventIconHeight(timelineVisualScale) / 4}px;
  background-color: ${GRAYSCALE_WHITE};
  border-radius: 50%;
  transition: opacity 0.3s ease-in-out;
  transform: translate(-50%, -50%);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`

const Icon = styled(HyIcon)<{ valid: boolean; isVisible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${({ valid, isVisible }) =>
    valid
      ? `translateY(calc(-50% + 1px)) translateX(calc(-50% + 1px)) scale(${isVisible ? 1 : 0.5})`
      : `translateY(calc(-50%)) translateX(calc(-50%)) scale(${isVisible ? 1 : 0.5})`};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: transform 0.5s cubic-bezier(0.53, 0.35, 0.12, 2.41), opacity 0.3s ease-in-out;
`

const Text = styled.div``

const Heading = styled.h2`
  ${UniParagraphStyles}
  margin: 0;
`

export default StudyRight
